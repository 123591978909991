import mammoth from 'mammoth';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';

// Convert DOCX to PDF
const docxToPdf = async (file: File): Promise<File> => {
  const arrayBuffer = await file.arrayBuffer();
  const { value: text } = await mammoth.extractRawText({ arrayBuffer });

  if (!text || text.trim().length === 0) {
    throw new Error('The DOCX file is empty or could not be read.');
  }

  const pdfDoc = await PDFDocument.create();
  let page = pdfDoc.addPage(); // Use 'let' for reassigning the page
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  const margin = 50;
  const lineHeight = 14;
  let y = page.getHeight() - margin;

  text.split('\n').forEach((line) => {
    if (y < margin) {
      page = pdfDoc.addPage(); // Reassign 'page' when adding a new page
      y = page.getHeight() - margin;
    }
    page.drawText(line, { font: timesRomanFont, size: 12, x: margin, y });
    y -= lineHeight;
  });

  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], file.name.replace(/\.docx$/, '.pdf'), {
    type: 'application/pdf',
  });
};

// Convert TXT to PDF
const txtToPdf = async (file: File): Promise<File> => {
  const text = await file.text();

  if (!text || text.trim().length === 0) {
    throw new Error('The TXT file is empty or could not be read.');
  }

  const pdfDoc = await PDFDocument.create();
  let page = pdfDoc.addPage(); // Use 'let' for reassigning the page
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  const margin = 50;
  const lineHeight = 14;
  let y = page.getHeight() - margin;

  text.split('\n').forEach((line) => {
    if (y < margin) {
      page = pdfDoc.addPage(); // Reassign 'page' when adding a new page
      y = page.getHeight() - margin;
    }
    page.drawText(line, { font: timesRomanFont, size: 12, x: margin, y });
    y -= lineHeight;
  });

  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], file.name.replace(/\.txt$/, '.pdf'), {
    type: 'application/pdf',
  });
};

// Convert XLSX to PDF
const xlsxToPdf = async (file: File): Promise<File> => {
  const arrayBuffer = await file.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer, { type: 'array' });

  if (!workbook.SheetNames || workbook.SheetNames.length === 0) {
    throw new Error('The XLSX file has no sheets.');
  }

  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  workbook.SheetNames.forEach((sheetName) => {
    const sheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_csv(sheet);

    let page = pdfDoc.addPage(); // Use 'let' for reassigning the page
    const textLines = data.split('\n');
    const margin = 20;
    const lineHeight = 14;
    let y = page.getHeight() - margin;

    textLines.forEach((line) => {
      if (y < margin) {
        page = pdfDoc.addPage(); // Reassign 'page' when adding a new page
        y = page.getHeight() - margin;
      }
      page.drawText(line, { font: timesRomanFont, size: 10, x: margin, y });
      y -= lineHeight;
    });
  });

  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], file.name.replace(/\.xlsx$/, '.pdf'), {
    type: 'application/pdf',
  });
};

// Convert PPTX to PDF
const pptxToPdf = async (file: File): Promise<File> => {
  const arrayBuffer = await file.arrayBuffer();
  const zip = await JSZip.loadAsync(arrayBuffer);

  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  const slides = Object.keys(zip.files)
    .filter((filename) => filename.startsWith('ppt/slides/slide') && filename.endsWith('.xml'));

  if (slides.length === 0) {
    throw new Error('The PPTX file has no slides.');
  }

  for (const slidePath of slides) {
    const slideXml = await zip.files[slidePath].async('string');
    const slideText = extractTextFromSlide(slideXml);

    let page = pdfDoc.addPage(); // Use 'let' for reassigning the page
    const textLines = slideText.split('\n');
    let y = page.getHeight() - 40;

    textLines.forEach((line) => {
      if (y < 40) {
        page = pdfDoc.addPage(); // Reassign 'page' when adding a new page
        y = page.getHeight() - 40;
      }
      page.drawText(line, { font: timesRomanFont, size: 12, x: 50, y });
      y -= 15;
    });
  }

  const pdfBytes = await pdfDoc.save();
  return new File([pdfBytes], file.name.replace(/\.pptx$/, '.pdf'), {
    type: 'application/pdf',
  });
};

// Helper function to extract text content from slide XML
const extractTextFromSlide = (xml: string): string => {
  const textMatches = [...xml.matchAll(/<a:t>(.*?)<\/a:t>/g)];
  return textMatches.map((match) => match[1]).join('\n');
};

// Convert file to PDF based on type
export const convertToPdf = async (file: File): Promise<File> => {
  const extension = file.name.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'docx':
      return await docxToPdf(file);
    case 'txt':
      return await txtToPdf(file);
    case 'xlsx':
      return await xlsxToPdf(file);
    case 'pptx':
      return await pptxToPdf(file);
    default:
      throw new Error('Unsupported file type for conversion.');
  }
};
