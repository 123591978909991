import React, { CSSProperties, useEffect } from 'react';

const PrivacyPolicy: React.FC = () => {
  // Set background color of the entire page to black when component mounts
  useEffect(() => {
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = ''; // Reset background on unmount
    };
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p style={styles.paragraph}>
        At Compreana, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when using our app. By using the Compreana app, you consent to the practices outlined below.
      </p>
      
      <h2 style={styles.subHeading}>Information We Collect</h2>
      <p style={styles.paragraph}>We collect the minimum amount of information necessary to provide you with a satisfactory service. The types of information we may collect include:</p>
      <ul>
        <li>Personal details such as your name, email address, and password.</li>
        <li>Data related to your use of the app.</li>
        <li>Additional information needed to process specific requests, as indicated during your interactions with the app.</li>
      </ul>
      
      <h2 style={styles.subHeading}>How We Use Your Information</h2>
      <p style={styles.paragraph}>The information we collect is used primarily to complete the task for which you visited the app. We take all reasonable precautions to protect your information from unauthorized access. To access certain account details, you may be required to provide additional forms of identification for security purposes.</p>
      
      <h2 style={styles.subHeading}>Disclosure of Information</h2>
      <p style={styles.paragraph}>
        We do not disclose your personal information to third parties without your explicit consent, except as required by law. We may use your information to contact you about updates or developments related to Compreana. You will have the option to unsubscribe from any communication you receive from us.
      </p>
      <p style={styles.paragraph}>
        In cases where we share general information with third parties—such as the number of app users or completed registrations—this data will not include personally identifiable information.
      </p>
      
      <h2 style={styles.subHeading}>Changes to the Policy</h2>
      <p style={styles.paragraph}>
        Any changes to this Privacy Policy will be reflected here and will supersede previous versions. We will make reasonable efforts to notify you of any significant changes. However, we recommend reviewing this policy periodically to ensure it continues to meet your approval.
      </p>
      
      <h2 style={styles.subHeading}>Contact Us</h2>
      <p style={styles.paragraph}>
        If you have questions or concerns about our Privacy Policy, or if you would like to know what personal information we have collected about you, please contact us at <a href="mailto:customerservice@compreqna.com" style={styles.link}>customerservice@compreqna.com</a>. You may also request corrections or deletion of your information from our records.
      </p>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    overflowY: 'auto',
    height: '100vh',
    backgroundColor: '#0d1117',
    color: '#c9d1d9',
    fontFamily: 'Arial, sans-serif',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/stardust.png)',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  heading: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#58a6ff',
    textShadow: '0 0 10px #58a6ff',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  subHeading: {
    fontSize: '22px',
    marginBottom: '15px',
    color: '#58a6ff',
    textShadow: '0 0 5px #58a6ff',
  },
  link: {
    color: '#58a6ff',
    textDecoration: 'none',
  },
};

export default PrivacyPolicy;

