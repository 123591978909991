import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { doc, updateDoc, arrayUnion, getFirestore} from 'firebase/firestore';
import { caddDesignerFunctions } from './functions/caddDesignerFunctions';
import { bridgeEngineerFunctions } from './functions/bridgeEngineerFunctions';
import { getCaddDesignerFunctionOutputs } from './functions/caddDesignerFunctionOutputs';
import { getBridgeEngineerFunctionOutputs } from './functions/bridgeEngineerFunctionOutputs';

import OpenAI from "openai";


interface ToolCall {
  id: string;
  function: {
    name: string;
    parameters?: {
      type: string; // Adjust the type as needed
      properties: {
        location: {
          type: string;
          description: string;
        };
        // Add other parameters as needed
      };
      required?: string[];
    };
  };
}


  interface RequiresActionData {
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[];
      };
    };
  }
  

interface ProfessionalCreationWindowProps {
  assistants:  Assistant[];
  setAssistants: (assistants: Assistant[]) => void;  
  selectedProfessional: string;
  assistantName: string;
  setAssistantName: (name: string) => void;
  assistantInstructions: string;
  setAssistantInstructions: (instructions: string) => void;
  handleClose: () => void;
}

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })

  interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; // Optional property for different message types
  }
  

  interface Assistant {
    name: string;
    professional:string;
    id: string;
    chat: ChatMessage[];
    virtualId: string;
    instructions?: string;
    model:string,
    tool:string;
    vectorStore:string; 
    threadID: string; 
  }

  type FunctionParameters = {
    type: "object";
    properties: Record<string, { type: string; description: string; enum?: string[] }>;
    required: string[];
  };

  interface FunctionDefinition {
    name: string;
    description: string;
    parameters: FunctionParameters;
  }

  type AssistantTool =
  | { type: 'code_interpreter'; languages?: string[] }  // Making 'languages' optional
  | { type: 'file_search' }
  | { type: 'function'; function: FunctionDefinition };


  interface FunctionOutput {
    tool_call_id: string; // ID of the tool call
    output: string;       // Output value (adjust type as necessary)
  }


  async function cancelRun(threadId: string, runId: string) {
    try {
      // Call the OpenAI API to cancel the run
      const response = await openai.beta.threads.runs.cancel(threadId, runId);
  
      // If the cancel operation is successful, log the response
      console.log('Run cancelled successfully:', response);
    } catch (error) {
      // Handle errors during the cancel operation
      console.error('Error cancelling run:', error);
      throw new Error('Failed to cancel the run');
    }
  }
  
  
  export const handleRequiresAction = async (
    data: RequiresActionData,
    runId: string,
    threadId: string,
    submitToolOutputs: (toolOutputs: FunctionOutput[], runId: string, threadId: string) => Promise<void>,
    setAssistants: React.Dispatch<React.SetStateAction<Assistant[]>>, 
    currentAssistantIndex: number 
  ) => {
    try {
      const toolOutputs = data.required_action.submit_tool_outputs.tool_calls;
      let outputs: FunctionOutput[] = [];
  
      if (toolOutputs.length > 0) {
        const functionName = toolOutputs[0].function.name;
  
        const fetchOutputsWithTimeout = async (fetchFunction: (toolCalls: ToolCall[]) => Promise<FunctionOutput[]> | FunctionOutput[]) => {
          return new Promise<FunctionOutput[]>((resolve, reject) => {
            const timeout = setTimeout(() => {
              reject(new Error('Timeout: No output available'));
            }, 5000); // 5 seconds timeout
        
            try {
              const outputs = fetchFunction(toolOutputs);
              
              // If it's a Promise, await it
              if (outputs instanceof Promise) {
                outputs.then((result) => {
                  clearTimeout(timeout); // Clear timeout if function executes successfully
                  resolve(result);
                }).catch((error) => {
                  clearTimeout(timeout);
                  reject(error);
                });
              } else {
                clearTimeout(timeout); // Clear timeout if function executes successfully
                resolve(outputs);
              }
            } catch (error) {
              clearTimeout(timeout); // Clear timeout on error
              reject(error);
            }
          });
        };
        
        if (functionName.includes('caddDesigner')) {
          outputs = await fetchOutputsWithTimeout(getCaddDesignerFunctionOutputs);
        } else if (functionName.includes('bridgeEngineer')) {
          outputs = await fetchOutputsWithTimeout(getBridgeEngineerFunctionOutputs);
        }
      }
  
      // Check if outputs are empty before submitting
      if (outputs.length === 0) {
        throw new Error('Sorry, no information available at this time. Try again later.');
      }
  
      await submitToolOutputs(outputs, runId, threadId);
    } catch (error: any) {
      console.error('Error processing required action:', error);
  
      // Cancel the run when no output available
      cancelRun(threadId, runId).catch(console.error);
      const errorMessage: ChatMessage = {
        message: error.message || 'An error occurred while processing your request. Please try again later.',
        from: 'assistant',
        type: 'text',
      };
  
      setAssistants((prevAssistants) => {
        const newAssistants = [...prevAssistants];
        newAssistants[currentAssistantIndex] = {
          ...newAssistants[currentAssistantIndex],
          chat: [...newAssistants[currentAssistantIndex].chat, errorMessage],
        };
        return newAssistants;
      });
    }
  };
  
  

const ProfessionalCreationWindow: React.FC<ProfessionalCreationWindowProps> = ({
  assistants, 
  setAssistants,
  selectedProfessional,
  assistantName,
  setAssistantName,
  assistantInstructions,
  setAssistantInstructions,
  handleClose,
}) => {
    const [assistantTool] = useState('function');

    
    const getTools = (): AssistantTool[] => {
    if (selectedProfessional === "CADD Designer") {
        return caddDesignerFunctions; // Directly return the array
    } else if (selectedProfessional === "Bridge Engineer") {
        return bridgeEngineerFunctions; // Directly return the array
    }

    switch (assistantTool) {
        case 'function':
        return [];
        case 'code_interpreter':
        return [{ type: 'code_interpreter' }];
        default:
        return [{ type: 'file_search' }];
    }
    };


    const handleCreateAssistant = async () => {
        const safeAssistantName = assistantName?.trim() || `Assistant ${assistants.length + 1}`;
    
        if (!safeAssistantName || !assistantInstructions) {
          alert('Please fill in all required fields.');
          return;
        }
    
        try {
          console.log('Creating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
    
          const tools = getTools();
      
          const response = await openai.beta.assistants.create({
            name: safeAssistantName,
            model: 'gpt-4o-2024-08-06',
            instructions: assistantInstructions,
            tools,
          });
    
          console.log('Assistant created:', response);
    
          let vectorStoreId = '';
          if (tools[0].type === 'file_search') {
            const vectorStore = await openai.beta.vectorStores.create({
              name: `${safeAssistantName}_vectorStore`,
            });
            console.log('Vector store created:', vectorStore);
            vectorStoreId = vectorStore.id;
          }
    
          const virtualId = safeAssistantName.charAt(0).toUpperCase();
          const threadResponse = await openai.beta.threads.create();
          const threadID = threadResponse.id;
    
          const newAssistant: Assistant = {
            name: safeAssistantName,
            id: response.id,
            chat: [],
            professional:selectedProfessional,
            virtualId,
            tool: 'file_search',
            model: 'gpt-4',
            vectorStore: vectorStoreId,
            threadID,
          };
    
          const user = auth.currentUser;
          const db = getFirestore();
          if (user) {
            const userDocRef = doc(db, 'users', user.uid);
            const assistantData = {
              name: safeAssistantName,
              id: response.id,
              instructions: assistantInstructions,
              model: 'gpt-4',
              vectorStoreId,
              professonal:selectedProfessional,
              tool: tools[0].type,
              threadID,
            };
    
            await updateDoc(userDocRef, {
              assistants: arrayUnion(assistantData),
            });
    
            console.log('Assistant information saved to Firestore.');
          }
    
          setAssistants([...assistants, newAssistant]);
          handleClose();
          setAssistantName('');
          setAssistantInstructions('');
    
          alert('Assistant created and saved successfully.');
        } catch (error) {
          console.error('Failed to create assistant:', error);
          alert('Failed to create the assistant. Please try again.');
        }
      };
    

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3 className="modal-title">New {selectedProfessional} Information</h3>
        <div className="inputContainer">
          <label htmlFor="assistantName" className="label">{selectedProfessional} Name</label>
          <input
            id="assistantName"
            type="text"
            name="assistantName"
            value={assistantName}
            onChange={(e) => setAssistantName(e.target.value)}
            placeholder="Enter Assistant Name"
            required
            maxLength={50}
            className="asswindow-input"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="assistantInstructions" className="label">Instructions</label>
          <textarea
            id="assistantInstructions"
            name="assistantInstructions"
            value={assistantInstructions}
            onChange={(e) => setAssistantInstructions(e.target.value)}
            placeholder="Enter Instructions"
            required
            rows={4}
            className="asswindow-textarea"
          />
        </div>
        <div className="buttonContainer">
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="save-button" onClick={handleCreateAssistant }>
            Save
          </button>
        </div>

      </div>
    </div>
  );
};

export default ProfessionalCreationWindow;
