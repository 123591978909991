const subjectGroups = {
    A: [
      "Accounting", "Additive Manufacturing", "Administrative Assistant", "Air Conditioning and Refrigeration Service",
      "American Sign Language", "Anthropology", "Apparel Construction", "Architectural Studies", "Art & Design", 
      "Astronomy", "Audio/Video Arts Technology"
    ],
    B: [
      "Basic Adult Education (GED)", "Biological Science", "Biology", "Bookkeeper/Clerk", "Bread and Pastry Arts", 
      "Business Administration"
    ],
    C: [
      "Cannabis Science and Therapeutics", "Certified Nursing Assistant (CNA)", "Chemistry", "Communication", 
      "Computed Tomography", "Computer-Aided Design (CAD)", "Computer Information Systems", "Computer Numerical Control (CNC)", 
      "Computer Science", "Computer Support Technician", "Construction", "Criminal Justice", "Culinary Arts"
    ],
    D: [
      "Dental Hygiene", "Desktop Publishing", "Diagnostic Cardiac Sonography", "Diagnostic Medical Sonography", 
      "Dietetic Internship", "Digital Photography", "Drone Technology and Applications"
    ],
    E: [
      "Early Childhood Education", "Earth Science", "Economics", "Education", "Electronics Engineering Technology",
      "Elementary Education", "Emergency Medical Services", "Emergency Medical Technician (EMT)", 
      "Emergency and Disaster Management", "eMarketing", "Engineering", "English", "English as a Second Language (ESL)", 
      "English as a Second Language (ESL) Teacher Endorsement", "Environmental Science (Biology)"
    ],
    F: [
      "Family Child Care", "Fashion Design", "Fashion Graphic Arts", "Fashion Merchandising", "Fashion Studies", "Fast Track",
      "Financial Management", "Fine Arts", "Fire Science Technology", "French", "Food Service Management", 
      "Forensic Science"
    ],
    G: [
      "GED Preparation (HSE, High School Equivalency)", "German", "Geography", "Geographic Information Systems (GIS)",
      "Geology", "Graphic Arts Technology"
    ],
    H: [
      "HSE Preparation (High School Equivalency)", "Health Care Office Manager", "Health Education", 
      "Health Education Teaching Endorsement", "Health Information Technology", "Health Sciences",
      "Heating, Ventilation, Air Conditioning and Refrigeration HVACR", "History", "Honors Program", 
      "Hospitality Management", "Hotel Management", "Human Resource Management", "Humanities"
    ],
    I: [
      "Industrial Electronics Maintenance", "Information Systems", "Insurance", "Interior Design", "International Business", 
      "Inventory/Production Control"
    ],
    J: ["Japanese", "Justice Administration"],
    K: ["Kinesiology", "Kitchen and Bath Specialty"],
    L: [
      "Language", "Law Enforcement and Justice Administration", "Learning Communities", "Liberal Arts", 
      "Licensed Practical Nurse (LPN)", "Life Science", "Linguistics", "Literature", "Logistics"
    ],
    M: [
      "Maintenance Technology", "Mammography", "Management", "Manufacturing Technology", "Magnetic Resonance Imaging (MRI)", 
      "Marketing", "Mass Communication", "Massage Therapy", "Mathematics", "Mechatronics", "Medical Assistant", 
      "Medical Office Administration", "Middle School Education", "Mobile App Foundations", "Music"
    ],
    N: [
      "Network Administration", "Nursing Program", "Nursing: Certified Nursing Assistant (CNA) Certificate", 
      "Nursing: Practical Nursing (PN) Certificate", "Nursing: Registered Nurse (RN) Degree", "Nutrition"
    ],
    O: ["Office Assistant"],
    P: [
      "Package Design", "Paralegal Studies", "Paramedic", "Paraprofessional Educator", "Performing Arts", "Personal Training", 
      "Perspective and Rendering", "Philosophy", "Phlebotomy", "Photography", "Physical Education Teacher Endorsement", 
      "Physical Science", "Physical Therapist Assistant", "Physics", "Pipe Welding", "Political Science", 
      "Practical Nurse", "Private Security", "Procurement", "Psychology", "Public Safety Dispatcher", "Public Service"
    ],
    R: [
      "Radiologic Technology", "Refrigeration Service", "Respiratory Care Science", "Retail Merchandising"
    ],
    S: [
      "Customer Success and Sales", "Social Media Specialist", "Secondary Education", "Small Business Management", 
      "Social Science", "Sociology", "Software Development", "Sonography (Medical)", "Sonography (Cardiac)", "Spanish",
      "Special Education", "Speech Communication", "Sterile Processing and Distribution", "Study Abroad", 
      "Supply Chain Management", "Surgical Technology"
    ],
    T: [
      "Tax Accounting Assistant", "Teaching", "Teaching English as a Second Language (TESOL)", "Textiles", "Theatre Arts"
    ],
    V: ["Personalized Marketing (Graphic Arts Technology)"],
    W: ["Web Development", "Welding Technology", "World Languages"],
  };
  
  export default subjectGroups;
  