interface ToolCall {
  id: string;
  function: {
    name: string;
    parameters?: {
      type: string; // Adjust the type as needed
      properties: {
        location: {
          type: string;
          description: string;
        };
        // Add other parameters as needed
      };
      required?: string[];
    };
    arguments?: string; // Added to access the arguments
  };
}

interface FunctionOutput {
  tool_call_id: string; // ID of the tool call
  output: string;       // Output value (should be a string)
}

// Function to fetch current weather data
const getCurrentWeather = async (location: string, unit: string = 'Celsius') => {
  const apiKey = '6f2fd1a8178e9ea89b09d75a48b70e32'; 
  const unitParam = unit === 'Fahrenheit' ? 'imperial' : 'metric';
  
  const response = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?q=${encodeURIComponent(location)}&units=${unitParam}&appid=${apiKey}`
  );

  if (!response.ok) {
    throw new Error('Failed to fetch weather data');
  }

  const weatherData = await response.json();
  const temperature = weatherData.main.temp;
  const description = weatherData.weather[0].description;
  const cityName = weatherData.name;

  return {
    temperature: `${temperature}°${unit === 'Fahrenheit' ? 'F' : 'C'}`,
    description,
    location: cityName,
  };
};

// Function to fetch and format weather information
const fetchWeather = async (location: string) => {
  try {
    const weather = await getCurrentWeather(location, 'Fahrenheit'); // Pass the location
    return `The weather in ${weather.location} is ${weather.temperature} with ${weather.description}.`;
  } catch (error) {
    console.error('Unable to fetch weather information:', error);
    return 'Unable to fetch weather information.'; // Return a fallback message
  }
};

// Main function to get outputs for bridge engineer functions
export const getBridgeEngineerFunctionOutputs = async (
  toolCalls: ToolCall[],
): Promise<FunctionOutput[]> => {
  const outputs: FunctionOutput[] = [];

  for (const toolCall of toolCalls) {
    console.log('toolCall', toolCall);
    
    switch (toolCall.function.name) {
      // Output for traffic condition
      case 'bridgeEngineer-getTrafficCondition':
        outputs.push({
          tool_call_id: toolCall.id,
          output: 'Good',
        });
        break;


      //Output for get weather condition
      case 'bridgeEngineer-getWeatherCondition':
        const argumentsString = toolCall.function.arguments; 
        console.log('argumentsString', argumentsString);
        
        if (argumentsString) {
          const args = JSON.parse(argumentsString); 
          const location = args.location; 
          console.log('location', location);

          try {
            const weatherInfo = await fetchWeather(location); 
            console.log('weatherInfo',weatherInfo)

            outputs.push({
              tool_call_id: toolCall.id,
              output: weatherInfo,
            });
          } catch (error) {
            console.error('Error fetching weather:', error);
            outputs.push({
              tool_call_id: toolCall.id,
              output: 'Failed to fetch weather data',
            });
          }
        } else {
          outputs.push({
            tool_call_id: toolCall.id,
            output: 'Location not specified. Please provide a location.',
          });
        }
        break;


      // End of function output  
      default:
        outputs.push({
          tool_call_id: toolCall.id,
          output: 'Unknown function call',
        });
        break;
    }
  }

  return outputs;
};
