import React, { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage } from '../firebaseConfig'; // Ensure your Firebase config is imported
import { getDownloadURL, ref } from 'firebase/storage';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css'; // Import styles for the PDF viewer

const USHistory: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = 'black';

    // Fetch the PDF URL from Firebase Storage
    const fetchPdf = async () => {
      try {
        const pdfRef = ref(storage, 'admin/History of the United States - Wikipedia.pdf');
        const url = await getDownloadURL(pdfRef);
        setPdfUrl(url); // Set the PDF URL in state
      } catch (error) {
        console.error('Error fetching PDF file:', error);
      }
    };

    fetchPdf();

    return () => {
      document.body.style.backgroundColor = ''; // Reset to default when component unmounts
    };
  }, []);

  return (
    <div style={styles.container}>
        <p style={styles.paragraph}>
        <span style={styles.textHighlight}>Whoa there!</span> Maybe take a peek at this file first...
        </p>


      {/* PDF Viewer */}
      {pdfUrl ? (
        <div style={styles.pdfContainer}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfUrl} />
          </Worker>
        </div>
      ) : (
        <p>Loading PDF...</p>
      )}

      <div style={styles.buttonContainer}>
        <button
          style={styles.skipButton}
          onClick={() => navigate('/home')}
        >
          SKIP
        </button>
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    height: '100vh',
    backgroundColor: '#0d1117',
    color: '#c9d1d9',
    fontFamily: 'Arial, sans-serif',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/stardust.png)',
    backgroundSize: 'cover',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  paragraph: {
    fontSize: '18px', // Slightly larger font for emphasis
    marginBottom: '30px',
    color: '#58a6ff', // Cool glowing blue color
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif", // Use a modern font
    textShadow: '0 0 8px rgba(88, 166, 255, 0.8)', // Adds a glowing effect
    lineHeight: '1.6',
    animation: 'fadeIn 2s ease-in-out forwards', // Smooth fade-in effect
  },
  textHighlight: {
    color: '#ff7b72', // Bright color to highlight 'Whoa there!'
    fontWeight: 'bold',
    textShadow: '0 0 12px rgba(255, 123, 114, 0.8)', // Extra glow for the highlight
    fontSize: '20px', // Slightly larger size for emphasis
  },

  pdfContainer: {
    width: '85%',
    height: '400px',
    border: '2px solid #58a6ff',
    borderRadius: '10px',
    overflow: 'hidden',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },

  skipButton: {
    padding: '10px 20px',
    fontSize: '14px',
    color: '#ffffff',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    cursor: 'pointer',
    textShadow: '0 0 5px #58a6ff',
    border: 'none',
    transition: 'box-shadow 0.5s ease, transform 0.2s ease',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
    width: '25%',
    marginLeft:'-30px',
  },
};

export default USHistory;
