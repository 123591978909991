import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import NavBar from './NavBar';
import './Base.css'; // New CSS file for specific styling
import { useNavigate } from 'react-router-dom';

const MyPlan: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [isStarter, setIsStarter] = useState<boolean | null>(false); // Track user plan
  const [isPremium, setIsPremium] = useState<boolean | null>(false); // Track user plan
  const [isChat, setIsChat] = useState<boolean | null>(false); // Track user plan
  const [isProChat, setIsProChat] = useState<boolean | null>(false); // Track user plan

// Fetch the subscription status of the user from Firestore
useEffect(() => {
  const fetchSubscriptionStatus = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus || 'Starter'; // Default to "Starter"

      //console.log('subscription status',subscriptionStatus)


      if (subscriptionStatus === 'Starter') {
        setIsStarter(true);
      }
      if (subscriptionStatus === 'Premium') {
        setIsPremium(true);
      }
      if (subscriptionStatus === 'Chat') {
        setIsChat(true);
      }
      if (subscriptionStatus === 'ProChat') {
        setIsProChat(true);
      }
    }
  };

  fetchSubscriptionStatus();
}, []);

  const handleUpgradeClickPremium = () => {
    navigate('/billing-premium');
  };

  const handleUpgradeClickChat = () => {
    navigate('/billing-chat');
  };

  const handleUpgradeClickProChat = () => {
    navigate('/billing-prochat');
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => {
    setUserImage(loadedImageUrl);
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
        <div className="plans-content">
          <div className="content-inner">
            <h1 className="plan-title">My Plan 📋</h1>

            {/* Three-column table layout */}
            <table className="plan-table reduced-width-table"> {/* Add a specific class for reduced width */}
              <thead>
                <tr>
                  <th>Your Plan</th>
                  {isStarter === true ? (
                    <>
                      <td className="checkmark" style={{ color: 'green' }}>✔</td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClickPremium}
                        >
                          Upgrade
                        </button>
                      </td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClickChat}
                        >
                          Upgrade
                        </button>
                      </td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClickProChat}
                        >
                          Upgrade
                        </button>
                      </td>                      
                    </>
                  ) : isPremium === true ? (
                    <>
                      <td></td>
                      <td className="checkmark" style={{ color: 'green' }}>✔</td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClickChat}
                        >
                          Upgrade
                        </button>
                      </td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClickProChat}
                        >
                          Upgrade
                        </button>
                      </td>

                    </>
                  ) : isChat === true ? (
                    <>
                      <td></td>
                      <td></td>
                      <td className="checkmark" style={{ color: 'green' }}>✔</td>
                      <td>
                        <button
                          className="upgrade-button"
                          onClick={handleUpgradeClickProChat}
                        >
                          Upgrade
                        </button>
                      </td>

                    </>
                  ) : isProChat === true ? (
                    <>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="checkmark" style={{ color: 'green' }}>✔</td>
                    </>                    
                  ):(
                    <td colSpan={2}>Loading...</td>
                  )}

                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Plan Type</th>
                  <td>Starter Plan</td>
                  <td>Premium Plan</td>
                  <td>Chat Plan</td>   
                  <td>ProChat Plan</td>                                    
                </tr>
                <tr>
                  <th>Subscription Fees</th>
                  <td>Free</td>
                  <td>$4.99/month</td>
                  <td>$9.99/month</td>
                  <td>Contact Us</td>                                    
                </tr>
                <tr>
                  <th>Upload PDF Files and Texts</th>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>  
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>                                    
                </tr>
                <tr>
                  <th>Upload Images to Texts</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td> 
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>                                    
                </tr>
                <tr>
                  <th>Share Files to Others</th>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>  
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>                                   
                </tr>
                <tr>
                  <th>Number of PDF Files</th>
                  <td>10</td>
                  <td>50</td>
                  <td>Unlimited</td>   
                  <td>Unlimited</td>                                    
                </tr>
                <tr>
                  <th>Number of Audio Files</th>
                  <td>10</td>
                  <td>50</td>
                  <td>Unlimited</td>    
                  <td>Unlimited</td>                                   
                </tr>                
                <tr>
                  <th>Number of Video Files</th>
                  <td>10</td>
                  <td>50</td>
                  <td>Unlimited</td>   
                  <td>Unlimited</td>                                    
                </tr>                
                <tr>
                  <th>Number of Prompts in Chat</th>
                  <td>20 per Day</td>
                  <td>100 per Day</td>
                  <td>300 per Day</td>
                  <td>Unlimited</td>                  
                </tr>
                <tr>
                  <th>Number of Uploads in Chat</th>
                  <td>3 per Day</td>
                  <td>10 per Day</td>                  
                  <td>30 per Day</td>
                  <td>Unlimited</td>                  
                </tr>                
                <tr>
                  <th>Number of Questions per Document</th>
                  <td>10</td>
                  <td>Unlimited</td>                  
                  <td>Unlimited</td>
                  <td>Unlimited</td>                  
                </tr>
                <tr>
                  <th>Explore Public Files</th>             
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>  
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>                                   
                </tr>  
                <tr>
                  <th>File Collection and Download</th>             
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>  
                  <td className="checkmark"  style={{ color: 'green' }} >✔</td>                                    
                </tr>                  
                <tr>
                  <th>Create Summary and Q&A</th>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>                  
                </tr>
                <tr>
                  <th>Chatbot - Personal Assistant</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="cross" style={{ color: 'red' }}>x</td>                  
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>                  
                </tr>
                <tr>
                  <th>Chatbot - Professional</th>
                  <td className="cross" style={{ color: 'red' }}>x</td>
                  <td className="cross" style={{ color: 'red' }}>x</td>                  
                  <td className="cross" style={{ color: 'red' }}>x</td>    
                  <td className="checkmark" style={{ color: 'green' }}>✔</td>                  
                </tr>                
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  );
};

export default MyPlan;
