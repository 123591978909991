import React, { useState, useCallback,useEffect,useRef} from 'react';
import { Worker, Viewer} from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { getDownloadURL, ref,getMetadata, listAll,uploadString, uploadBytes,updateMetadata,getStorage} from 'firebase/storage';
import { auth, storage } from '../firebaseConfig';
import { FaCommentDots, FaPaperPlane,FaHandPaper, FaExpand,FaCrop, FaVolumeUp, FaClipboardCheck, FaClipboard,FaBackward,FaPlay,FaForward,FaPause,FaEyeSlash, FaEye,FaEraser} from 'react-icons/fa';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'; 
import { EventEmitter } from 'events';
import ReactPlayer from 'react-player/youtube';
import { PDFDocument, rgb} from 'pdf-lib';
import * as fontkit from 'fontkit'; 
import CustomAudioMotionAnalyzer from './AudioMotionAnalyzer'; // Adjust import path if necessary
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import { formatContent } from './FormatContent';
import { getAuth } from "firebase/auth";
import Qna from './Qna'; 
import html2canvas from 'html2canvas';
import OpenAI from "openai";
import { onAuthStateChanged} from 'firebase/auth';

const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
});

interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; 
}

interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    fileID: string;
    threadID: string; 
    vectorStore:string; 
}


interface FileData {
  name: string;
  userId: string;
  url: string;
  youtubeUrl: string;
  thumbnailUrl:string;
  refPath: string;
  createdAt: Date; 
  viewCount: number;
  documentType: string; 
  shareWithPublic: string; 
  userImageUrl: string; 
  qnaUrl:string;
  summaryUrl:string;   
  transcriptUrl:string;  
  fileTags:string;
  size:number;
  fileNumber:string;
  duration:number;
}

interface PdfViewerModalProps {
  isOpen: boolean;
  pdfUrl: string | null;
  fileName: string;  
  qnaStatus: Record<string, boolean>; 
  summaryStatus: Record<string, boolean>; 
  setSummaryStatus: React.Dispatch<React.SetStateAction<Record<string, boolean>>>; 
  onClose: () => void;
  onGenerateQuestions: (fileUrl: string) => void; 
  onGenerateSummaries: (fileUrl: string) => void; 
  currentFile: FileData | null;
  setCurrentFile: React.Dispatch<React.SetStateAction<FileData | null>>; 
  transcriptUrl: string;
  setTranscriptUrl: React.Dispatch<React.SetStateAction<string>>; 
  showPublic: boolean;
}

interface Event {
    event: string;
    data: any; // Adjust 'any' to a more specific type if you know the structure of 'data'
  }

  interface ToolCall {
    id: string;
    function: {
      name: string;
    };
  }
  
  interface RequiresActionData {
    required_action: {
      submit_tool_outputs: {
        tool_calls: ToolCall[];
      };
    };
  }

  interface LibraryItemType {
    question: string;
    options?: { [key: string]: string };
    answer: string;
  }

class EventHandler extends EventEmitter {
    client: OpenAI;
    constructor(client: OpenAI) {
      super();
      this.client = client;
    }
  
    async onEvent(event: Event) {
      try {
        console.log(event);
        // Retrieve events that are denoted with 'requires_action'
        // since these will have our tool_calls
        if (event.event === "thread.run.requires_action") {
          await this.handleRequiresAction(
            event.data as RequiresActionData,
            event.data.id,
            event.data.thread_id,
          );
        }
      } catch (error) {
        console.error("Error handling event:", error);
      }
    }
  
    async handleRequiresAction(data: RequiresActionData, runId: string, threadId: string) {
      try {
        const toolOutputs = data.required_action.submit_tool_outputs.tool_calls.map((toolCall) => {
          if (toolCall.function.name === "getCurrentTemperature") {
            return {
              tool_call_id: toolCall.id,
              output: "57",
            };
          } else if (toolCall.function.name === "getRainProbability") {
            return {
              tool_call_id: toolCall.id,
              output: "0.06",
            };
          }
          return null; // Add a default return value
        }).filter(Boolean); // Filter out any null values
    
        // Submit all the tool outputs at the same time
        await this.submitToolOutputs(toolOutputs, runId, threadId);
      } catch (error) {
        console.error("Error processing required action:", error);
      }
    }
    
    async submitToolOutputs(toolOutputs: any[], runId: string, threadId: string) {
      try {
        // Use the submitToolOutputsStream helper
        const stream = this.client.beta.threads.runs.submitToolOutputsStream(
          threadId,
          runId,
          { tool_outputs: toolOutputs },
        );
        for await (const event of stream) {
          this.emit("event", event);
        }
      } catch (error) {
        console.error("Error submitting tool outputs:", error);
      }
    }
  }

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({ 
  isOpen, 
  pdfUrl,
  fileName,
  qnaStatus,
  summaryStatus,
  setSummaryStatus,
  onClose,
  onGenerateQuestions,
  onGenerateSummaries,
  currentFile,
  setCurrentFile,
  transcriptUrl,
  setTranscriptUrl,
  showPublic,
  }) => {

  const toolbarPluginInstance = toolbarPlugin();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isQnaOpen, setIsQnaOpen] = useState(false);  
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);    
  const [newMessage, setNewMessage] = useState('');
  const [assistant, setAssistant] = useState<Assistant | null>(null);
  const [isAssistantLoading, setIsAssistantLoading] = useState(false); // Track assistant loading
  const [isSendDisabled, setIsSendDisabled] = useState(true); // Disable send button until assistant is ready
  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [selectionPosition, setSelectionPosition] = useState<{ top: number, left: number } | null>(null);
  const [copiedKey, setCopiedKey] = useState<number | null>(null); // Track which code snippet is copied
  const [showPreloadedMessage, setShowPreloadedMessage] = useState(true);  
  const [selectionWithinPDF, setSelectionWithinPDF] = useState(false); 
  const [plan, setPlan] = useState<string | null>(null); // State to track user plan
  const user = auth.currentUser;
  const [isPanning, setIsPanning] = useState(false); // Track panning mode
  const [currentZoom, setCurrentZoom] = useState(1.0); // Track current zoom level
  const mainPdfViewerRef = useRef<HTMLDivElement | null>(null);
  const summaryPdfViewerRef = useRef<HTMLDivElement | null>(null);
  const dummyDivRef = useRef<HTMLDivElement | null>(null); // Reference for the dummy div
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [firstName, setFirstName] = useState<string | null>(null); 
  const [copiedResponseIndex, setCopiedResponseIndex] = useState<number | null>(null); // Track copied response index
  const [isAssistantThinking, setIsAssistantThinking] = useState(false); 
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hover state
  const [audioInstance, setAudioInstance] = useState<SpeechSynthesisUtterance | null>(null); 
  const [pdfViewerWidth, setPdfViewerWidth] = useState(60); // Initial width in percentage
  const resizingRef = useRef(false);
  const [activeViewer, setActiveViewer] = useState<'main' | 'summary'>('main');
  const mainZoomPlugin = zoomPlugin();
  const summaryZoomPlugin = zoomPlugin();
  const { ZoomInButton: MainZoomInButton, ZoomOutButton: MainZoomOutButton} = mainZoomPlugin;
  const { ZoomInButton: SummaryZoomInButton, ZoomOutButton: SummaryZoomOutButton } = summaryZoomPlugin;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1.0);
  const [metadataDuration, setMetadataDuration] = useState<number | null>(null); 
  const [playingAudio, setPlayingAudio] = useState<string | null>(null);
  const [audioProgress, setAudioProgress] = useState<number>(0); // Audio progress state
  const playerRef = useRef<ReactPlayer | null>(null);
  const [libraryItems, setLibraryItems] = useState<LibraryItemType[]>([]);
  const [showAnswerIndexes, setShowAnswerIndexes] = useState<number[]>([]);
  const [selectedSubject] = useState<string | null>(null);
  const [textareaContent, setTextareaContent] = useState<string>('');
  const [isConverting, setIsConverting] = useState<boolean>(false); 
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [userUID, setUserUID] = useState<string>('');
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const [showTranscriptNote, setShowTranscriptNote] = useState<boolean>(false);  
  const [showTextArea, setShowTextArea] = useState<boolean>(true);
  const audioMotionAnalyzerRef = useRef<AudioMotionAnalyzer | null>(null);
  const [showSimpleAudioPlayer, setShowSimpleAudioPlayer] = useState<boolean>(true);
  const [showMotionAudioPlayer, setShowMotionAudioPlayer] = useState<boolean>(false);
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]); 
  const hasProcessedFileRef = useRef<string | null>(null);
  const isTranscribingRef = useRef(false); 
  const [mainTotalPages, setMainTotalPages] = useState<number>(0);
  const [mainCurrentPage, setMainCurrentPage] = useState<number>(1);
  const [summaryTotalPages, setSummaryTotalPages] = useState<number>(0);
  const [summaryCurrentPage, setSummaryCurrentPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState(
    activeViewer === 'main'
      ? `${mainCurrentPage}`
      : `${summaryCurrentPage}`
  );
  const [clickMain, setClickMain] = useState(false);  
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [endPoint, setEndPoint] = useState<{ x: number; y: number } | null>(null);
  const cropAreaRef = useRef<HTMLDivElement>(null);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const mainPageNavigationPluginInstance = pageNavigationPlugin();
  const summaryPageNavigationPluginInstance = pageNavigationPlugin();

// Destructure jumpToPage for each plugin
const { jumpToPage: jumpToMainPage } = mainPageNavigationPluginInstance;
const { jumpToPage: jumpToSummaryPage } = summaryPageNavigationPluginInstance;

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const uid = currentUser.uid;
      setUserUID(uid);
      setIsAnonymous(currentUser.isAnonymous);
      console.log('isanonymous',isAnonymous)
    }
  }, [isAnonymous]);
  

  useEffect(() => {
    // Scroll the dummy div into view when chat content changes
    if (dummyDivRef.current) {
      dummyDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assistant?.chat]); 


  const handleDocumentLoad = (e: any) => {
    // Set the initial scale based on the active viewer
    if (activeViewer === 'main') {
      if (mainZoomPlugin && typeof mainZoomPlugin.zoomTo === 'function') {
        mainZoomPlugin.zoomTo(1.0); // Set zoom for the main viewer
      } else {
        console.warn('Main zoom plugin is not initialized or invalid.');
      }
    } else if (activeViewer === 'summary') {
      if (summaryZoomPlugin && typeof summaryZoomPlugin.zoomTo === 'function') {
        summaryZoomPlugin.zoomTo(1.0); // Set zoom for the summary viewer
      } else {
        console.warn('Summary zoom plugin is not initialized or invalid.');
      }
    } else {
      console.warn('No active viewer detected.');
    }
  
    // Additional logic if required (e.g., setting total pages, resetting current page, etc.)
    const totalPages = e.doc.numPages; // Access total pages from the loaded document
    console.log('Total Pages:',totalPages)
    //console.log('activeViewer:',activeViewer)
    //console.log('INput Value',inputValue)
    setClickMain(false);
    setInputValue(`1 of ${totalPages}`);

    if (activeViewer === 'main') {
      setMainTotalPages(totalPages);
      setMainCurrentPage(1); // Reset to the first page for the main viewer

    } else if (activeViewer === 'summary') {
      setSummaryTotalPages(totalPages);
      setSummaryCurrentPage(1); // Reset to the first page for the summary viewer
    }

    //console.log('Main Total Pages:',mainTotalPages)
   // console.log('Summary Total Pages:',summaryTotalPages)

  };
  
  const checkTextSelection = useCallback(() => {
    const selection = window.getSelection();

    if (selection && selection.toString().trim() !== '') {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // Get the element where the text was selected
      const parentElement = range.commonAncestorContainer as HTMLElement;
      const pdfViewerElement = document.querySelector('.pdf-viewer-container');

      // Check if the selection is within the PDF viewer container
      if (pdfViewerElement && pdfViewerElement.contains(parentElement)) {
        setSelectedText(selection.toString());
        setSelectionPosition({
          top: rect.top + window.scrollY - 40, // Adjust for display action buttons
          left: rect.left + window.scrollX,
        });
        setSelectionWithinPDF(true); // Selection is within the PDF viewer
      } else {
        // If selection is outside the PDF viewer, reset selection states
        setSelectedText(null);
        setSelectionPosition(null);
        setSelectionWithinPDF(false);
      }
    } else {
      // Reset selection states if no text is selected
      setSelectedText(null);
      setSelectionPosition(null);
      setSelectionWithinPDF(false);
    }
  }, []);

  const db = getFirestore();

  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;

        setFirstName(userData?.firstName || null); // Set firstName for personalized responses

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus === 'Premium') planType = 'Premium';
        else if (subscriptionStatus === 'Chat') planType = 'Chat';
        else if (subscriptionStatus === 'ProChat') planType = 'ProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserPlan(user.uid); // Fetch user plan when authenticated
      } else {
        console.warn("No user authenticated, data fetching skipped.");
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [fetchUserPlan]);

  
  useEffect(() => {
    const interval = setInterval(checkTextSelection, 1000); // Check selection every second
    return () => clearInterval(interval);
  }, [checkTextSelection]);

  const handlePanClick = () => {
    setIsPanning((prev) => !prev);
  };

  const getScrollablePdfContainer = (container: HTMLElement): HTMLElement | null => {
    // Traverse child nodes to find a scrollable container
    for (const child of Array.from(container.children)) {
        const element = child as HTMLElement;
        const style = window.getComputedStyle(element);
        
        // Check for overflow properties indicating scrollability
        if (style.overflow === 'auto' || style.overflow === 'scroll') {
            return element;
        }
        
        // Recursively check deeper levels for a scrollable element
        const scrollableChild = getScrollablePdfContainer(element);
        if (scrollableChild) return scrollableChild;
    }
    return null;
};

const handleMouseDown = (e: React.MouseEvent) => {
  if (isPanning && e.button === 0) {
      const pdfViewerContainer = activeViewer === 'main' ? mainPdfViewerRef.current : summaryPdfViewerRef.current;

      if (!pdfViewerContainer) {
          console.error('PDF viewer container not found.');
          return;
      }

      // Using helper function to find scrollable content inside the viewer container
      const scrollableContent = getScrollablePdfContainer(pdfViewerContainer);
      
      if (!scrollableContent) {
          console.error("Scrollable PDF content not found.");
          return;
      }

      // Add the no-text-select class to disable text selection while panning
      pdfViewerContainer.classList.add('no-text-select');

      const startX = e.clientX;
      const startY = e.clientY;
      const initialScrollLeft = scrollableContent.scrollLeft;
      const initialScrollTop = scrollableContent.scrollTop;

      const handleMouseMove = (moveEvent: MouseEvent) => {
          const deltaX = moveEvent.clientX - startX;
          const deltaY = moveEvent.clientY - startY;
          scrollableContent.scrollLeft = initialScrollLeft - deltaX;
          scrollableContent.scrollTop = initialScrollTop - deltaY;
      };

      const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);

          // Remove the no-text-select class after panning ends
          pdfViewerContainer.classList.remove('no-text-select');
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  }
};

  useEffect(() => {
    const handleWheelZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const scaleFactor = e.deltaY < 0 ? 0.1 : -0.1;
        const newZoom = Math.max(0.5, Math.min(2.0, currentZoom + scaleFactor));
  
        if (activeViewer === 'main') {
          mainZoomPlugin.zoomTo(newZoom);
        } else if (activeViewer === 'summary') {
          summaryZoomPlugin.zoomTo(newZoom);
        }
  
        setCurrentZoom(newZoom);
      }
    };
  
    window.addEventListener('wheel', handleWheelZoom, { passive: false });
    return () => window.removeEventListener('wheel', handleWheelZoom);
  }, [mainZoomPlugin, summaryZoomPlugin, currentZoom, activeViewer]);
  
const handleFullPageClick = () => {
  if (activeViewer === 'main') {
    mainZoomPlugin.zoomTo(1.0); // Set zoom for main viewer
  } else if (activeViewer === 'summary') {
    summaryZoomPlugin.zoomTo(1.0); // Set zoom for summary viewer
  }
};

const handleAction = (action: 'question' | 'explain' | 'summary') => {
    if (!selectedText) return;

    let message: string;
    switch (action) {
      case 'question':
        // Set the text in the prompt input container for user to modify
        setNewMessage(`"${selectedText}" For the text above, `);
        setShowPreloadedMessage(false); 
        break;
      case 'explain':
        message = `Can you explain the following text: "${selectedText}"?`;
        sendMessage(message); // Send the message directly to chat
        break;
      case 'summary':
        message = `Can you summarize the following text: "${selectedText}"?`;
        sendMessage(message); // Send the message directly to chat
        break;
      default:
        return;
    }
  };

  const sendMessage = async (message: string) => {
    if (!assistant || !assistant.threadID) {
      console.error('Cannot send message: Assistant or threadID is not available.');
      return;
    }

    const updatedChat: ChatMessage[] = assistant ? [...assistant.chat, { message, from: 'user', type: 'text' }] : [];

    setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
    setLoading(true);
    setShowPreloadedMessage(false); 
    try {
      const content = [{ type: 'text' as const, text: message }];
      await openai.beta.threads.messages.create(assistant!.threadID, { role: 'user', content });
      await processAssistantResponse(updatedChat);
    } catch (error) {
      console.error('Error sending message:', error);
    }

    setNewMessage(''); // Clear the input field after sending
  };

  const handleQnaButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }

    //console.log('currentFile qna url',currentFile?.qnaUrl)
    //console.log('currentFile url',pdfUrl)

    setShowPreloadedMessage(true); 
    setIsQnaOpen((prev) => !prev);
  
  };
  

  const handleSummaryButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }
    setShowPreloadedMessage(true); 
    setActiveViewer('summary');
    setIsSummaryOpen((prev) => !prev);
  };
  
  const handleChatButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }
    setShowPreloadedMessage(true); 
    setIsChatOpen((prev) => !prev);
  
    if (!assistant) {
      if(currentFile?.documentType === 'Note'){
        await createTemporaryAssistant(pdfUrl);  
      } else{
        await createTemporaryAssistant(transcriptUrl);  
      }
    }
  };
  
// Function to create a temporary assistant
const createTemporaryAssistant = useCallback(async (pdfUrl: string) => {
    const assistantInstructions = 'You are an assistant to help people unserstands uploaded documents. You should respond to questions based on the document you have. When you provide responses, provide answers without including source citations.';
    setIsAssistantLoading(true);  // Start assistant loading
    setIsSendDisabled(true);  // Disable send button while loading assistant
    try {
      const assistantResponse = await openai.beta.assistants.create({
        name: 'PDF Assistant',
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });
  
      const assistantID = assistantResponse.id;  
      console.log('assistant ID', assistantID);
  
      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;
      console.log('thread ID', threadID);
  
      // Upload PDF using pdfUrl
      console.log('pdfURL',pdfUrl)
      const fileResponse = await fetch(pdfUrl);
      const fileBlob = await fileResponse.blob();

      const fileUploadResponse = await openai.files.create({
        file: new File([fileBlob], "document.pdf", { type: fileBlob.type }),  // Create file from the URL
        purpose: "assistants",
      });

      console.log('fileUploadResponse', fileUploadResponse);
  
      const fileID = fileUploadResponse.id;
  
      const vectorStoreResponse = await openai.beta.vectorStores.create({
        name: "Temporary Vector Store",
      });
  
      const vectorStoreID = vectorStoreResponse.id;
  
      console.log('vectorstore ID', vectorStoreID);
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });
      console.log('my vector store file', myVectorStoreFile);

  
  
      await openai.beta.assistants.update(assistantID, {
        tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });
  
      setAssistant({
        name: 'Temporary Assistant',
        id: assistantID,  // Store the real assistant ID
        chat: [],
        fileID: fileID,
        threadID: threadID,
        vectorStore: vectorStoreID,
      });
  
      setIsSendDisabled(false);  // Enable send button when assistant is ready
    } catch (error) {
      console.error('Error creating assistant:', error);
    } finally {
      setIsAssistantLoading(false);  // Stop assistant loading
    }
  }, []);

  const removeTemporaryAssistant = async () => {
    if (assistant) {
      try {
        await openai.files.del(assistant.fileID);
        console.log('file ID removed:', assistant.fileID);
  
        await openai.beta.threads.del(assistant.threadID);
        console.log('thread ID removed:', assistant.threadID);
  
        await openai.beta.vectorStores.del(assistant.vectorStore);
        console.log('vector store ID removed:', assistant.vectorStore);
  
        await openai.beta.assistants.del(assistant.id);
        console.log('assistant ID removed:', assistant.id);
  
        // Clear the assistant state by setting it to null
        setAssistant(null);
  
        setIsSendDisabled(true); // Disable send button after removing assistant
  
        // Log the assistant state after it's cleared
        setTimeout(() => {
          console.log('assistant removed:', assistant); // This might still log the old value due to async nature of setState
        }, 0);
      } catch (error) {
        console.error('Error removing assistant:', error);
      }
    }
  };
  
  const retryRequest = async (func: () => Promise<any>, retries = 3, defaultDelay = 1000): Promise<any> => {
    try {
      return await func();
    } catch (error: any) {
      if (error?.status === 429 && retries > 0) {
        const retryAfterMatch = error?.response?.data?.error?.message?.match(/after ([\d.]+) seconds/i);
        const retryAfter = retryAfterMatch ? parseFloat(retryAfterMatch[1]) * 1000 : defaultDelay;
        console.log(`Rate limit exceeded, retrying after ${retryAfter / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, retryAfter));
        return retryRequest(func, retries - 1, defaultDelay);
      } else {
        throw error;
      }
    }
  };

  const uploadFileToOpenAI = async (file: File) => {
    return await retryRequest(async () => {
      const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
      return response.id;
    });
  };

  const addAssistantImageMessage = async (message: string, fileIds: string[]) => {
    if (!assistant) return;

    const content: OpenAI.Beta.Threads.Messages.MessageContentPartParam[] = [
      { type: "text", text: message },
      ...fileIds.map(fileId => ({
        type: "image_file" as const,
        image_file: { file_id: fileId },
      })),
    ];

    await retryRequest(async () => {
      await openai.beta.threads.messages.create(
        assistant.threadID,
        {
          role: "user",
          content: content,
        }
      );
    });
  };
  

  const processAssistantResponse = async (updatedChat: ChatMessage[]) => {
    if (!assistant) return;
    try {
      if (!assistant.threadID) {
        console.error('No thread ID for this assistant.');
        return;
      }
  
      const client = new OpenAI({ 
        apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
        dangerouslyAllowBrowser: true,
      });
      const threadId = assistant.threadID;
      const eventHandler = new EventHandler(client);
      eventHandler.on("event", eventHandler.onEvent.bind(eventHandler));

        try {
          // Start the stream
          const stream = await openai.beta.threads.runs.stream(threadId, {
            assistant_id: assistant.id,
          });
  
          let partialMessage = ""; // To build the message incrementally
          const newUpdatedChat = updatedChat;

          let lastMessageIndex = -1; // Keep track of the last message index
          
          // Listen for events from the stream
          for await (const event of stream) {
            eventHandler.emit("event", event); // Emit each event to your EventHandler instance
  
            // Type assertion to access the 'delta' property
            if ((event as any).data.delta?.content?.length > 0) {
              const chunk = (event as any).data.delta.content[0].text.value;
              partialMessage += chunk; // Add the chunk to the partial message
  
              // Find the last assistant message if not already located
              if (lastMessageIndex === -1) {
                lastMessageIndex = newUpdatedChat.findIndex(
                  (msg) => msg.from === 'assistant' && !msg.message.trim()
                );
              }
  
              // Update the assistant's response incrementally
              console.log('lastMessageIndex',lastMessageIndex)

              if (lastMessageIndex !== -1) {
                newUpdatedChat[lastMessageIndex].message = partialMessage;
                console.log('updated chat1',newUpdatedChat)

              } else {
                newUpdatedChat.push({ message: partialMessage, from: 'assistant', type: 'text' });
                lastMessageIndex = newUpdatedChat.length - 1; // Set the new index
              }
  
              setAssistant((prev) => prev ? { ...prev, chat: newUpdatedChat } : null);
              setIsAssistantThinking(false); 
            }
          }
  
          setLoading(false); // Set loading to false after completion
        } catch (error) {
          console.error("Error handling stream:", error);
        }
      
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false); // Ensure loading is reset in case of error
    }
  };
  
  const handleSendMessage = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert("User is not authenticated.");
      return;
    }
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "users", currentUser.uid);
  
    setIsAssistantThinking(true);
  
    try {
      // Fetch the user's data from Firestore
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        alert("User profile not found in Firestore.");
        setIsAssistantThinking(false);
        return;
      }
  
      const userData = userDoc.data();
      const subscriptionStatus = userData?.subscriptionStatus || "Starter";
      const promptCount = userData?.dailyPromptCount || 0;
      const fileUploadCount = userData?.dailyFileUploadCount || 0;
  
      // Define daily limits for prompts and file uploads based on the plan
      const dailyPromptLimits: Record<string, number> = {
        Starter: 20,
        Premium: 100,
        Chat: 300,
      };
  
      const dailyFileUploadLimits: Record<string, number> = {
        Starter: 3,
        Premium: 10,
        Chat: 30,
      };
  
      const dailyPromptLimit = dailyPromptLimits[subscriptionStatus];
      const dailyFileUploadLimit = dailyFileUploadLimits[subscriptionStatus];
  
      // Check if the user has exceeded the daily prompt limit
      if (promptCount >= dailyPromptLimit) {
        alert(`You have reached your daily prompt limit of ${dailyPromptLimit} for the ${subscriptionStatus} plan.`);
        setIsAssistantThinking(false);
        return;
      }
  
      // Check if the user has exceeded the daily file upload limit
      if (uploadedFiles.length > 0 && fileUploadCount + uploadedFiles.length > dailyFileUploadLimit) {
        alert(`You can upload up to ${dailyFileUploadLimit} files per day for the ${subscriptionStatus} plan.`);
        setIsAssistantThinking(false);
        return;
      }
  
      if (isAssistantLoading) {
        console.log("Assistant is still loading. Please wait.");
        setIsAssistantThinking(false);
        return;
      }
  
      if (!assistant || !assistant.threadID) {
        console.error("Cannot send message: Assistant or threadID is not available.");
        setIsAssistantThinking(false);
        return;
      }
  
      if (newMessage.trim() === "" && uploadedFiles.length === 0) {
        setIsAssistantThinking(false);
        return;
      }
  
      let updatedChat: ChatMessage[] = assistant ? [...assistant.chat] : [];
      let fileQuestion = newMessage;
  
      const filePromises = uploadedFiles.map(async (file) => {
        const fileUrl = URL.createObjectURL(file);
  
        let matchingPreviewUrl;
        if (file.name === "Screenshot.png") {
          matchingPreviewUrl = imagePreviewUrls[0];
        } else {
          matchingPreviewUrl = imagePreviewUrls.find((url) => url.includes(file.name));
        }
  
        if (!matchingPreviewUrl) {
          console.error(`No preview URL found for file: ${file.name}`);
        }
  
        fileQuestion = `<img src="${matchingPreviewUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;
  
        const uploadedFileId = await uploadFileToOpenAI(file);
        return { fileUrl, fileId: uploadedFileId };
      });
  
      if (uploadedFiles.length > 0) {
        try {
          const fileData = await Promise.all(filePromises);
          const fileIds = fileData.map((file) => file.fileId);
  
          if (fileQuestion.trim() !== "") {
            updatedChat.push({ message: fileQuestion, from: "user", type: "text" });
          }
  
          setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
          setLoading(true);
  
          await addAssistantImageMessage(fileQuestion, fileIds);
          await processAssistantResponse(updatedChat);
  
          // Update daily prompt and file upload counts in Firestore
          await updateDoc(userDocRef, {
            dailyPromptCount: promptCount + 1,
            dailyFileUploadCount: fileUploadCount + uploadedFiles.length,
          });
  
          setIsAssistantThinking(false);
        } catch (error) {
          console.error("Error uploading files:", error);
          setIsAssistantThinking(false);
          return;
        }
      } else {
        if (newMessage.trim() !== "") {
          updatedChat.push({ message: newMessage, from: "user", type: "text" });
        }
  
        setAssistant((prev) => (prev ? { ...prev, chat: updatedChat } : null));
        setLoading(true);
  
        try {
          const content = [{ type: "text" as const, text: newMessage }];
          await retryRequest(async () => {
            await openai.beta.threads.messages.create(assistant!.threadID, { role: "user", content });
          });
          await processAssistantResponse(updatedChat);
  
          // Update daily prompt count in Firestore
          await updateDoc(userDocRef, { dailyPromptCount: promptCount + 1 });
          setIsAssistantThinking(false);
        } catch (error) {
          console.error("Error sending message:", error);
          setLoading(false);
          setIsAssistantThinking(false);
        }
      }
  
      setNewMessage("");
      setShowPreloadedMessage(false);
      setUploadedFiles([]);
      setImagePreviewUrls([]);
    } catch (error) {
      console.error("Error handling message:", error);
      setIsAssistantThinking(false);
    }
  };
  


  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const auth = getAuth(); // Get Firebase Auth instance
    const user = auth.currentUser; // Get the currently authenticated user
  
    if (!user) {
      console.error("No authenticated user found.");
      return;
    }
  
    if (e.target.files && e.target.files.length > 0) {
      const storage = getStorage(); // Initialize Firebase Storage
      const filesArray = Array.from(e.target.files); // Convert FileList to an array
  
      const uploadPromises = filesArray.map(async (file) => {
        // Include user's UID in the file name
        const fileRef = ref(storage, `chatImages/${user.uid}_${file.name}`); // UID appended to file name
  
        try {
          // Upload the file to Firebase Storage
          const uploadSnapshot = await uploadBytes(fileRef, file);
          console.log(`Uploaded file: ${uploadSnapshot.metadata.name}`);
  
          // Get the download URL for the uploaded file
          const downloadUrl = await getDownloadURL(fileRef);
          console.log(`File available at: ${downloadUrl}`);
  
          return downloadUrl; // Return the download URL
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          return null; // Return null for failed uploads
        }
      });
  
      try {
        // Wait for all files to upload
        const downloadUrls = await Promise.all(uploadPromises);
  
        // Filter out any null entries (failed uploads)
        const validUrls = downloadUrls.filter((url): url is string => url !== null); // Use a type guard to ensure only strings
  
        // Update state with the valid download URLs
        setImagePreviewUrls((prevUrls) => [...prevUrls, ...validUrls]); // Store Firebase URLs for preview
        setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]); // Store the file objects
      } catch (error) {
        console.error("Error handling file uploads:", error);
      }
    }
  };
  

  const handleClose = async (isCancel = false) => {
    setTextareaContent('');
    setLeaving(true);
    setIsPanning(false);
    if (assistant) {
      await removeTemporaryAssistant();
    }
  
    setLeaving(false);
  
    if (isCancel) {
      setIsChatOpen(false);
    } else {
      setIsChatOpen(false);        
      onClose();
    }
  };

  const handleQnaClose = () => {
    setIsQnaOpen(false);
  };

  const handleSummaryClose = () => {
    setIsSummaryOpen(false);
    if (activeViewer === 'summary') setActiveViewer('main');
  };

  // Function to read aloud the assistant's response
  const readAloud = (text: string) => {
    if (isPlaying && audioInstance) {
      // Stop playing if already playing
      speechSynthesis.cancel(); // Stop any ongoing speech
      setIsPlaying(false); // Reset playing state
      setAudioInstance(null); // Clear audio instance
      return; // Exit if we are stopping
    }

    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'en-US'; // Set the language as needed
    speech.onstart = () => {
      setIsPlaying(true); // Set playing state
      setAudioInstance(speech); // Store the audio instance
    }; 
    speech.onend = () => {
      setIsPlaying(false); // Reset playing state when done
      setAudioInstance(null); // Clear audio instance
    };

    speechSynthesis.speak(speech);
  };

  const handleCopyResponse = (index: number, response: string) => {
    navigator.clipboard.writeText(response).then(() => {
      setCopiedResponseIndex(index);
      setTimeout(() => setCopiedResponseIndex(null), 2000); // Reset after 2 seconds
    }).catch(err => console.error('Failed to copy:', err));
  };

  useEffect(() => {
    const updatePdfViewerWidth = () => {
      if (window.innerWidth > 600) {
        setPdfViewerWidth(60); // Set width to 60% for screens larger than 600px
      } else {
        setPdfViewerWidth(90); // Set width to 90% for screens smaller than 600px
      }
    };
  
    // Initial setup
    updatePdfViewerWidth();
  
    // Add resize listener
    window.addEventListener('resize', updatePdfViewerWidth);
  
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updatePdfViewerWidth);
    };
  }, []);
  
  const handleMouseDownOnResizeBar = (e: React.MouseEvent) => {
    e.preventDefault();
    resizingRef.current = true;
  
    const handleMouseMove = (event: MouseEvent) => {
      if (resizingRef.current && mainPdfViewerRef.current) {
        const newWidth = (event.clientX / window.innerWidth) * 100;
        setPdfViewerWidth(Math.min(95, Math.max(5, newWidth))); // Restrict width between 5% and 95%
      }
    };
  
    const handleMouseUp = () => {
      resizingRef.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };
  

  // Functions for simple audio player
  const handlePlayAudio = async (url: string,fileRefPath: string) => {
    if (audioRef.current) {
      try {
        if (playingAudio === url && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        } else {
          if (playingAudio === url) {
            audioRef.current.play();
            setIsPlaying(true);
          } else {
            audioRef.current.src = url;
            audioRef.current.currentTime = 0;
            audioRef.current.play();
            setPlayingAudio(url);
            setIsPlaying(true);
          }
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        alert('Failed to play the audio. Please try again.');
      }
    }
  };

  // Function to update progress of audio playback
  const handleAudioProgress = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
  
      // Use metadataDuration if available, otherwise fallback to audioRef.current.duration
      const duration = metadataDuration || audioRef.current.duration;
  
      if (isFinite(currentTime) && isFinite(duration) && duration > 0) {
        const progress = (currentTime / duration) * 100;
        setAudioProgress(progress);
      }
    }
  };

  const handleFastForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10; // Fast forward 10 seconds
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10; // Rewind 10 seconds
    }
  };

  const handleSpeedChange = () => {
    if (audioRef.current) {
      const newSpeed = playbackSpeed === 1.0 ? 1.5 : playbackSpeed === 1.5 ? 2.0 : 1.0;
      audioRef.current.playbackRate = newSpeed; // Set playback speed
      setPlaybackSpeed(newSpeed);
    }
  };

  // Function to handle seeking when dragging the progress bar
const handleAudioSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (audioRef.current && isFinite(audioRef.current.duration)) {
    const seekTime = (parseFloat(event.target.value) / 100) * audioRef.current.duration;
    if (isFinite(seekTime)) {
      audioRef.current.currentTime = seekTime;
    }
  }
};

  // Function to handle metadata load (duration availability)
  const handleMetadataLoaded = () => {
    if (audioRef.current && isFinite(audioRef.current.duration)) {
      setAudioProgress(0); // Reset progress when metadata is loaded
    }
  };

const handleToggleAnswer = (index: number) => {
  if (showAnswerIndexes.includes(index)) {
    setShowAnswerIndexes(showAnswerIndexes.filter(i => i !== index));
  } else {
    setShowAnswerIndexes([...showAnswerIndexes, index]);
  }
};


const handleDeleteItem = async (index: number) => {
  const currentUser = auth.currentUser;
  if (!currentUser || !selectedSubject) return;

  const confirmDeletion = window.confirm('Are you sure you want to delete this question?');
  if (!confirmDeletion) return;

  const updatedLibraryItems = libraryItems.filter((_, i) => i !== index);
  setLibraryItems(updatedLibraryItems);

  const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
  const subjectRef = ref(storage, subjectFileName);
  await uploadString(subjectRef, JSON.stringify(updatedLibraryItems));
};



const filterUnsupportedCharacters = useCallback((text: string): string => {
  // Replace specific problematic characters with placeholders or remove them
  const filteredText = text
    .replace(/[\u2190-\u21FF]/g, '[ARROW]') // Arrows
    .replace(/[\u2200-\u22FF]/g, '[MATH]') // Mathematical operators
    .replace(/[\u2300-\u23FF]/g, '[TECH]') // Miscellaneous technical symbols
    .replace(/[\u2500-\u257F]/g, '[BOX]') // Box drawing
    .replace(/[\u2580-\u259F]/g, '[BLOCK]') // Block elements
    .replace(/[\u25A0-\u25FF]/g, '[GEOMETRIC]') // Geometric shapes
    .replace(/[\u2600-\u26FF]/g, '[SYMBOL]') // Miscellaneous symbols
    .replace(/[\u2700-\u27BF]/g, '[DINGBAT]') // Dingbats
    .replace(/[\u2B00-\u2BFF]/g, '[SYMBOL]') // Miscellaneous symbols and arrows
    .replace(/[\u2C60-\u2C7F]/g, '[LATIN]') // Latin Extended-C
    .replace(/[\u2E80-\u2EFF]/g, '[CJK]') // CJK Radicals Supplement
    .replace(/[\u2F00-\u2FDF]/g, '[KANGXI]') // Kangxi Radicals
    .replace(/[\u2FF0-\u2FFF]/g, '[IDEOGRAPH]') // Ideographic Description Characters
    .replace(/[\u3000-\u303F]/g, '[CJK SYMBOL]') // CJK Symbols and Punctuation
    .replace(/[\u3040-\u309F]/g, '[HIRAGANA]') // Hiragana
    .replace(/[\u30A0-\u30FF]/g, '[KATAKANA]') // Katakana
    .replace(/[\u3100-\u312F]/g, '[BOPOMOFO]') // Bopomofo
    .replace(/[\u3130-\u318F]/g, '[HANGUL]') // Hangul Compatibility Jamo
    .replace(/[\u3190-\u319F]/g, '[KANBUN]') // Kanbun
    .replace(/[\u31A0-\u31BF]/g, '[BOPOMOFO]') // Bopomofo Extended
    .replace(/[\u31F0-\u31FF]/g, '[KATAKANA]') // Katakana Phonetic Extensions
    .replace(/[\u3200-\u32FF]/g, '[ENCLOSED CJK]') // Enclosed CJK Letters and Months
    .replace(/[\u3300-\u33FF]/g, '[CJK COMPAT]') // CJK Compatibility
    .replace(/[\u3400-\u4DBF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs Extension A
    .replace(/[\u4E00-\u9FFF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs
    .replace(/[\uA000-\uA48F]/g, '[YI]') // Yi Syllables
    .replace(/[\uA490-\uA4CF]/g, '[YI]') // Yi Radicals
    .replace(/[\uA700-\uA71F]/g, '[MODIFIER]') // Modifier Tone Letters
    .replace(/[\uA720-\uA7FF]/g, '[LATIN]') // Latin Extended-D
    .replace(/[\uA800-\uA82F]/g, '[SYLOTI NAGRI]') // Syloti Nagri
    .replace(/[\uA830-\uA83F]/g, '[NUMERIC]') // Common Indic Number Forms
    .replace(/[\uA840-\uA87F]/g, '[PHAGS-PA]') // Phags-pa
    .replace(/[\uA880-\uA8DF]/g, '[SAURASHTRA]') // Saurashtra
    .replace(/[\uA900-\uA92F]/g, '[KAYAH]') // Kayah Li
    .replace(/[\uA930-\uA95F]/g, '[REJANG]') // Rejang
    .replace(/[\uA960-\uA97F]/g, '[HANGUL]') // Hangul Jamo Extended-A
    .replace(/[\uAC00-\uD7AF]/g, '[HANGUL]') // Hangul Syllables
    .replace(/[\uD800-\uDB7F]/g, '[SURROGATE]') // High Surrogates
    .replace(/[\uDB80-\uDBFF]/g, '[SURROGATE]') // High Private Use Surrogates
    .replace(/[\uDC00-\uDFFF]/g, '[SURROGATE]') // Low Surrogates
    .replace(/[\uE000-\uF8FF]/g, '[PRIVATE]') // Private Use Area
    .replace(/[\uF900-\uFAFF]/g, '[CJK COMPAT]') // CJK Compatibility Ideographs
    .replace(/[\uFB00-\uFB4F]/g, '[ALPHABETIC]') // Alphabetic Presentation Forms
    .replace(/[\uFB50-\uFDFF]/g, '[ARABIC]') // Arabic Presentation Forms-A
    .replace(/[\uFE00-\uFE0F]/g, '[VARIATION]') // Variation Selectors
    .replace(/[\uFE10-\uFE1F]/g, '[VERTICAL]') // Vertical Forms
    .replace(/[\uFE20-\uFE2F]/g, '[COMBINING]') // Combining Half Marks
    .replace(/[\uFE30-\uFE4F]/g, '[CJK COMPAT]') // CJK Compatibility Forms
    .replace(/[\uFE50-\uFE6F]/g, '[SMALL FORM]') // Small Form Variants
    .replace(/[\uFE70-\uFEFF]/g, '[ARABIC]') // Arabic Presentation Forms-B
    .replace(/[\uFF00-\uFFEF]/g, '[HALFWIDTH]') // Halfwidth and Fullwidth Forms
    .replace(/[\uFFF0-\uFFFF]/g, '[SPECIAL]'); // Specials    
  return filteredText;
}, []);


const generateUniqueFileName = useCallback(
async (originalName: string): Promise<string> => {

  let folderName = '';

  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
        folderName= `videosTranscript/`;
      } else if (currentFile.documentType === "Recording") {
        folderName= `recordingTranscript/`;
      }
  }
  
  const storageRef = ref(storage, folderName);
  const fileList = await listAll(storageRef);

  let newFileName = originalName;
  let counter = 1;
  
  const existingFiles = fileList.items.map((file) => file.name);

  while (existingFiles.includes(newFileName + '.pdf')) {
    const fileNameWithoutExt = originalName;
    newFileName = `${fileNameWithoutExt}(${counter})`;
    counter++;
  }
  
  return `${newFileName}.pdf`;
},
[currentFile]
);


const getOriginalFileName = useCallback((url: string): string => {
  // Decode the URL to get the file path with normal slashes
  const decodedUrl = decodeURIComponent(url);
  
  // Extract the file name after removing the "recordings/" prefix
  const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('recordings/') + 'recordings/'.length);
  
  // Remove the file extension
  return fileName.split('.').slice(0, -1).join('.');
}, []);


const uploadTextAsPDF = useCallback(async (text: string) => {
  const pdfDoc = await PDFDocument.create();

  // Register fontkit
  pdfDoc.registerFontkit(fontkit as any);

  // Load a custom Unicode font from a TTF file
  const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const customFont = await pdfDoc.embedFont(fontBytes); // Embed custom font

  // Set up an 8x11 inch page in portrait format (8.5 inches wide, 11 inches tall)
  const pageWidth = 8.5 * 72;
  const pageHeight = 11 * 72;

  let page = pdfDoc.addPage([pageWidth, pageHeight]);
  const { width, height } = page.getSize();
  const fontSize = 12;
  const lineHeight = fontSize + 4;
  const margin = 50;
  const maxLineWidth = width - margin * 2;

  const sanitizedText = filterUnsupportedCharacters(text);
  const paragraphs = sanitizedText.split('\n');

  let y = height - margin;

  for (const paragraph of paragraphs) {
    const words = paragraph.split(' ');
    let currentLine = '';

    for (const word of words) {
      const testLine = currentLine + word + ' ';
      const testLineWidth = customFont.widthOfTextAtSize(testLine, fontSize);

      if (testLineWidth > maxLineWidth) {
        if (y - lineHeight < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = height - margin;
        }
        page.drawText(currentLine, {
          x: margin,
          y: y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        currentLine = word + ' ';
        y -= lineHeight;
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine.trim()) {
      if (y - lineHeight < margin) {
        page = pdfDoc.addPage([pageWidth, pageHeight]);
        y = height - margin;
      }
      page.drawText(currentLine.trim(), {
        x: margin,
        y: y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      y -= lineHeight;
    }

    // After processing each paragraph, add a line break
    y -= lineHeight;
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  const extractVideoId = (url: string): string => {
    // Extract the part of the URL after the last '/'
    const fileName = url.split('/').pop()?.split('?')[0]; 
    if (!fileName) return '';
  

  // Extract the video ID from the file name based on the assumed naming convention
  const parts = fileName.split('_');
  const videoId = parts.length > 1 ? parts.slice(1).join('_') : '';

    // Remove the ".url" suffix if it exists
    return videoId.replace('.url', '');
  };
  
 // console.log('seleced audio url',pdfUrl)

  let originalName = '';
  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
          console.log('selectedVideoUrl',pdfUrl)
          const originalName0 = pdfUrl ? extractVideoId(pdfUrl || '') : '';
          //console.log('originalName0',originalName0)
          originalName = userUID +'_' + originalName0 + '_transcript';

      } else if (currentFile.documentType === "Recording") {
          const originalName0 = getOriginalFileName(pdfUrl || '');
          originalName = originalName0 + '_transcript';
      }
  }

  //console.log('originalName',originalName)
  
  const uniqueFileName = await generateUniqueFileName(originalName);
  let folderName = '';
  if (currentFile) {
      if (currentFile.documentType === "YouTube Video") {
        folderName= `videosTranscript/`;
      } else if (currentFile.documentType === "Recording") {
        folderName= `recordingTranscript/`;
      }
  }

  //console.log('uniqueFileName',uniqueFileName )
  const storageRef = ref(storage, `${folderName}${uniqueFileName}`);

  try {
    const userFilesRef = ref(storage, folderName);
    const fileList = await listAll(userFilesRef);

    const currentUser = auth.currentUser;
    if (currentUser) {
      const uid = currentUser.uid;
      setUserUID(uid);
      setIsAnonymous(currentUser.isAnonymous);
    
    if (isAnonymous) {
     // console.log('isAnonymouse',isAnonymous)
      // Allow anonymous users to upload a maximum of 2 files
      const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
      if (existingFiles.length >= 2) {
        alert('Anonymous users can only upload up to two files.');
        return;
      }
    } else {
      const currentUser = auth.currentUser;
      if (currentUser) {
            const uid = currentUser.uid;
            setUserUID(uid);
            // For non-anonymous users, check Firestore for subscriptionStatus
            const firestore = getFirestore();
            const userDocRef = doc(firestore, 'users', uid);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();

            const isStarterUser = userData?.subscriptionStatus !== true; // Starter user if subscriptionStatus is not true

            if (isStarterUser) {
              // Check if the starter user has already uploaded 2 files
              const existingFiles = fileList.items.filter((item) => item.name.startsWith(uid));
              if (existingFiles.length >= 2) {
                alert('Starter users can only upload up to two files.');
                return;
              }
            }

            }
          }

          // Set custom metadata based on the user's selection
          const customMetadata = {
            customMetadata: {
              ShareWithPublic: 'No',
              UserID:userUID,
              DocumentType:'Trancript',
            },
          };

          await uploadBytes(storageRef, blob,customMetadata);
          const transcript_Url = await getDownloadURL(storageRef);
          // Update currentFile's custom metadata with the transcript_Url
          if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);

            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    TranscriptUrl: transcript_Url, // Add the transcriptUrl
                },
            };

            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              transcriptUrl: transcript_Url, 
            });

            if (currentFile.fileNumber) {
              const firestore = getFirestore();
              const fileDocRef = doc(firestore, "files", currentFile.fileNumber.toString());
          
              await setDoc(
                  fileDocRef,
                  { transcriptUrl: transcriptUrl }, 
                  { merge: true } 
              );
          
              console.log(`Added transcript URL to Firestore for document number: ${currentFile.fileNumber}`);
            }


           // console.log("Updated currentFile metadata with TranscriptUrl:", transcript_Url);
        }

          setIsConverting(false);
          console.log('PDF has been succesfully generated')

  }


  } catch (error) {
    console.error('Upload error:', error);
  }
}, [
  currentFile,
  setCurrentFile,
  filterUnsupportedCharacters,
  generateUniqueFileName,
  getOriginalFileName,
  userUID,
  isAnonymous,
  setIsConverting,
  pdfUrl,
  transcriptUrl,
]);



const handleSubmitScript = useCallback(async (content?: string) => {
  const textToSubmit = content ?? textareaContent.trim(); // Use argument or state value
  //console.log('starting to transcribe');

  if (textToSubmit) {
    await uploadTextAsPDF(textToSubmit);
  } else {
    alert('Please paste some information before submitting.');
  }
}, [textareaContent, uploadTextAsPDF]); // Add dependencies


const handleTranscribeAudio = useCallback(async (url: string) => {
 // console.log('Transcribing audio for', url);
  try {
    setTextareaContent('Processing the audio, please wait...');
    setIsConverting(true);

    // Step 1: Fetch the audio file from the provided URL
    const audioBlob = await fetch(url).then((res) => res.blob());

    // Step 2: Convert the audio Blob to base64 format (if necessary, Whisper API can accept Blob directly)
    const audioFile = new File([audioBlob], "audio.mp4", { type: audioBlob.type });
   // console.log("audio file type:", audioBlob.type)
    // Step 3: Call OpenAI's Whisper API for transcription
    const transcriptionText = await transcribeAudioWithOpenAI(audioFile);   
    setTextareaContent(transcriptionText); // Update the textarea with the transcription
    await handleSubmitScript(transcriptionText); // Save the full transcript

  } catch (error) {
    setTextareaContent('An error occurred during audio transcription. Please try again.');
    console.error('Error during transcription:', error);
  } finally {
    setIsConverting(false);
  }
}, [handleSubmitScript]);

const transcribeAudioWithOpenAI = async (audioFile: File): Promise<string> => {
  try {
    // Call OpenAI's Whisper API for transcription
    const transcription = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
      response_format: "text",
    });

   // console.log('transcription', transcription);

    // Convert the transcription to a string explicitly
    if (transcription && typeof transcription === 'string') {
      return transcription; // Return the transcription as a string
    } else if (transcription && transcription.text) {
      return String(transcription.text); // Safely convert the text to a string
    } else {
      throw new Error("No transcription text found in the response.");
    }
  } catch (error) {
    console.error("Error during transcription:", error);
    return "Error during transcription.";
  }
};



const handleTranscribeVideo = useCallback(async (youtubeUrl: string, url: string) => {
  setTextareaContent('Processing the video, please wait...');
  setIsConverting(true);
  console.log('yourtubeurl',youtubeUrl)

  try {
    const response = await fetch(`/api/download-captions?url=${encodeURIComponent(youtubeUrl)}`);

    if (response.headers.get('Content-Type') === 'text/plain; charset=utf-8') {
      // Process captions as plain text
      const captionContent = await response.text();
      setTextareaContent(captionContent);
      //console.log('Caption content fetched:', captionContent);
      await handleSubmitScript(captionContent); // Save the transcript dynamically
    } else if (response.headers.get('Content-Type') === 'audio/mpeg') {
      // Process captions as audio stream
      const reader = response.body?.getReader();

      if (reader) {
        let dynamicTranscript = ''; // Initialize an empty transcript
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          // Create a Blob from the chunk of audio data
          const audioBlob = new Blob([value], { type: 'audio/mpeg' });
          const audioFile = new File([audioBlob], 'audio_chunk.mp3', { type: 'audio/mpeg' });

          // Transcribe the chunk using Whisper
          const chunkTranscript = await transcribeAudioChunkWithWhisper(audioFile, openai);

          // Append the chunk to the dynamic transcript
          dynamicTranscript += chunkTranscript;

          // Update the state to dynamically show the transcript
          setTextareaContent((prev) => prev + chunkTranscript);
        }

        //console.log('Full transcript:', dynamicTranscript);
        await handleSubmitScript(dynamicTranscript); // Save the full transcript
      }
    }
  } catch (error) {
    console.error('Error fetching captions or audio:', error);
  } finally {
    setIsConverting(false);
  }
}, [ handleSubmitScript]);


// Helper function to transcribe each chunk using OpenAI Whisper
const transcribeAudioChunkWithWhisper = async (audioFile: File, openai: OpenAI): Promise<string> => {
  try {
    const transcription = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
      response_format: "text",
    });
    return String(transcription);
  } catch (error) {
    console.error('Error during transcription:', error);
    return ''; // Return empty string if error occurs
  }
};

useEffect(() => {
  const processVideoAndSubmitScript = async () => {

    if (!currentFile) {
      console.warn("No current file selected. Aborting transcription process.");
      return;
    }

    if (!currentFile.youtubeUrl) {
      console.warn("Current file does not have a valid youtubeURL. Aborting transcription process.");
      return;
    }

    if (currentFile.documentType !== 'YouTube Video') {
      console.log("Current file is not a recording. Skipping transcription.");
      return;
    }

    if (showPublic) {
      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "videosTranscript/";
      const folderRef = ref(storage, folderPath);

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
 
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );
    
          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            await getDownloadURL(highestFile);

          setShowTextArea(false);
          setShowTranscript(true);
          return; 
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Show note...");
        }
      }

      setShowTranscript(false);
      setShowTextArea(false);
      setShowTranscriptNote(true)
      return;
    }

    if (!currentFile.url) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (hasProcessedFileRef.current === currentFile.url) {
      console.log("This file has already been processed. Skipping transcription...");
      return;
    }

    if (isTranscribingRef.current) {
      console.log("Transcription already in progress. Skipping...");
      return;
    }

    try {
      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "videosTranscript/";
      const folderRef = ref(storage, folderPath);

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
 
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );
    
          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            const validUrl = await getDownloadURL(highestFile);
            //console.log("Transcript exists at:", validUrl);

          setTranscriptUrl(validUrl);
          setShowTextArea(false);
          setShowTranscript(true);
          hasProcessedFileRef.current = currentFile.url; // Mark as processed
          return; // Exit early if transcript exists
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Proceeding to transcription...");
        }
      }

      //console.log("Starting transcription for:", currentFile.url);
      isTranscribingRef.current = true;
      setTranscriptUrl(''); // Clear previous transcript URL
      setShowTranscript(false);
      setShowTextArea(true);

      try {
        await handleTranscribeVideo(currentFile.youtubeUrl, currentFile.url);
      } catch (transcriptionError) {
        console.error("Error during transcription or script submission:", transcriptionError);
      }

      const fileList = await listAll(folderRef);
    
      // Filter files to include only those with `cleanFileName1` as part of the name
      const matchingFiles = fileList.items.filter(item => 
        item.name.startsWith(cleanFileName1)
      );

      if (matchingFiles.length === 0) {
        console.warn("No matching files found for:", cleanFileName1);
      } else {
        // Sort files by number in filename, defaulting to 0 if no number exists
        const sortedFiles = matchingFiles.sort((a, b) => {
          const extractNumber = (name: string) => {
            const match = name.match(/\((\d+)\)\.pdf$/);
            return match ? parseInt(match[1], 10) : 0;
          };
          return extractNumber(a.name) - extractNumber(b.name);
        });

        // Get the last file (highest number or cleanFileName1 + .pdf)
        const highestFile = sortedFiles[sortedFiles.length - 1];

      try {
        const validUrl = await getDownloadURL(highestFile);
        console.log("New transcript validated at:", validUrl);
        setTranscriptUrl(validUrl);
        setShowTextArea(false);
        setShowTranscript(true);
        currentFile.transcriptUrl = validUrl; 
        hasProcessedFileRef.current = currentFile.url; 

      } catch (validationError) {
        console.error("Failed to validate new transcript URL:", validationError);
      }
    }
    } catch (error) {
      console.error("Error during transcription process:", error);
    } finally {
      isTranscribingRef.current = false; // Reset transcription flag
    }
  };

  if (currentFile) {
    processVideoAndSubmitScript();
  }
}, [currentFile]); // eslint-disable-line react-hooks/exhaustive-deps


useEffect(() => {
  const processRecordingAndSubmitScript = async () => {
    if (!currentFile) {
      console.warn("No current file selected. Aborting transcription process.");
      return;
    }

    if (currentFile.documentType !== 'Recording') {
      console.log("Current file is not a recording. Skipping transcription.");
      return;
    }

    if (showPublic) {


      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");
      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "recordingTranscript/";
      const folderRef = ref(storage, folderPath);


      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {

        try {
 
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );

          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            await getDownloadURL(highestFile);

          setShowTextArea(false);
          setShowTranscript(true);

          return; 
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Show note...");
        }
      }

      setShowTranscript(false);
      setShowTextArea(false);
      setShowTranscriptNote(true)
      return;
    }

    if (!currentFile.url) {
      console.warn("Current file does not have a valid URL. Aborting transcription process.");
      return;
    }

    if (hasProcessedFileRef.current === currentFile.url) {
      console.log("This file has already been processed. Skipping transcription...");
      return;
    }

    if (isTranscribingRef.current) {
      console.log("Transcription already in progress. Skipping...");
      return;
    }

    try {
      //console.log("Checking transcript URL:", currentFile.transcriptUrl || "No transcript URL");

      const fileName = currentFile.url.split('/').pop()?.split('?')[0]; // Extract the file name without folder
      if (!fileName) throw new Error("Invalid file name extracted from URL.");

      const cleanFileName0 = fileName.split('%2F').pop()?.replace(/%20/g, ' '); 
      const cleanFileName1 = cleanFileName0!.replace(/\..+$/, "_transcript"); 
      
      const folderPath = "recordingTranscript/";
      const folderRef = ref(storage, folderPath);

      // Check if the transcript URL exists
      if (currentFile.transcriptUrl) {
        try {
          
          const fileList = await listAll(folderRef);
          const matchingFiles = fileList.items.filter(item => 
            item.name.startsWith(cleanFileName1)
          );
    
          if (matchingFiles.length === 0) {
            console.warn("No matching files found for:", cleanFileName1);
          } else {
            // Sort files by number in filename, defaulting to 0 if no number exists
            const sortedFiles = matchingFiles.sort((a, b) => {
              const extractNumber = (name: string) => {
                const match = name.match(/\((\d+)\)\.pdf$/);
                return match ? parseInt(match[1], 10) : 0;
              };
              return extractNumber(a.name) - extractNumber(b.name);
            });
    
            // Get the last file (highest number or cleanFileName1 + .pdf)
            const highestFile = sortedFiles[sortedFiles.length - 1];
            const validUrl = await getDownloadURL(highestFile);
            //console.log("Transcript exists at:", validUrl);

          setTranscriptUrl(validUrl);
          setShowTextArea(false);
          setShowTranscript(true);
          hasProcessedFileRef.current = currentFile.url; // Mark as processed
          return; // Exit early if transcript exists
          }

        } catch (error) {
          console.warn("Transcript URL fetch failed. Proceeding to transcription...");
        }
      }

      //console.log("Starting transcription for:", currentFile.url);
      isTranscribingRef.current = true;

      setTranscriptUrl(''); // Clear previous transcript URL
      setShowTranscript(false);
      setShowTextArea(true);

      // Perform transcription
      await handleTranscribeAudio(currentFile.url);

      const fileList = await listAll(folderRef);
    
      // Filter files to include only those with `cleanFileName1` as part of the name
      const matchingFiles = fileList.items.filter(item => 
        item.name.startsWith(cleanFileName1)
      );

      if (matchingFiles.length === 0) {
        console.warn("No matching files found for:", cleanFileName1);
      } else {
        // Sort files by number in filename, defaulting to 0 if no number exists
        const sortedFiles = matchingFiles.sort((a, b) => {
          const extractNumber = (name: string) => {
            const match = name.match(/\((\d+)\)\.pdf$/);
            return match ? parseInt(match[1], 10) : 0;
          };
          return extractNumber(a.name) - extractNumber(b.name);
        });

        // Get the last file (highest number or cleanFileName1 + .pdf)
        const highestFile = sortedFiles[sortedFiles.length - 1];

        try {
        const validUrl = await getDownloadURL(highestFile);
        console.log("New transcript validated at:", validUrl);


        setTranscriptUrl(validUrl);
        setShowTextArea(false);
        setShowTranscript(true);
        currentFile.transcriptUrl = validUrl; // Update file metadata
        hasProcessedFileRef.current = currentFile.url; // Mark as processed

      } catch (validationError) {
        console.error("Failed to validate new transcript URL:", validationError);
      }

      }
    } catch (error) {
      console.error("Error during transcription process:", error);
    } finally {
      isTranscribingRef.current = false; // Reset transcription flag
    }
  };

  if (currentFile) {
    processRecordingAndSubmitScript();
  }
}, [currentFile]); // eslint-disable-line react-hooks/exhaustive-deps


// Functions for motion audio player
const fetchAudioFromProxy = async (firebaseAudioUrl: string) => {
  try {
    const proxyUrl = `/api/proxy-audio?url=${encodeURIComponent(firebaseAudioUrl)}`;
    const response = await fetch(proxyUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch audio from proxy');
    }
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching audio:', error);
    return null;
  }
};

const getAudioUrl = async (url: string,fileRefPath:string) => {
    // Fetch metadata for duration
    const storageRef = ref(storage, fileRefPath);
    const metadata = await getMetadata(storageRef);
    const duration = metadata.customMetadata?.Duration;
    //console.log('fileRefPath',fileRefPath)
    //console.log('duration',duration)
    if (duration) {
      setMetadataDuration(parseFloat(duration));
    } else {
      setMetadataDuration(null);
    }
  return fetchAudioFromProxy(url);
};


  useEffect(() => {
    if (!audioMotionAnalyzerRef.current) {
      const container = document.getElementById('audio-motion-container');
      if (container) {
        audioMotionAnalyzerRef.current = new AudioMotionAnalyzer(container, {
          mode: 1, // Replace with the correct numeric value for 'waveform'
          gradient: 'rainbow',
        });
      }
    }

    return () => {
      // Cleanup AudioMotionAnalyzer
      audioMotionAnalyzerRef.current?.disconnectInput();
      audioMotionAnalyzerRef.current?.destroy();
      audioMotionAnalyzerRef.current = null;
    };
  }, []);

  const onPageChange = (page: number, viewer: 'main' | 'summary') => {
    const totalPages = viewer === 'main' ? mainTotalPages : summaryTotalPages;

    console.log('viewer:', viewer);
    // Ensure the page is within valid bounds
    if (page >= 1 && page <= totalPages) {
     // console.log('Attempting to jump to page:', page);
  
      if (viewer === 'main') {
       // console.log('Jumping in main viewer');
        jumpToMainPage(page - 1); // Use main viewer's jumpToPage
        setMainCurrentPage(page);
        setInputValue(`${page} of ${mainTotalPages}`);
      } else if (viewer === 'summary') {
        //console.log('Jumping in summary viewer');
        jumpToSummaryPage(page - 1); // Use summary viewer's jumpToPage
        setSummaryCurrentPage(page);
        setInputValue(`${page} of ${summaryTotalPages}`);
      } else {
        console.warn('Unknown viewer');
      }
    } else {
      console.warn('Invalid page number:', page);
    }
  };

  const onMainPageChange = (page: number) => {
    onPageChange(page, 'main');
  };
  
  const onSummaryPageChange = (page: number) => {
    onPageChange(page, 'summary');
  };


  // Handle page load initialization
  useEffect(() => {
    const initializePageInput = () => {
      const totalPages =
        activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
       
        //console.log('mainCurrent Page',mainCurrentPage)

      // Ensure totalPages is a valid number
      if (totalPages && totalPages > 0) {
        // Set the initial page to 1
        setInputValue(`1 of ${totalPages}`);
        if (activeViewer === 'main') {
          setMainCurrentPage(1);
        } else {
          setSummaryCurrentPage(1);
        }
      } else {
        // Handle edge cases where totalPages is undefined or invalid
        setInputValue(`1 of ...`);
      }
    };
  
    initializePageInput();
  }, [activeViewer, mainTotalPages, summaryTotalPages]);
  

  useEffect(() => {
    //console.log('INput value2',inputValue)
    const pdfContainer =
      activeViewer === 'main' ? mainPdfViewerRef.current : summaryPdfViewerRef.current;
  
    if (!pdfContainer) {
      console.error('pdfContainer is not defined.');
      return;
    }
  
    const scrollableContainer = pdfContainer.querySelector(
      '.rpv-core__inner-pages'
    ) as HTMLElement;
  
    if (!scrollableContainer) {
      console.error('Scrollable container (.rpv-core__inner-pages) not found.');
      return;
    }
  
    let currentVisiblePage = 1;
  
    // Function to calculate the visible page using scroll position
    const calculateVisiblePage = () => {
      const totalPages = activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
  
      // Approximate height of a single page
      const pdfPages = scrollableContainer.querySelectorAll('.rpv-core__page-layer');
      if (pdfPages.length === 0) {
        console.error('No pages detected in the DOM.');
        return;
      }
  
      const singlePageHeight = pdfPages[0].getBoundingClientRect().height;
  
      // Current scroll position and approximate visible page
      const scrollPosition = scrollableContainer.scrollTop;
      const visiblePage = Math.floor(scrollPosition / singlePageHeight) + 1;
  
      // Ensure visiblePage stays within the total pages
      currentVisiblePage = Math.min(Math.max(visiblePage, 1), totalPages);
  
      //console.log(`Visible page: ${currentVisiblePage} of ${totalPages}`);
  
      if (activeViewer === 'main') {
        setMainCurrentPage(currentVisiblePage);
      } else {
        setSummaryCurrentPage(currentVisiblePage);
      }
  
      setInputValue(`${currentVisiblePage} of ${totalPages}`);
    };
  
    const handleScroll = () => {
      calculateVisiblePage();
    };
  
    // Attach scroll listener
    scrollableContainer.addEventListener('scroll', handleScroll);
  
    // Trigger initial calculation
    calculateVisiblePage();
  
    // Observe DOM changes to detect new pages being added
    const domObserver = new MutationObserver(() => {
      //console.log('DOM updated, recalculating visible page.');
      calculateVisiblePage(); // Recalculate pages on new page load
    });
  
    domObserver.observe(scrollableContainer, { childList: true, subtree: true });
  
    return () => {
      scrollableContainer.removeEventListener('scroll', handleScroll);
      domObserver.disconnect();
    };
  }, [activeViewer, mainTotalPages, summaryTotalPages, mainPdfViewerRef, summaryPdfViewerRef,clickMain]);
  
  // Function to allow user to crop a rectangle area to chat
  const handleCropClick = () => {
    setIsCropping(true);
    setStartPoint(null);
    setEndPoint(null);
  };
  
  const handleMouseDown1 = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isCropping && cropAreaRef.current) {
      const rect = cropAreaRef.current.getBoundingClientRect();
  
      // Calculate the start point relative to the crop area
      setStartPoint({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top + window.scrollY, // Add scroll offset
      });
    }
  };
  
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isCropping && startPoint && cropAreaRef.current) {
      const rect = cropAreaRef.current.getBoundingClientRect();
  
      // Calculate the end point relative to the crop area
      setEndPoint({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top + window.scrollY, // Add scroll offset
      });
    }
  };
  
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isCropping && startPoint && cropAreaRef.current) {
      const rect = cropAreaRef.current.getBoundingClientRect();
  
      // Finalize the end point relative to the crop area
      const newEndPoint = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top + window.scrollY, // Add scroll offset
      };
  
      setEndPoint(newEndPoint);
      setIsCropping(false);
  
      // Perform the screenshot operation
      captureCropArea(startPoint, newEndPoint);
    }
  };
  

  const captureCropArea = async (
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) => {
    if (!cropAreaRef.current) {
      console.error("Crop area reference is null");
      return;
    }
  
    const rect = {
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y),
      width: Math.abs(end.x - start.x),
      height: Math.abs(end.y - start.y),
    };
  
    try {
      const canvas = await html2canvas(cropAreaRef.current);
      const scaleX = canvas.width / cropAreaRef.current.offsetWidth;
      const scaleY = canvas.height / cropAreaRef.current.offsetHeight;
  
      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = rect.width * scaleX;
      croppedCanvas.height = rect.height * scaleY;
  
      const context = croppedCanvas.getContext("2d");
      if (!context) {
        console.error("Failed to get canvas context");
        return;
      }
  
      context.drawImage(
        canvas,
        rect.x * scaleX,
        rect.y * scaleY,
        rect.width * scaleX,
        rect.height * scaleY,
        0,
        0,
        rect.width * scaleX,
        rect.height * scaleY
      );
  
      croppedCanvas.toBlob(async (blob) => {
        if (blob) {
          try {
            // Save to clipboard
            await navigator.clipboard.write([
              new ClipboardItem({
                "image/png": blob,
              }),
            ]);

            alert("Screenshot saved to clipboard and sent to chat if open!");
  
            // Additional functionality: Save to uploadedFiles and imagePreviewUrls
            if (isChatOpen) {
              const file = new File([blob], "Screenshot.png", { type: "image/png" });
              const url = URL.createObjectURL(file);
  
              // Update uploadedFiles
              setUploadedFiles((prevFiles) => [...prevFiles, file]);
  
              // Update imagePreviewUrls
              setImagePreviewUrls((prevUrls) => [...prevUrls, url]);
  
              console.log("Screenshot saved to uploadedFiles and imagePreviewUrls");
            }
          } catch (err) {
            console.error("Failed to write to clipboard or save:", err);
          }
        }
      });
    } catch (error) {
      console.error("Error capturing crop area:", error);
    }
  };
  

  // CSS to prevent text selection
  useEffect(() => {
    if (isCropping) {
      document.body.style.userSelect = "none";
    } else {
      document.body.style.userSelect = "auto";
    }
  
    return () => {
      document.body.style.userSelect = "auto";
    };
  }, [isCropping]);
  

  const removeImage = (index: number) => {
    // Remove the image from imagePreviewUrls
    const updatedUrls = [...imagePreviewUrls];
    updatedUrls.splice(index, 1);
    setImagePreviewUrls(updatedUrls);
    // Empty uploaded file
    setUploadedFiles([]);
  };
  
  const handleImageClick = (url: string) => {
    setCurrentImage(url);
    setShowPopup(true);
  };
  

  const closePopup = () => {
    setShowPopup(false);
    setCurrentImage(null);
  }

  
  if (!isOpen || !pdfUrl) return null;

  return (

    <div className="modal-overlay"  
        ref={cropAreaRef}
        onMouseDown={handleMouseDown1}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
      <div className={`pdf-viewer-model ${isChatOpen||isQnaOpen||isSummaryOpen ? 'expanded' : 'shrink'}`} style={{ cursor: isPanning ? 'grab' : 'default' }}>
        <div className="pdf-toolbar">
          <div className="zoom-buttons-container" style={{ display: 'flex', gap: '10px' }}>

          {activeViewer === 'main' && (
              <>
                <MainZoomInButton key="main-zoom-in" />
                <MainZoomOutButton key="main-zoom-out" />
              </>
            )}
            {activeViewer === 'summary' && (
              <>
                <SummaryZoomInButton key="summary-zoom-in" />
                <SummaryZoomOutButton key="summary-zoom-out" />
              </>
            )}

            <button 
              onClick={handlePanClick} 
              style={{ background: 'none', border: 'none', cursor: 'pointer', color: isPanning ? 'blue' : 'white' }}
            >
              <FaHandPaper size={18} title="Pan" />
            </button>

            <button onClick={handleFullPageClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <FaExpand size={18} title="One Full Page" />
            </button>

            <button
                onClick={handleCropClick}
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              >
                <FaCrop size={18} title="Crop Screen" />
              </button>


            <input
              className="pdf-page-input"
              type="text"
              value={inputValue}
              placeholder={`1 of ${
                activeViewer === 'main' ? mainTotalPages : summaryTotalPages
              }`}
              onChange={(e) => {
                // Allow free input, including blank
                setInputValue(e.target.value);
              }}
              onBlur={() => {
                const totalPages =
                  activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
                const newPage = parseInt(inputValue, 10);

                if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                  // Navigate to the page if valid
                  if (activeViewer === 'main') {
                    onMainPageChange(newPage);
                  } else {
                    onSummaryPageChange(newPage);
                  }
                  // Set the formatted value
                  setInputValue(`${newPage} of ${totalPages}`);
                } else {
                  // Reset to current page if invalid
                  setInputValue(
                    activeViewer === 'main'
                      ? `${mainCurrentPage} of ${mainTotalPages}`
                      : `${summaryCurrentPage} of ${summaryTotalPages}`
                  );
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const totalPages =
                    activeViewer === 'main' ? mainTotalPages : summaryTotalPages;
                  const newPage = parseInt(inputValue, 10);

                  if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
                    // Navigate to the page if valid
                    if (activeViewer === 'main') {
                      onMainPageChange(newPage);
                    } else {
                      onSummaryPageChange(newPage);
                    }
                    // Set the formatted value
                    setInputValue(`${newPage} of ${totalPages}`);
                  } else {
                    // Reset to current page if invalid
                    setInputValue(
                      activeViewer === 'main'
                        ? `${mainCurrentPage} of ${mainTotalPages}`
                        : `${summaryCurrentPage} of ${summaryTotalPages}`
                    );
                  }
                  e.currentTarget.blur();
                }
              }}
            />

          </div>

          <div className="action-buttons-container" style={{ display: 'flex', gap: '10px' }}>

          {currentFile && currentFile.documentType === 'Note' && (
            <div className="generation-buttons-container" style={{ display: 'flex', gap: '10px' }}>
              {/* Generate Questions Button */}
              <button
                onClick={() => onGenerateQuestions(pdfUrl!)}
                title="Generate Questions"
                className="qna-button"
                style={{ color: qnaStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                Q
              </button>

              {/* Generate Summary Button */}
              <button
                onClick={() => onGenerateSummaries(pdfUrl!)}
                title="Generate Summary"
                className="summary-button"
                style={{ color: summaryStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                S
              </button>
            </div>
          )}

          {currentFile && (currentFile.documentType === 'YouTube Video' || currentFile.documentType === 'Recording')  && (
            <div className="generation-buttons-container" style={{ display: 'flex', gap: '10px' }}>
              {/* Generate Questions Button */}
              <button
                onClick={() => onGenerateQuestions(transcriptUrl!)}
                title="Generate Questions"
                className="qna-button"
                style={{ color: qnaStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                Q
              </button>

              {/* Generate Summary Button */}
              <button
                onClick={() => onGenerateSummaries(transcriptUrl!)}
                title="Generate Summary"
                className="summary-button"
                style={{ color: summaryStatus[pdfUrl!] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
              >
                S
              </button>
            </div>
          )}


          <button
            className="chat-button"
            onClick={() => handleQnaButtonClick()} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Q & A"
          >
           Q & A
          </button>

          <button
            className="chat-button"
            onClick={() => {handleSummaryButtonClick();}} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Summary"
          >

          <FaClipboard size={18} />
          </button>

          {/* Chat Button */}
          <button
            className="chat-button"
            onClick={() => handleChatButtonClick()} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Chat"
            disabled={isAssistantLoading} // Disable the button while loading
          >
            {isAssistantLoading ? "Loading..." : <FaCommentDots size={18} />}
          </button>

          {leaving && <div className="leaving-overlay">Leaving...</div>}
        </div>

          <button
            onClick={() => handleClose(false)}
            className="close-viewer-button"
            >
            Close
        </button>

        </div>

        <div className={`modal-container ${isChatOpen ? 'expanded' : 'shrink'}`}
           >

          {isCropping && startPoint && endPoint && (
            <div
              style={{
                position: 'absolute',
                left: Math.min(startPoint.x, endPoint.x),
                top: Math.min(startPoint.y, endPoint.y),
                width: Math.abs(endPoint.x - startPoint.x),
                height: Math.abs(endPoint.y - startPoint.y),
                border: '2px solid black',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                pointerEvents: 'none',
                zIndex: 99999,
              }}
            />
          )}


          <div
            className="pdf-viewer-container"
            ref={mainPdfViewerRef}
            onMouseDown={handleMouseDown}
            onClick={(e) => {
              e.stopPropagation(); // Prevent propagation if necessary
              //console.log('Container clicked');
              setActiveViewer('main'); // Ensure this function updates the state properly
              //console.log('active viewer',activeViewer);
              setClickMain(true);
            }}

            style={{ width: `${pdfViewerWidth}%` }}
          >

          {currentFile && (
            <>
              {currentFile.documentType === 'Note' && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  
                  <Viewer 
                    fileUrl={pdfUrl} 
                    plugins={[mainZoomPlugin, toolbarPluginInstance,mainPageNavigationPluginInstance]} 
                    onDocumentLoad={(e) => handleDocumentLoad(e)}
                  />
                </Worker>
              )}

          {currentFile.documentType === 'Recording' && (
            <div>
              {/* Pull-down menu for selecting audio player */}
              <div className="audio-player-selector">
                <select
                  value={showSimpleAudioPlayer ? 'simpleAudioPlayer' : 'motionAudioPlayer'}
                  onChange={(e) => {
                    const selectedPlayer = e.target.value;
                    if (selectedPlayer === 'simpleAudioPlayer') {
                      setShowSimpleAudioPlayer(true);
                      setShowMotionAudioPlayer(false);
                    } else {
                      setShowSimpleAudioPlayer(false);
                      setShowMotionAudioPlayer(true);
                    }
                  }}
                >
                  <option value="simpleAudioPlayer">Simple Audio Player</option>
                 {/*  <option value="motionAudioPlayer">Motion Audio Player</option>*/}
                </select>
              </div>

              {/* Render Simple Audio Player */}
              {showSimpleAudioPlayer && (
                <div className="audio-play-bar">
                  <div className="audio-controls">
                    <button onClick={handleRewind}><FaBackward size={18} /></button>
                    <button
                      onClick={() => {
                        handlePlayAudio(currentFile.url, currentFile.refPath);
                      }}
                    >
                      {isPlaying ? <FaPause size={18} /> : <FaPlay size={18} />}
                    </button>
                    <button onClick={handleFastForward}><FaForward size={18} /></button>
                    <button onClick={handleSpeedChange}>
                      {`${playbackSpeed}x`}
                    </button>
                  </div>
                  <div className="audio-progress-container">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={isNaN(audioProgress) ? 0 : audioProgress}
                      onChange={handleAudioSeek}
                      className="audio-progress-bar"
                    />
                  </div>

                  <div style={{ textAlign: 'center', fontSize: '14px', color: '#555', marginTop: '10px' }}>
                    <span style={{ fontWeight: 'bold' }}>Elapsed:</span>{' '}
                    {new Date((audioRef.current?.currentTime ?? 0) * 1000).toISOString().substr(11, 8)}{' '}
                    <span style={{ fontWeight: 'bold', margin: '0 5px' }}>/</span>{' '}
                    <span style={{ fontWeight: 'bold' }}>Total:</span>{' '}
                    {new Date((currentFile.duration ?? 0) * 1000).toISOString().substr(11, 8)}
                  </div>
                </div>
              )}

              {/* Render Motion Audio Player */}
              {showMotionAudioPlayer && (
                <div>
                  <CustomAudioMotionAnalyzer
                    fetchAudioBlob={() => getAudioUrl(currentFile.url, currentFile.refPath)}
                    onAudioContextReady={(analyzerInstance: AudioMotionAnalyzer) => {
                      audioMotionAnalyzerRef.current = analyzerInstance;
                    }}
                    metadataDuration={metadataDuration}
                  />
                </div>
              )}

              <h3 style={{ color: 'white' }}>Transcript</h3>

              {showTranscriptNote && (
                 <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>
                  No transcript available. Transcript can only be generated within My Space. 
                 </p>
               )}

              {showTextArea && (
              <div className="textarea-container" style={{ position: 'relative' }}>
                <textarea
                  value={textareaContent}
                  onChange={(e) => setTextareaContent(e.target.value)}
                  placeholder="Paste Information Here"
                  className="textarea-input"
                  readOnly={isConverting}
                  ref={textareaRef} // Attach ref to textarea
                />
              </div>
              )}
              {showTranscript && (
              <div className="audio-transcript" >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer 
                    fileUrl={transcriptUrl || currentFile.transcriptUrl} 
                    plugins={[mainZoomPlugin, toolbarPluginInstance,mainPageNavigationPluginInstance]} 
                    onDocumentLoad={(e) => handleDocumentLoad(e)}
                  />
                </Worker>
              </div>

              )}

            </div>
          )}

            {currentFile.documentType === 'YouTube Video' && (
              <div>
                <div className="video-container">
                  <ReactPlayer
                    ref={playerRef}
                    url={currentFile.youtubeUrl || undefined} // Use undefined if no URL
                    playing={Boolean(currentFile.youtubeUrl)}
                    controls
                    className="react-player"
                    width="100%" // Make the player take the full width of the parent
                    height="100%" // Optional: Adjust height as needed
                  />
                </div>
                <h3 style={{ color: 'white' }}>Transcript</h3>

                {showTranscriptNote && (
                 <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>
                  No transcript available. Transcript can only be generated within My Space. 
                 </p>
               )}

               {showTextArea && (
                <div className="textarea-container" style={{ position: 'relative' }}>
                  <textarea
                    value={textareaContent}
                    onChange={(e) => setTextareaContent(e.target.value)}
                    placeholder="Paste Information Here"
                    className="textarea-input"
                    readOnly={isConverting}
                    ref={textareaRef} // Attach ref to textarea
                  />
                </div>
               )}

               {showTranscript && (
                <div className="video-transcript">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer 
                      fileUrl={transcriptUrl || currentFile.transcriptUrl} 
                      plugins={[mainZoomPlugin, toolbarPluginInstance, mainPageNavigationPluginInstance]} 
                      onDocumentLoad={(e) => handleDocumentLoad(e)}
                    />
                  </Worker>
                </div>

               )}

              </div>
            )}

            {currentFile.documentType === 'Subject' && (
              <div className="library-items" style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
                {libraryItems.map((item, index) => (
                  <div key={index} className="library-item" style={{ marginBottom: '10px' }}>
                    <div
                      className="library-card"
                      style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px'}}
                    >
                      <div className="front">
                        <p style={{ color: 'white' }}><strong>Question:</strong> {item.question}</p>
                        {item.options && (
                          <ul style={{ paddingLeft: '20px' }}>
                            {Object.entries(item.options).map(([key, value]) => (
                              <li key={key} style={{ color: 'white' }}>
                                <strong>{key}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        )}
                        {showAnswerIndexes.includes(index) && (
                          <p style={{ marginTop: '10px', color: 'white' }}>
                            <strong>Answer:</strong> {item.answer}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Button container */}
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px', marginLeft: '100px' }}>
                      <button 
                        title={showAnswerIndexes.includes(index) ? "Hide Answer" : "See Answer"} 
                        onClick={() => handleToggleAnswer(index)} 
                        className="icon-button"
                      >
                        {showAnswerIndexes.includes(index) ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                      </button>
                      <button title="Delete" onClick={() => handleDeleteItem(index)} className="icon-button">
                        <FaEraser size={20} /> 
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}


        </div>

          <div 
            className="resize-bar" 
            onMouseDown={handleMouseDownOnResizeBar} 
          />

          {isQnaOpen && (
              <div className="chat-window">
                  <div className="chat-header">
                      <h3 style={{ color: 'white' }}>Q&A</h3>                
                      <button
                          className="cancel-chat-button"
                          onClick={() => handleQnaClose()}
                          style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
                      >
                          Cancel
                      </button>
                  </div>
                  
                  {/* Integrate Qna component here */}
                  <Qna 
                   currentFile={currentFile}
                   />
              </div>
          )}

          {isSummaryOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Summary</h3>

            <button
                className="cancel-chat-button"
                onClick={() => handleSummaryClose()}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
              >
                Cancel
            </button>

            </div>

            <div 
              className="pdf-viewer-container" 
              onClick={(e) => {
                e.stopPropagation(); // Prevent propagation if necessary
                //console.log('Container clicked');
                setActiveViewer('summary'); // Ensure this function updates the state properly
                //console.log('active viewer',activeViewer);

              }}
              ref={summaryPdfViewerRef}
              onMouseDown={(e) => {
               // console.log('Mouse down event');
                handleMouseDown(e);
              }}
              style={{ width: '98%', zIndex: 1 }} // Added zIndex for potential overlap issues
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {currentFile?.summaryUrl ? (
                  <Viewer 
                    fileUrl={currentFile.summaryUrl} 
                    plugins={[summaryZoomPlugin, toolbarPluginInstance, summaryPageNavigationPluginInstance]}  
                    onDocumentLoad={(e) => handleDocumentLoad(e)}
                  />
                ) : (
                  <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>
                    No summary available. Generate a summary with the "S" button first. Green "S" indicates a summary is available; Red "S" indicates not available.
                  </p>
                )}
              </Worker>
            </div>

            </div>
          )}

          {isChatOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Chat</h3>
            <button
                className="cancel-chat-button"
                onClick={() => handleClose(true)}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
              >
                Cancel
              </button>
            </div>

            <div className="chat-content">
            {/* Preloaded message */}
            {showPreloadedMessage && (
                <p style={{ color: 'white', textAlign: 'left', fontSize: '14px' }}>
                    You can ask any questions related to the document. You can also select specific texts from the PDF to ask a question, explain the text or provide a summary.
                </p>
            )}


                {assistant?.chat.map((chatMsg, index) => (
                  <div 
                    key={index} 
                    onMouseEnter={() => setHoveredIndex(index)} 
                    onMouseLeave={() => setHoveredIndex(null)} 
                    style={{ position: 'relative' }} // Position relative for absolute button positioning
                  >
                    <p
                      style={{
                        color: chatMsg.from === 'user' ? 'white' : 'white',
                        textAlign: chatMsg.from === 'user' ? 'right' : 'left',
                        fontSize: '14px',
                      }}
                    >
                      <strong>
                        {chatMsg.from === 'user' 
                          ? firstName || 'You' 
                          : 'Assistant'}:
                      </strong>

                      <div className="assistant-response-container">
                        {formatContent(chatMsg.message, copiedKey, setCopiedKey)}

                        {chatMsg.from === 'assistant' && (
                          <div 
                            className="pdf-response-buttons" 
                            style={{
                              display: index === assistant.chat.length - 1 || hoveredIndex === index ? 'block' : 'none',
                              position: 'absolute',
                              right: '0',
                              bottom: '-30px',
                            }}
                          >
                            <button 
                              onClick={() => readAloud(chatMsg.message)} 
                              title="Read Aloud" 
                              style={{ cursor: 'pointer', marginRight: '5px', color: isPlaying ? 'green' : 'inherit' }}
                            >
                              {isPlaying ? <FaVolumeUp style={{ color: 'green' }} size={16} /> : <FaVolumeUp size={16} />}
                            </button>
                            <button 
                              onClick={() => handleCopyResponse(index, chatMsg.message)} 
                              title="Copy Response"
                              style={{ cursor: 'pointer' }}
                            >
                              {copiedResponseIndex === index ? <FaClipboardCheck size={16}/> : <FaClipboard size={16} />}
                            </button>
                          </div>
                        )}
                      </div>
                    </p>
                  </div>
                ))}

                {/* Show thinking indicator after the last user message */}
                  {isAssistantThinking && (
                    <div className="thinking-indicator">
                      <div className={`chat-message assistant`}>
                      <strong>
                         Assistant:
                      </strong>
                      </div>
                      <div className="dot"></div>
                    </div>
                  )}
            {/* Dummy div to ensure scrolling to the bottom */}
            <div ref={dummyDivRef} />
            </div>
              <div className="chat-input-container">
              {imagePreviewUrls.length > 0 && (

                <div className="image-preview">
                  {imagePreviewUrls.map((url, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        margin: "10px",
                      }}
                    >
                      <img
                        src={url}
                        alt={`Preview ${index + 1}`}
                        style={{ width: "50px", height: "50px" }}
                        onClick={() => handleImageClick(url)}
                      />
                      <button
                        onClick={() => removeImage(index)} // Pass the current index
                        style={{
                          position: "absolute",
                          top: "-24px",
                          right: "-5px",
                          backgroundColor: "transparent",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
                
                )}

              <div className="input-row">
                <label 
                  title="Upload" 
                  htmlFor="file-upload" 
                  style={{ cursor: 'pointer', alignSelf: 'flex-end', color: 'white' }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                </label>
                <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />
                <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);

                        // Reset height to 30px if the input is empty
                        if (e.target.value.trim() === '') {
                            e.target.style.height = '30px';
                        } else {
                            // Dynamically adjust the height based on content
                            e.target.style.height = 'auto'; // Reset the height to calculate the scrollHeight properly
                            e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`; // Cap the height at 200px
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                    placeholder="Type a message"
                    className="chat-input"
                    rows={1}
                    disabled={isSendDisabled} // Disable sending until assistant is ready
                    style={{ height: newMessage ? 'auto' : '30px' }} // Reset the height to 30px when empty
                />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? setLoading(false) : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                  disabled={isSendDisabled} // Disable sending until assistant is ready
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>
                </div>
              </div>

            </div>
          )}
          {/* Show action buttons near the selected text */}
          {selectedText && selectionWithinPDF && selectionPosition && isChatOpen && (
            <div
              style={{
                position: 'absolute',
                top: `${selectionPosition.top}px`,
                left: `${selectionPosition.left}px`,
                zIndex: 1000,
                backgroundColor: 'transparent',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <button
                className="pdf-text-button"
                onClick={() => handleAction('question')}
              >
                Question
              </button>
              <button
                className="pdf-text-button"
                onClick={() => handleAction('explain')}
              >
                Explain
              </button>
              <button
                className="pdf-text-button"
                onClick={() => handleAction('summary')}
              >
                Summary
              </button>
            </div>
          )}
            <audio
            ref={audioRef} // Reference to control the audio element
            onTimeUpdate={handleAudioProgress} // Update the progress bar as the audio plays
            onLoadedMetadata={handleMetadataLoaded} // Reset progress bar when metadata (like duration) is loaded
            onEnded={() => setPlayingAudio(null)} // Reset when audio finishes
            />

            {/* Pop-up for larger image */}
            {showPopup && currentImage && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={currentImage}
                    alt="Larger Preview"
                    style={{ maxWidth: "90vw", maxHeight: "90vh" }}
                  />
                  <button
                    onClick={closePopup}
                    style={{
                      position: "absolute",
                      top: "-15px", // Move above the image
                      right: "-15px", // Move to the right outside the image
                      backgroundColor: "white",
                      color: "black",
                      border: "none",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Optional: add some styling for a better look
                    }}
                  >
                    ×
                  </button>
                </div>
              </div>
            )}

        </div>
      </div>
    </div>
  );
};

export default PdfViewerModal;
