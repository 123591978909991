import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider,setPersistence, browserLocalPersistence} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyADUF3OboIwLpmNC1GbOcIzx1nkkEBJCeU",
    authDomain: "compreqna.firebaseapp.com",
    projectId: "compreqna",
    storageBucket: "compreqna.appspot.com",
    messagingSenderId: "165277558101",
    appId: "1:165277558101:web:99386e5f41631b91a97bdc",
    measurementId: "G-MT1LECHRSK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Your other auth setup or sign-in code here, if any
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });
// Initialize Firebase Storage and get a reference to the service
const storage = getStorage(app);

export const database = getDatabase(app);
export { auth, googleProvider, storage };