// caddDesignerFunctionOutputs.tsx
interface ToolCall {
    id: string;
    function: {
      name: string;
    };
  }
  
  interface FunctionOutput {
    tool_call_id: string; // ID of the tool call
    output: string;       // Output value (adjust type as necessary)
  }

export const getCaddDesignerFunctionOutputs = (toolCalls: ToolCall[]): FunctionOutput[] => {
  return toolCalls.map((toolCall) => {
    switch (toolCall.function.name) {
      case 'caddDesigner-getCurrentTemperature':
        return {
          tool_call_id: toolCall.id,
          output: '75', // Example output for temperature
        };

      case 'caddDesigner-getRainProbability':
        return {
          tool_call_id: toolCall.id,
          output: '0.20', // Example output for rain probability
        };
        
      default:
        return null; // Return null for unhandled cases
    }
  }).filter((output): output is FunctionOutput => output !== null); // Type guard to filter out nulls
};
