// bridgeEngineerFunctions.tsx
type FunctionParameters = {
  type: "object";
  properties: Record<string, { type: string; description: string; enum?: string[] }>;
  required: string[];
};

interface FunctionDefinition {
  name: string;
  description: string;
  parameters: FunctionParameters;
}

type AssistantTool =
| { type: 'code_interpreter'; languages?: string[] }  // Making 'languages' optional
| { type: 'file_search' }
| { type: 'function'; function: FunctionDefinition };

export const bridgeEngineerFunctions: AssistantTool[] = [
  //Function for get traffic condition
  {
    type: "function" as const,
    function: {
      name: "bridgeEngineer-getTrafficCondition",
      description: "Get the traffic condition for a specific location",
      parameters: {
        type: "object" as const,
        properties: {
          location: {
            type: "string",
            description: "The city and state, e.g., San Francisco, CA",
          },
        },
        required: ["location"],
      },
    },
  },
  //Function for get weather condition
  {
    type: "function" as const,
    function: {
      name: "bridgeEngineer-getWeatherCondition",
      description: "Get the weather condition for a specific location",
      parameters: {
        type: "object" as const,
        properties: {
          location: {
            type: "string",
            description: "The city and state, e.g., San Francisco, CA",
          },
        },
        required: ["location"],
      },
    },
  },


 
// End of Functions

];