import './Base.css';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore methods
import { auth } from '../firebaseConfig';
import NavBar from './NavBar';

const BillingCancel: React.FC = () => {
  const location = useLocation(); 
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleViewHomePage = () => {
    navigate('/home');
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      try {
        const userDocRef = doc(getFirestore(), 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
          setUserImage(userData.imageUrl || '/path-to-user-image.png');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadedBio: string) => {
    setFirstName(loadedFirstName);
    setLastName(loadedLastName);
    setUserImage(loadedImageUrl);
  };

  const handleCancelSubscription = async () => {
    await fetch('/api/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: location.state.subscription, 
      }),
    });
    navigate('/billing-account', { replace: true });
  };

  const handleCancelClick = () => {
    setShowModal(true);
  };

  const handleProceedCancel = () => {
    setShowModal(false);
    handleCancelSubscription();
  };

  const handleStopCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="container">
      <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />
      <div className="main-content stylish-background">
        <div className="content">
          <div className="content-inner stylish-letter">
            <h1 className="billing-title">Subscription Information 💳</h1>
            <div className="billing-card stylish-card">
              <div className="checkout-section">
                <p className="cancel-letter">
                  Hey {firstName} {lastName}, <br /><br />
                  We’re sad to see you’re thinking of leaving! But before you go, here’s what you’ll miss:
                  <ul>
                    <li>⚡ Generate questions and answers from your uploaded notes</li>
                    <li>📝 Share notes with users you trust</li>
                    <li>🔍 Improve your understanding with targeted practice questions</li>
                  </ul>
                  We’re always looking to improve. Is there something we can do to make your experience better? <br /><br />
                  Your feedback is valuable to us, so don’t hesitate to reach out! <br /><br />
                  Best, <br />
                  The CompreQnA Team<br />
                  customerservice@compreqna.com
                </p>
                <button className="cancel-button stylish-button" onClick={handleCancelClick}>Cancel Subscription</button>
              </div>
            </div>
          </div>
          <p onClick={handleViewHomePage} className="home-page-link">Go to Home Page</p>
        </div>
      </div>

      {/* Modal for confirming cancellation */}
      {showModal && (
        <div className="cancel-modal-overlay space-theme-overlay">
          <div className="modal space-theme-modal">
            <h2 className="space-theme-heading">Are you sure?</h2>
            <p className="space-theme-text">You’re about to cancel your subscription. This action is permanent.</p>
            <div className="modal-actions">
              <button className="modal-button confirm space-theme-button" onClick={handleProceedCancel}>Proceed</button>
              <button className="modal-button cancel space-theme-button" onClick={handleStopCancel}>Stop Canceling</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default BillingCancel;
