import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { listAll, getDownloadURL, ref, deleteObject, getMetadata, uploadBytes, updateMetadata} from 'firebase/storage';
import { signOut, onAuthStateChanged} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, query, where,getDocs} from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import { FirebaseError } from 'firebase/app';
import OpenAI from "openai";
import Logo from '../assets/logo.png'; // Assuming your logo is here
import { FaShareAlt, FaEye,FaEdit, FaTrashAlt,FaShareSquare  } from 'react-icons/fa';
import PdfViewerModal from './PdfViewerModal'; 
import * as pdfjsLib from 'pdfjs-dist/webpack'; 
import CustomConfirmModal from './CustomConfirmModal';

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })


  interface FileData {
    name: string;
    userId: string;
    url: string;
    youtubeUrl: string;
    thumbnailUrl:string;
    refPath: string;
    createdAt: Date; 
    viewCount: number;
    documentType: string; 
    shareWithPublic: string; 
    userImageUrl: string; 
    qnaUrl:string;
    summaryUrl:string;  
    transcriptUrl:string;  
    fileTags:string;
    size:number;
    fileNumber:string;
    duration:number;
  }
  

const Summaries: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [files, setFiles] = useState<{ name: string; url: string; refPath: string }[]>([]);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');  
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [currentFileRefPath, setCurrentFileRefPath] = useState('');
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [isQnaModalOpen, setIsQnaModalOpen] = useState(false);
  const [numQuestions, setNumQuestions] = useState(10);
  const [questionFormat, setQuestionFormat] = useState('Multiple choice');
  const [difficultyLevel, setDifficultyLevel] = useState('Medium'); // New state for difficulty level
  const [animationOption, setAnimationOption] = useState('Yes'); // New state for animation
  const [progressText, setProgressText] = useState<string>(''); 
  const [showProgress, setShowProgress] = useState<boolean>(false); 
  const [qnaStatus, setQnaStatus] = useState<Record<string, boolean>>({});
  const [summaryStatus, setSummaryStatus] = useState<Record<string, boolean>>({});
  const fetchAttemptedRef = useRef<Record<string, boolean>>({});
  const firestoreFetchAttempted = useRef(false); // Ref to track Firestore fetch attempts
  const filesFetchAttempted = useRef(false);
  const [qnaFileUrl, setQnaFileUrl] = useState<string | null>(null);
  const [isAnimationRunning, setIsAnimationRunning] = useState(false); // New state to control animation visibility
  const [shareModalOpen, setShareModalOpen] = useState(false); // State for Share Modal
  const [shareEmail, setShareEmail] = useState(''); // Email input state
  const [shareMessage, setShareMessage] = useState(''); // Message input state
  const [shareFileUrl, setShareFileUrl] = useState<string | null>(null); // The file URL to be shared
  const [shareFileName, setShareFileName] = useState<string | null>(null); 
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [showAnimationOptions] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown menu
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [showQuestionConfirmModal, setShowQuestionConfirmModal] = useState<boolean>(false);
  const [showShareConfirmModal, setShowShareConfirmModal] = useState<boolean>(false);  
  const [shareWithPublic, setShareWithPublic] = useState<boolean>(true);
  const [currentFile, setCurrentFile] = useState<FileData | null>(null);
  const [transcriptUrl, setTranscriptUrl] = useState<string>('');
  const [showPublic, setShowPublic] = useState<boolean>(true); 
  const [tags, setTags] = useState<string[]>([]);

  const handleViewHomePage = () => {
    navigate('/home', { state: { selectedFile, files } });
  };
  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const fetchSelectedFileFromDatabase = useCallback(async (uid: string) => {
    if (!uid || firestoreFetchAttempted.current) return;

    firestoreFetchAttempted.current = true; // Mark Firestore fetch as attempted

    const firestore = getFirestore();
    try {
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData && userData.selectedFileUrl) {
          setSelectedFile(userData.selectedFileUrl);
        } else if (files.length > 0) {
          setSelectedFile(files[0].url);
        }
      }
    } catch (error) {
      if (error instanceof FirebaseError && error.code !== 'permission-denied') {
        console.error("Error fetching selected file from Firestore:", error);
      }
    }
  }, [files]);



// Function to fetch files from Firebase Storage
const fetchFiles = useCallback(async (userUID: string) => {
  if (filesFetchAttempted.current) return;
  setFileName('')
  try {
    const listRef = ref(storage, 'summaries/');
    const res = await listAll(listRef);

    const userFiles = res.items
      .filter((item) => item.name.includes(userUID))
      .map(async (item) => ({
        name: item.name.replace(`${userUID}_`, ''),
        url: await getDownloadURL(item),
        refPath: item.fullPath,
      }));

    const filesWithUrls = await Promise.all(userFiles);
    setFiles(filesWithUrls);

    if (filesWithUrls.length > 0) {
      await fetchSelectedFileFromDatabase(userUID);
    }
    filesFetchAttempted.current = true;
  } catch (error) {
    if (error instanceof FirebaseError && error.code !== 'permission-denied') {
      console.error("Error fetching files from storage:", error);
    }
  }
}, [fetchSelectedFileFromDatabase]);

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      const userUID = currentUser.uid;

      // Fetch files and selected file from Firestore and Storage
      await fetchFiles(userUID);
      await fetchSelectedFileFromDatabase(userUID);
    } else {
      console.warn("No user is authenticated, skipping file fetching.");
      setFiles([]);
      setSelectedFile(null);
    }
  });

  return () => unsubscribe();
}, [fetchFiles,fetchSelectedFileFromDatabase]);

  // Handler for toggling the menu
  const toggleMenu = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  // Handle clicks outside the dropdown to close it
  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.querySelector('.menu-content');
      const isClickInside = dropdownElement?.contains(event.target as Node);
      
      // If the click is outside the dropdown, close it
      if (!isClickInside) {
        setOpenMenuIndex(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const fetchFiles = async () => {
      if (filesFetchAttempted.current) return; // Exit if files have already been fetched

      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.warn("No user is authenticated, skipping file fetching.");
        return;
      }

      const userUID = currentUser.uid;
      try {
        const listRef = ref(storage, 'summaries/');
        const res = await listAll(listRef);

        const userFiles = res.items
          .filter((item) => item.name.includes(userUID))
          .map(async (item) => ({
            name: item.name.replace(`${userUID}_`, ''), 
            url: await getDownloadURL(item),
            refPath: item.fullPath, 
          }));

        const filesWithUrls = await Promise.all(userFiles);
        setFiles(filesWithUrls);

        if (filesWithUrls.length > 0) {
          await fetchSelectedFileFromDatabase(userUID);
        }
        filesFetchAttempted.current = true; // Mark files as fetched
      } catch (error) {
        if (error instanceof FirebaseError && error.code !== 'permission-denied') {
          console.error("Error fetching files from storage:", error);
        }
      }
    };

    if (auth.currentUser) {
      fetchFiles();
    } else {
      console.warn('No user is authenticated, skipping Firestore operations.');
    }
  }, [fetchSelectedFileFromDatabase]);

  const checkQnaFileExists = useCallback(async (fileUrl: string, fileName: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser || fetchAttemptedRef.current[fileUrl]) {
      return;
    }

    fetchAttemptedRef.current[fileUrl] = true;

    const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);

    try {
      await getDownloadURL(qnaFileRef);
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: true }));
    } catch (error) {
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
    }
  }, []);

  useEffect(() => {
    files.forEach((file) => {
      checkQnaFileExists(file.url, file.name);
    });
  }, [files, checkQnaFileExists]);


 // Function to generate thumbnail for a PDF file
 const generateThumbnail = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl);
    const pdfData = await response.arrayBuffer();

    // Load PDF document using the Uint8Array directly
    const pdfDoc = await pdfjsLib.getDocument(new Uint8Array(pdfData)).promise;
    const firstPage = await pdfDoc.getPage(1); // Get the first page (1-indexed)

    // Get viewport for rendering
    const viewport = firstPage.getViewport({ scale: 0.5 }); // Scale down for thumbnail

    // Create a canvas element to draw the image
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('Failed to get canvas context');
      return;
    }

    // Render the PDF page into the canvas context
    await firstPage.render({ canvasContext: ctx, viewport }).promise;

    // Create thumbnail URL from canvas
    const thumbnailUrl = canvas.toDataURL('image/png');
    setThumbnails((prev) => ({ ...prev, [fileUrl]: thumbnailUrl }));
  } catch (error) {
    console.error('Error generating thumbnail:', error);
  }
};

  // Use effect to generate thumbnails for all files
  useEffect(() => {
    files.forEach((file) => {
      if (!thumbnails[file.url]) {
        generateThumbnail(file.url);
      }
    });
  }, [files, thumbnails]);




  const saveSelectedFileToDatabase = async (uid: string, fileUrl: string) => {
    if (!uid) return;

    const firestore = getFirestore();
    try {
      const userDocRef = doc(firestore, 'users', uid);
      await setDoc(userDocRef, { selectedFileUrl: fileUrl }, { merge: true });
    } catch (error) {
      if (error instanceof FirebaseError && error.code !== 'permission-denied') {
        console.error("Error saving selected file to Firestore:", error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  const handleViewFile = (url: string) => {
    setPdfUrl(url); // Set the PDF URL
    setIsPdfModalOpen(true); // Open the modal
};


  // Handler for closing the Share Modal
  const handleCancelShare = () => {
    setShareModalOpen(false);
  };

  const handleShareFile = (fileUrl: string, fileName: string) => {
    setShareFileUrl(fileUrl); // Set the file URL to be shared
    setShareFileName(fileName); // Set the file name to be shared
    setShareModalOpen(true); // Open the modal
  };


  // Function to get the user by email from Firestore
  const getUserByEmail = async (email: string) => {
    const firestore = getFirestore();
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('email', '==', email));
    console.log('q:',q)
    
    const querySnapshot = await getDocs(q);

    console.log('code gets here')
    console.log('querySnapshot:',querySnapshot)


    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return { uid: userDoc.id, ...userDoc.data() }; // Return UID and user data

    }
    return null; // No user found with the provided email
  };
  
  const handleSendShare = async () => {
    const firestore = getFirestore();
  
    try {
      // Fetch the receiver's UID based on the email provided in the modal
      const receiverUser = await getUserByEmail(shareEmail);

      if (!receiverUser) {
        alert('User with this email does not exist.');
        return;
      }

      const receiverUID = receiverUser.uid; // Get the receiver's UID
  
      // Add a notification to the receiver's Firestore collection
      await addDoc(collection(firestore, 'notifications'), {
        receiverId: receiverUID,
        senderId: auth.currentUser?.uid,
        fileUrl: shareFileUrl,
        fileName: shareFileName, // Send the file name with the notification
        fileType: 'summary',
        message: `${auth.currentUser?.email} is sharing the file "${shareFileName}" with you.`,
        status: 'pending', // Initially, the status is pending
        createdAt: new Date(),
        isRead: false, // Ensure that new notifications are marked as unread
      });
  
      alert(`Sharing file "${shareFileName}" message sent to ${shareEmail}`);
      setShareModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sharing file:', error);
      alert('Failed to share the file. Please try again.');
    }
  };
  
  const handleSelectFile = async (url: string) => {
    setSelectedFile(url);

    const currentUser = auth.currentUser;
    if (currentUser) {
      // Reset savedLibraryUrl in Firestore to empty string
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await setDoc(userDocRef, { savedLibraryUrl: '' }, { merge: true });

      // Save the selected file URL to the database
      saveSelectedFileToDatabase(currentUser.uid, url);
    }
  };

  const handleDeleteFile = async (fileRefPath: string) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this file?");
    if (confirmDelete) {
        const fileRef = ref(storage, fileRefPath);

        try {
            // Attempt to delete the main file
            await deleteObject(fileRef);
        } catch (error) {
            if (error instanceof FirebaseError && error.code === 'storage/object-not-found') {
                console.log('Main file not found in storage, removing from file list.');
            } else {
                console.error('Error deleting file:', error);
                alert('Failed to delete the file. Please try again.');
                return;
            }
        }

        // Extract the file name from the fileRefPath
        const fileName = fileRefPath.split('/').pop(); // Get the last part of the path as the file name

        if (fileName) {
            const currentUser = auth.currentUser;
            if (currentUser) {
                // Construct the path to the corresponding JSON file in the Q&A folder
                const qnaFileRefPath = `Q&A/${fileName}_qna.json`;
                const qnaFileRef = ref(storage, qnaFileRefPath);

                // Attempt to delete the corresponding JSON file
                try {
                    await deleteObject(qnaFileRef);
                    console.log('Q&A JSON file deleted successfully.');
                } catch (qnaError) {
                    if (qnaError instanceof FirebaseError && qnaError.code === 'storage/object-not-found') {
                        console.log('No corresponding Q&A JSON file found.');
                    } else {
                        console.error('Error deleting Q&A JSON file:', qnaError);
                    }
                }
            }
        }

        // Update the state to remove the file from the list and close the PDF viewer if open
        setFiles(files.filter(file => file.refPath !== fileRefPath));
        setPdfUrl(null);

        alert('File deleted successfully.');
    }
};


const handleFileShareWithPublic = async (fileUrl: string) => {
  setShareFileUrl(fileUrl); 
  setShowShareConfirmModal(true); 
};

const confirmFileShareWithPublic = async () => {
  if (shareFileUrl) { 
    setShareWithPublic(true);
    await handleShareChange(shareFileUrl); 
  } else {
    console.error('No file selected for change.'); 
  }
  setShowShareConfirmModal(false); // Close the modal
};

const cancelFileShareWithPublic = async () => {
  if (shareFileUrl) { 
    setShareWithPublic(false);
    await handleShareChange(shareFileUrl); 
  } else {
    console.error('No file selected for change.'); 
  }
  setShowShareConfirmModal(false); // Close the modal
};


const cancelShareWithPublicWindow = async () => {
  setShowShareConfirmModal(false); 
};


const handleShareChange = async (fileRefPath: string) => {
    const fileRef = ref(storage, fileRefPath); // Reference to the file

    try {
      // Fetch the current metadata of the file
      const currentMetadata = await getMetadata(fileRef);

      // Prepare the new metadata object by merging with the current metadata
      const updatedMetadata = {
        customMetadata: {
          ...currentMetadata.customMetadata, // Preserve existing custom metadata fields
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No' // Update only ShareWithPublic field
        }
      };

      // Update the file's metadata in Firebase Storage
      await updateMetadata(fileRef, updatedMetadata);
      setShareFileUrl('');

      // Success message
      alert(`You have updated the file sharing with the public succesfully.`);
    } catch (error) {
      console.error('Error updating metadata:', error);
      alert('Failed to update the metadata. Please try again.');
    }
  };

const handleRenameFile = async (newName: string) => {
    if (currentFileRefPath && newName) {
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userUID = currentUser.uid;
            const newFileRefPath = `summaries/${userUID}_${newName}`;
            const fileRef = ref(storage, currentFileRefPath);
            const newFileRef = ref(storage, newFileRefPath);

            try {
                // Fetch the file from storage
                const fileUrl = await getDownloadURL(fileRef);
                const response = await fetch(fileUrl);
                const blob = await response.blob();

                // Get the old metadata
                const oldMetadata = await getMetadata(fileRef); // Use getMetadata to fetch file metadata
                const customMetadata = oldMetadata.customMetadata || {};

                // Upload the file with the new name and the same custom metadata
                const metadata = {
                    customMetadata: {
                        UserID: customMetadata.UserID || userUID,
                        DocumentType: customMetadata.DocumentType || 'Summary',
                        ShareWithPublic: customMetadata.ShareWithPublic || 'Yes'
                    }
                };
                await uploadBytes(newFileRef, blob, metadata);

                // Fetch the new file URL after renaming
                const newFileUrl = await getDownloadURL(newFileRef);

                // Delete the original file
                await deleteObject(fileRef);

                // Rename the corresponding Q&A JSON file if it exists
                const oldQnaFileRefPath = `Q&A/${currentFileRefPath.split('/').pop()}_qna.json`;
                const newQnaFileRefPath = `Q&A/${userUID}_${newName}_qna.json`;

                const oldQnaFileRef = ref(storage, oldQnaFileRefPath);
                const newQnaFileRef = ref(storage, newQnaFileRefPath);

                try {
                    // Check if the old Q&A JSON file exists
                    const qnaFileUrl = await getDownloadURL(oldQnaFileRef);
                    const qnaResponse = await fetch(qnaFileUrl);
                    const qnaBlob = await qnaResponse.blob();

                    // Upload the Q&A JSON file with the new name and same custom metadata
                    await uploadBytes(newQnaFileRef, qnaBlob, metadata);

                    // Delete the old Q&A JSON file
                    await deleteObject(oldQnaFileRef);

                    console.log('Q&A JSON file renamed successfully.');
                } catch (qnaError) {
                    if (qnaError instanceof FirebaseError && qnaError.code === 'storage/object-not-found') {
                        console.log('No corresponding Q&A JSON file found, skipping rename.');
                    } else {
                        console.error('Error renaming Q&A JSON file:', qnaError);
                    }
                }

                // Update the state to reflect the renamed file with the new URL
                setFiles(
                    files.map(file =>
                        file.refPath === currentFileRefPath ? { ...file, name: newName, refPath: newFileRefPath, url: newFileUrl } : file
                    )
                );
                alert('File renamed successfully.');
            } catch (error) {
                console.error('Error renaming file:', error);
                alert('Failed to rename the file. Please try again.');
            } finally {
                setIsRenameModalOpen(false);
            }
        }
    }
};


  const handleQnaButtonClick = (fileUrl: string) => {
    setQnaFileUrl(fileUrl);
    setProgressText('');
    setShowProgress(false);
    setIsQnaModalOpen(true);
  };

  const handleQnaModalClose = () => {
    setIsQnaModalOpen(false);
  };

  const updateProgress = (message: string) => {
    setProgressText(prev => `${message}\n${prev}`);
  };

  const handleQuestionGeneration = async () => {
    setShowQuestionConfirmModal(true); 
  };

  const confirmQuestionShareWithPublic = async () => {
    setShareWithPublic(true);
    setShowQuestionConfirmModal(false); // Close the modal
    await handleQnaOkClick() ;
  };
  
  const cancelQuestionShareWithPublic = async () => {
    setShareWithPublic(false);
    setShowQuestionConfirmModal(false); // Close the modal
    await handleQnaOkClick() ;
  };


  const handleQnaOkClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        alert("You need to be logged in to generate Q&A.");
        return;
    }

    setShowProgress(true);
    updateProgress("Starting Q&A generation...");

    if (animationOption === 'Yes') {
        setIsQnaModalOpen(false); // Close the Q&A modal
        setIsAnimationRunning(true); // Start the animation

        // Start with the initial scale-up and rotation
        const logo = document.querySelector('.animation-logo') as HTMLElement | null;
        if (logo) {
            logo.style.animation = 'rotateAndScaleUpLogo 5s ease-in-out forwards';

            // After the scale-up is complete, start continuous rotation without stopping
            setTimeout(() => {
                logo.style.animation = 'continuousRotate 2s linear infinite';
            }, 5000); // Match this timeout with the duration of the rotateAndScaleUpLogo animation
        }
    }

    try {
        const fileUrl = qnaFileUrl;
        if (!fileUrl) {
            alert("No file selected. Please select a file to generate Q&A.");
            return;
        }

        updateProgress(`Selected file URL: ${fileUrl}`);

        const fileResponse = await fetch(fileUrl);
        const fileBlob = await fileResponse.blob();
        const fileName = files.find(file => file.url === fileUrl)?.name || "file.pdf";

        updateProgress("Fetched file blob.");
        updateProgress(`Determined file name: ${fileName}`);

        const file = new File([fileBlob], fileName, { type: fileBlob.type });
        updateProgress("Converted Blob to File.");

        const assistantResponse = await openai.beta.assistants.create({
            name: "Q&A Assistant",
            instructions: "Generate Q&A based on the uploaded file.",
            model: "gpt-4o-mini",
            tools: [{ type: "file_search" }],
            metadata: { user: currentUser.uid }
        });

        const assistantID = assistantResponse.id;
        updateProgress("Created AI Assistant.");

        const threadResponse = await openai.beta.threads.create();
        const threadID = threadResponse.id;
        updateProgress("Created thread.");

        const fileUploadResponse = await openai.files.create({
            file: file,
            purpose: "assistants",
        });

        const fileID = fileUploadResponse.id;
        updateProgress("Uploaded file.");

        const vectorStoreResponse = await openai.beta.vectorStores.create({
            name: "Q&A Vector Store",
        });

        const vectorStoreID = vectorStoreResponse.id;
        updateProgress("Created Vector Store.");

        const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
            file_id: fileID,
        });
        console.log(myVectorStoreFile);
        updateProgress("File added to vector store.");

        await openai.beta.assistants.update(assistantID, {
            tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
        });
        updateProgress("Updated assistant with vector store ID.");

        let prompt = '';
        let adjustedDifficultyLevel = difficultyLevel;
        if (difficultyLevel === 'Easy') {
          adjustedDifficultyLevel = 'Medium';
        } else if (difficultyLevel === 'Medium') {
          adjustedDifficultyLevel = 'Hard';
        } else if (difficultyLevel === 'Hard') {
          adjustedDifficultyLevel = 'very very hard and impossible to answer';
        }
        
        // Construct the prompt based on the question format and the adjusted difficulty level
        if (questionFormat === 'Direct question') {
          prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
          Show the questions and answers in JSON format in an array.`;
        }
        
        if (questionFormat === 'Multiple choice') {
          prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
          Provide the question with multiple choices for user to select, choice A, choice B, choice C, and choice D. There is only 
          one correct answer. Provide a letter among A, B, C, and D. Show the multiple choice questions and answers in JSON format in an array.
          For the JSON format, use "question" as the key for the question, "options" as the key for choices of A, B, C, and D, and "answer" as the key for the correct answer.`;
        }
        updateProgress("Prompt for Q&A generation sent.");
        console.log('prompt', prompt);

        const messageResponse = await openai.beta.threads.messages.create(threadID, {
            content: prompt,
            role: 'user',
        });
        updateProgress("Added a message to the thread.");
        console.log(messageResponse);

        let runAttempts = 0;
        let resultData: string | null = null;
        let isComplete = false;
        const maxAttempts = 10;

        while (runAttempts < maxAttempts && !isComplete) {
            runAttempts += 1;

            updateProgress(`Attempt ${runAttempts} to generate Q&A`);

            const runResponse = await openai.beta.threads.runs.create(threadID, {
                assistant_id: assistantID,
            });

            updateProgress(`Created run...`);

            let runStatus: OpenAI.Beta.Threads.Runs.Run | null = null;
            while (!isComplete) {
                runStatus = await openai.beta.threads.runs.retrieve(threadID, runResponse.id);
                updateProgress(`Run status: ${runStatus?.status}`);

                if (runStatus && runStatus.status === "completed") {
                    const messages = await openai.beta.threads.messages.list(threadID);
                    if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
                        resultData = messages.data[0].content[0].text.value;
                        const jsonMatch = resultData.match(/```json([\s\S]*?)```/);

                        if (jsonMatch && jsonMatch[1]) {
                            try {
                                const jsonData = JSON.parse(jsonMatch[1].trim());

                                const cleanedResultData = JSON.stringify(jsonData, null, 2);

                                const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);
                                const qnaBlob = new Blob([cleanedResultData], { type: "application/json" });

                                // Set custom metadata based on the user's selection
                                const customMetadata = {
                                  customMetadata: {
                                    ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                                    UserID:currentUser.uid,
                                    DocumentType:'Question',
                                  },
                                };

                                await uploadBytes(qnaFileRef, qnaBlob,customMetadata);

                                updateProgress("Saved Q&A to Cloud.");
                                alert("Q&A generated and saved successfully!");
                                setShareWithPublic(false);

                                setQnaStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [fileUrl]: true,  
                                }));
                                isComplete = true;
                                break;
                            } catch (error) {
                                console.error("Failed to parse JSON data:", error);
                            }
                        }
                    }
                    if (!isComplete) {
                        updateProgress("Run completed but no valid JSON data found.");
                        break;
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            }
            if (isComplete) break;
        }

        if (!isComplete) {
            updateProgress("No valid JSON data found in the resultData.");
            alert("Failed to generate Q&A data. Try again!");
            await openai.files.del(fileID);
            updateProgress("Deleted file.");

            await openai.beta.threads.del(threadID);
            updateProgress("Deleted thread.");

            await openai.beta.vectorStores.del(vectorStoreID);
            updateProgress("Deleted vector store.");

            await openai.beta.assistants.del(assistantID);
            updateProgress("Deleted assistant.");
        } else {
            await openai.files.del(fileID);
            updateProgress("Deleted file.");

            await openai.beta.threads.del(threadID);
            updateProgress("Deleted thread.");

            await openai.beta.vectorStores.del(vectorStoreID);
            updateProgress("Deleted vector store.");

            await openai.beta.assistants.del(assistantID);
            updateProgress("Deleted assistant.");
        }

    } catch (error) {
        console.error("Error during Q&A generation:", error);
        alert("Failed to generate Q&A. Please try again.");
    } finally {
        if (animationOption === 'Yes') {
            handlePopUpMessage(); // Start the post-animation after completion
        } else {
            setIsQnaModalOpen(false);
            setIsAnimationRunning(false); // Stop the animation
            navigate('/home'); // Navigate back to Notes component
        }
    }
};

const handlePopUpMessage = () => {
    // After the user clicks OK, start scaling down the logo while continuing to rotate
    const logo = document.querySelector('.animation-logo') as HTMLElement | null;
    if (logo) {
        // Switch to the scale down animation with rotation
        logo.style.animation = 'rotateAndScaleDownLogo 3s ease-in-out forwards';
    }

    // Fade out the overlay after the logo scales down
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.classList.add('fade-out');
        }
    }, 3000); // Adjust the timing to match the logo scale-down duration

    // Remove the overlay from the DOM after the fade-out is complete
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.style.display = 'none';
        }
        setIsAnimationRunning(false);
        navigate('/home'); // Navigate back to Notes component
    }, 4000); // Ensure this matches the total duration of scale-down + fade-out
};

  const handleHomeClick = async () => {
    navigate('/home');
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />

      <div className="main-content">
        <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />

        <div className="content-inner">

        <div className="file-list-container">
            {files.length === 0 ? (
                // Show this message when files is empty
                <p className="no-files-message" style={{ color: 'white' }}>No file was found. Please create your summary from a note.</p>
            ) : (
                // Show the file list when files are not empty
                <ul className="file-list">
                {files.map((file, index) => {
                    const isLongWithoutSpaces = file.name.length > 20 && !file.name.includes(' ');

                    // Function to split the file name into two segments
                    const splitFileName = (name: string): string => {
                    const midpoint = Math.floor(name.length / 2); // Find the midpoint of the name
                    return `${name.slice(0, midpoint)}\u200B${name.slice(midpoint)}`; // Insert a zero-width space to create a word break
                    };

                    return (
                    <li key={index} className="file-list-item">
                      <span className="file-thumbnail" onClick={() => handleViewFile(file.url)} style={{ cursor: 'pointer' }}>
                        {thumbnails[file.url] ? (
                          <img src={thumbnails[file.url]} alt={`Thumbnail for ${file.name}`} />
                        ) : (
                          <p>Loading...</p>
                        )}
                      </span>
                        <span className="file-name">
                        {isLongWithoutSpaces ? splitFileName(file.name) : file.name}
                        </span>

                        <div className="file-actions">
                        <div className="button-grid-container-summary">
                            <button
                            title="Select"
                            onClick={() => handleSelectFile(file.url)}
                            className={`select-button ${selectedFile === file.url ? 'selected' : ''}`}
                            >
                            {selectedFile === file.url ? '✔' : '⬜'}
                            </button>

                            {/* Q&A Button */}
                            <button
                            title="Generate Questions"
                            onClick={() => handleQnaButtonClick(file.url)}
                            className="qna-button"
                            style={{ color: qnaStatus[file.url] ? 'green' : 'red', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}
                            >
                            ?
                            </button>

                            {/* View Button */}
                            <button title="View" onClick={() => handleViewFile(file.url)} className="view-button">
                            <FaEye size={18} />
                            </button>

                            {/* Share Button */}
                            <button title="Share" onClick={() => handleShareFile(file.url, file.name)} className="share-button">
                            <FaShareAlt size={18} />
                            </button>
                        </div>

                        {/* Menu Button with Dropdown */}
                        <div className="menu-container" ref={menuRef}>
                            <button title="Tools" className="menu-button" onClick={() => toggleMenu(index)}>...</button>
                            {openMenuIndex === index && (
                            <div className="menu-content">
                                {/* Share button with icon */}
                                <button 
                                className="menu-option" 
                                onClick={() => {
                                    handleFileShareWithPublic(file.refPath);
                                    setOpenMenuIndex(null);
                                }}
                                >
                                <FaShareSquare size={18} style={{ marginRight: '8px' }} />
                                Share
                                </button>

                                {/* Rename button with icon */}
                                <button
                                className="menu-option"
                                onClick={() => {
                                    setCurrentFileRefPath(file.refPath);
                                    setNewFileName(file.name);
                                    setIsRenameModalOpen(true);
                                    setOpenMenuIndex(null);
                                }}
                                >
                                <FaEdit size={18} style={{ marginRight: '8px' }} />
                                Rename
                                </button>

                                {/* Delete button with icon */}
                                <button
                                className="menu-option"
                                onClick={() => {
                                    handleDeleteFile(file.refPath);
                                    setOpenMenuIndex(null);
                                }}
                                >
                                <FaTrashAlt size={18} style={{ marginRight: '8px' }} />
                                Delete
                                </button>
                            </div>
                            )}
                        </div>
                        </div>
                    </li>
                    );
                })}
                </ul>
            )}
            </div>

        <p 
        onClick={handleViewHomePage} 
        style={{
            marginTop: '20px',
            color: 'white',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontSize: '14px'
          }}
        >
        Go to Home Page
        </p>

        <PdfViewerModal
            isOpen={isPdfModalOpen}
            pdfUrl={pdfUrl}
            fileName={fileName}
            qnaStatus={qnaStatus}
            summaryStatus={summaryStatus}  
            setSummaryStatus={setSummaryStatus}              
            onClose={() => setIsPdfModalOpen(false)}  // Close modal function
            onGenerateQuestions={handleQnaButtonClick}
            onGenerateSummaries={handleQnaButtonClick}  
            currentFile={currentFile}  
            setCurrentFile={setCurrentFile}  
            transcriptUrl={transcriptUrl} 
            setTranscriptUrl={setTranscriptUrl}  
            showPublic={showPublic}             
        />

        </div>
      </div>

      {shareModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content notes-share-modal">
            <h3 className="modal-title">Share Document</h3>
            <div className="modal-row notes-share-row">
              <label className="notes-share-label">To:</label>
              <input
                type="email"
                value={shareEmail}
                onChange={(e) => setShareEmail(e.target.value)}
                placeholder="Enter recipient's email"
                className="notes-share-input"
              />
            </div>
            <div className="modal-row notes-share-row">
              <label className="notes-share-label">Message:</label>
              <textarea
                value={shareMessage}
                onChange={(e) => setShareMessage(e.target.value)}
                placeholder="Enter your message"
                className="notes-share-textarea"
              />
            </div>
            <div className="modal-actions notes-share-actions">
              <button className="cancel-button" onClick={handleCancelShare}>
                Cancel
              </button>
              <button className="notes-share-send-button" onClick={handleSendShare}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      {isRenameModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="modal-title">Rename File</h3>
            <div className="input-row" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label style={{ marginLeft: '30px', marginRight: '10px', width: '80px', color: 'white' }}>File Name:</label>
              <input 
                type="text" 
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)} 
                placeholder="Enter new file name" 
                style={{ width: '66%', padding: '5px' }}  // Adjusted to 2/3 width
              />
            </div>
            <div className="modal-actions">
              <button className="cancel-button" onClick={() => setIsRenameModalOpen(false)}>
                Cancel
              </button>
              <button className="save-button" onClick={() => handleRenameFile(newFileName)}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isQnaModalOpen && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
                  <h3 className="modal-title">Q&A Generation</h3>
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Number of Questions:
                          <input
                              type="number"
                              value={numQuestions}
                              onChange={(e) => setNumQuestions(parseInt(e.target.value, 10))}
                              min="1"
                              max="100"
                              className="qwindow-input"
                          />
                      </label>
                  </div>
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Question Format:
                          <select
                              value={questionFormat}
                              onChange={(e) => setQuestionFormat(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Multiple choice">Multiple choice</option>
                              <option value="Direct question">Direct question</option>
                          </select>
                      </label>
                  </div>

                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Difficulty Level:
                          <select
                              value={difficultyLevel}
                              onChange={(e) => setDifficultyLevel(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Easy">Easy</option>
                              <option value="Medium">Medium</option>
                              <option value="Hard">Hard</option>
                          </select>
                      </label>
                  </div>

                  {showAnimationOptions && (
                    <div className="modal-row qwindow-row">
                    <label className="qwindow-label">
                        Animation:
                        <select
                        value={animationOption}
                        onChange={(e) => setAnimationOption(e.target.value)}
                        className="qwindow-select"
                        >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                    </label>
                    </div>
                 )}

                  {showProgress && (
                      <div className="modal-row qwindow-row">
                          <textarea
                              className="qwindow-textarea"
                              value={progressText}
                              readOnly
                          />
                      </div>
                  )}

                  <div className="modal-actions qwindow-actions">
                      <button className="cancel-button qwindow-button" onClick={handleQnaModalClose}>
                          Cancel
                      </button>
                      <button className="ok-button qwindow-button" onClick={handleQuestionGeneration}>
                          OK
                      </button>
                  </div>


              </div>
          </div>
      )}

      {showQuestionConfirmModal && (
                <CustomConfirmModal 
                  message="Do you want to share generated questions with the public?"
                  onPublic={confirmQuestionShareWithPublic}
                  onPrivate={cancelQuestionShareWithPublic}
                  onCancel={cancelShareWithPublicWindow}
                  tags={tags}
                  setTags={setTags}
                />
      )}

     {showShareConfirmModal && (
                <CustomConfirmModal 
                  message="Do you want to share the file with the public?"
                  onPublic={confirmFileShareWithPublic}
                  onPrivate={cancelFileShareWithPublic}
                  onCancel={cancelShareWithPublicWindow}
                  tags={tags}
                  setTags={setTags}
                />
      )}

      {/* Animation Overlay */}
      {isAnimationRunning && (
        <div className="animation-overlay">
          <img src={Logo} alt="Loading" className="animation-logo" />
        </div>
      )}
  
    </div>
  );
};

export default Summaries;
