import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { auth } from '../firebaseConfig';
import './Base.css';

interface EditProfileModalProps {
  firstName: string;
  lastName: string;
  userImage: string;
  userBio: string;
  onClose: () => void;
  onSave: (updatedFirstName: string, updatedLastName: string, updatedBio: string, updatedImage: File | null) => void;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  firstName,
  lastName,
  userImage,
  userBio,
  onClose,
  onSave,
}) => {
  const [updatedFirstName, setFirstName] = useState(firstName);
  const [updatedLastName, setLastName] = useState(lastName);
  const [bio, setBio] = useState(userBio);
  const [image, setImage] = useState<File | null>(null);
  const storage = getStorage();
  const db = getFirestore();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    let imageUrl = userImage;

    if (image) {
      const imageRef = ref(storage, `profileImages/${auth.currentUser?.uid}`);
      await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(imageRef);
    }

    const userDocRef = doc(db, 'users', auth.currentUser?.uid as string);
    await setDoc(userDocRef, {
      firstName: updatedFirstName,
      lastName: updatedLastName,
      bio: bio,
      imageUrl: imageUrl,
    }, { merge: true });

    onSave(updatedFirstName, updatedLastName, bio, image);
    onClose();
  };

  return (
    <div className="pmodal-overlay">
      <div className="modal-content pwindow-modal">
        <h2 className="modal-title">Edit Profile</h2>
        <div className="profile-photo">
          <img src={image ? URL.createObjectURL(image) : userImage} alt="Profile" className="profile-photo-preview" />
          <input type="file" onChange={handleImageChange} className="file-input" />
        </div>
        <div className="profile-field">
          <label className="field-label">First Name</label>
          <input type="text" value={updatedFirstName} onChange={(e) => setFirstName(e.target.value)} className="pwindow-input" />
        </div>
        <div className="profile-field">
          <label className="field-label">Last Name</label>
          <input type="text" value={updatedLastName} onChange={(e) => setLastName(e.target.value)} className="pwindow-input" />
        </div>
        <div className="profile-field">
          <label className="field-label">Bio</label>
          <textarea value={bio} onChange={(e) => setBio(e.target.value)} maxLength={80} className="pwindow-textarea"></textarea>
          <p className="character-count">{bio.length}/80</p>
        </div>
        <div className="pwindow-actions">
          <button onClick={onClose} className="pwindow-button cancel-button">Cancel</button>
          <button onClick={handleSave} className="pwindow-button save-button">Save</button>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
