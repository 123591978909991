import React, { useEffect, useRef } from "react";
import { Engine, Scene, FreeCamera, Vector3, HemisphericLight, MeshBuilder, AbstractMesh } from "@babylonjs/core"; 
import "../Base.css";

interface SceneComponentProps {
    id: string; // required
    [key: string]: any; // allow additional props if needed
}

const EngineerMain: React.FC<SceneComponentProps> = ({ id, ...rest }) => {
    const reactCanvas = useRef<HTMLCanvasElement | null>(null);
    const boxRef = useRef<AbstractMesh | undefined>(undefined); // Use useRef for the box

    useEffect(() => {
        const { current: canvas } = reactCanvas;

        if (!canvas) return;

        const engine = new Engine(canvas, true); // Antialiasing enabled by default
        const scene = new Scene(engine);

        // Create and position a free camera
        const camera = new FreeCamera("camera1", new Vector3(0, 5, -10), scene);
        camera.setTarget(Vector3.Zero());
        camera.attachControl(canvas, true);

        // Create a light
        const light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
        light.intensity = 0.7;

        // Create a box and store it in the useRef
        boxRef.current = MeshBuilder.CreateBox("box", { size: 2 }, scene);
        if (boxRef.current) {
            boxRef.current.position.y = 1; // Move the box upward 1/2 its height
        }

        // Create a ground
        MeshBuilder.CreateGround("ground", { width: 6, height: 6 }, scene);

        engine.runRenderLoop(() => {
            if (boxRef.current) {
                const deltaTimeInMillis = scene.getEngine().getDeltaTime();
                const rpm = 10;
                boxRef.current.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
            }
            scene.render();
        });

        const resize = () => {
            scene.getEngine().resize();
        };

        window.addEventListener("resize", resize);

        return () => {
            scene.getEngine().dispose();
            window.removeEventListener("resize", resize);
        };
    }, []);

    return <canvas ref={reactCanvas} id={id} {...rest} />;
};

export default EngineerMain;
