import React, { useEffect, useRef, useState } from 'react';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import {FaBackward,FaPlay,FaForward,FaPause, FaStop} from 'react-icons/fa';

interface CustomAudioMotionAnalyzerProps {
  fetchAudioBlob: () => Promise<Blob | null>;
  onAudioContextReady?: (analyzer: AudioMotionAnalyzer) => void;
  metadataDuration: number | null;
}

declare global {
    interface Window {
      webkitAudioContext?: typeof AudioContext;
    }
  }

const CustomAudioMotionAnalyzer: React.FC<CustomAudioMotionAnalyzerProps> = ({
  fetchAudioBlob,
  onAudioContextReady,
  metadataDuration,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const audioMotionAnalyzerRef = useRef<AudioMotionAnalyzer | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<AudioBuffer | null>(null);
  const audioSourceRef = useRef<AudioBufferSourceNode | null>(null);
  const animationRef = useRef<number | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const sourceStartedRef = useRef(false);

  const startOffsetRef = useRef(0); // Tracks the playback offset
  let isStopping = false;

  useEffect(() => {
    if (!containerRef.current) {
      console.error('AudioMotionAnalyzer container not found.');
      return;
    }

    if (!audioMotionAnalyzerRef.current) {
      console.log('Initializing AudioMotionAnalyzer...');
      audioMotionAnalyzerRef.current = new AudioMotionAnalyzer(containerRef.current, {
        height: 200,
        width: 600,
        gradient: 'rainbow',
      });

      audioContextRef.current = audioMotionAnalyzerRef.current.audioCtx;

      if (onAudioContextReady) {
        onAudioContextReady(audioMotionAnalyzerRef.current);
      }
    }

    return () => {
      if (audioMotionAnalyzerRef.current) {
        console.log('Destroying AudioMotionAnalyzer...');
        audioMotionAnalyzerRef.current.disconnectInput();
        audioMotionAnalyzerRef.current.destroy();
        audioMotionAnalyzerRef.current = null;
      }

      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close().catch((error) => {
          console.error('Error closing AudioContext:', error);
        });
      }
    };
  }, [onAudioContextReady]);

  const setupAudioSource = () => {
    const audioContext = audioContextRef.current;
    const audioBuffer = audioBufferRef.current;
    console.log('A new source is created ###################');

    if (!audioContext || !audioBuffer) {
      console.error('AudioContext or AudioBuffer is not available.');
      return null;
    }

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.playbackRate.value = playbackRate;

    const analyserNode = audioContext.createAnalyser();
    source.connect(analyserNode);
    analyserNode.connect(audioContext.destination);

    audioMotionAnalyzerRef.current?.connectInput(analyserNode);

    return source;
  };

  const startProgressTracking = () => {
    const updateProgress = () => {
      const audioContext = audioContextRef.current;
  
      if (audioContext?.state === 'running' && !isSeeking) {
        // Calculate elapsed time from `startOffsetRef`
        const elapsed =
          (audioContext.currentTime - startOffsetRef.current) * playbackRate;
        const maxDuration =
          metadataDuration || audioBufferRef.current?.duration || 0;
        const adjustedCurrentTime = Math.min(elapsed, maxDuration);
  
        setCurrentTime(adjustedCurrentTime);
  
        // Log for debugging
       // console.log('Current Time:', adjustedCurrentTime);
       // console.log('maxDuration:', maxDuration);
       // console.log('Dynamic adjustedCurrentTime:', adjustedCurrentTime);
  
        // Continue tracking progress
        if (adjustedCurrentTime < maxDuration) {
          animationRef.current = requestAnimationFrame(updateProgress);
        } else {
          console.log('Audio playback ended.');
          setIsPlaying(false);
          setCurrentTime(maxDuration);
          cancelAnimationFrame(animationRef.current || 0);
        }
      }
    };
  
    // Cancel previous tracking
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  
    animationRef.current = requestAnimationFrame(updateProgress);
  };

  const handleSkip = (seconds: number) => {
    const audioContext = audioContextRef.current;
    const audioBuffer = audioBufferRef.current;
  
    if (!audioBuffer || !audioContext) {
      console.error('AudioBuffer or AudioContext is not initialized.');
      return;
    }
  
    // Calculate the new playback position within bounds
    const bufferDuration = metadataDuration || audioBuffer.duration;
    const newTime = Math.max(0, Math.min(bufferDuration, currentTime + seconds));
    console.log('Skipping to new time:', newTime);
  
    // Update `currentTime` state for UI synchronization
    setCurrentTime(newTime);
  
    // Update `startOffsetRef` for accurate playback position
    startOffsetRef.current = audioContext.currentTime - newTime / playbackRate;
  
    // Cancel any ongoing progress tracking
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
  
    // Pause and reset the source if currently playing
    if (isPlaying) {
      pauseAudio();
    }
  };

  const playAudio = () => {
    const audioContext = audioContextRef.current;
    const audioBuffer = audioBufferRef.current;
  
    if (!audioContext || !audioBuffer) {
      console.error('AudioContext or AudioBuffer is not initialized.');
      return;
    }
  
    // If the context is suspended, resume it
    if (audioContext.state === 'suspended') {
      audioContext.resume().then(() => {
        setIsPlaying(true);
        startProgressTracking();
      });
      return;
    }
  
    // Clean up any existing source
    if (audioSourceRef.current) {
      console.log('Stopping and cleaning up previous source before starting playback.');
      try {
        audioSourceRef.current.stop();
        audioSourceRef.current.disconnect();
      } catch (error) {
        console.error('Error stopping or disconnecting the source:', error);
      }
      audioSourceRef.current = null;
    }
  
    // Create a new source
    const source = setupAudioSource();
    if (source) {
      audioSourceRef.current = source;
  
      // Start playback at the `currentTime` position
      const startAt = Math.min(currentTime, audioBuffer.duration);
      console.log('Starting playback from:', startAt);
  
      try {
        source.start(0, startAt); // Start at the correct position
        startOffsetRef.current = audioContext.currentTime - startAt / playbackRate;
        sourceStartedRef.current = true;
        setIsPlaying(true);
  
        // Handle when the audio playback ends
        source.onended = () => {
          console.log('Playback ended. Resetting currentTime to 0.');
          setIsPlaying(false);
          setCurrentTime(0); // Reset playback position
          sourceStartedRef.current = false;
          cancelAnimationFrame(animationRef.current || 0);
        };
  
        // Start progress tracking
        startProgressTracking();
      } catch (error) {
        console.error('Error starting playback:', error);
      }
    } else {
      console.error('Failed to create a new audio source.');
    }
  };
  


  const pauseAudio = () => {
    if (audioContextRef.current?.state === 'running') {
      audioContextRef.current.suspend().then(() => {
        setIsPlaying(false);
        cancelAnimationFrame(animationRef.current || 0);
      });
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const handleSpeedToggle = () => {
    const nextSpeed = playbackRate === 1 ? 1.5 : playbackRate === 1.5 ? 2 : 1;
    setPlaybackRate(nextSpeed);

    if (isPlaying) {
      playAudio();
    }
  };

  const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSeeking(true);
    setCurrentTime(parseFloat(e.target.value)); // Update the current time dynamically
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
  
    const audioContext = audioContextRef.current;
    const audioBuffer = audioBufferRef.current;
  
    if (!audioContext || !audioBuffer) {
      console.error('AudioContext or AudioBuffer is not initialized.');
      return;
    }
  
    // Stop and clean up the current audio source
    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
      audioSourceRef.current.disconnect();
      audioSourceRef.current = null;
    }
  
    // Update the `startOffsetRef` to reflect the new playback position
    startOffsetRef.current = audioContext.currentTime - currentTime / playbackRate;
  
    // Restart playback if currently playing
    if (isPlaying) {
      playAudio();
    }
  };


  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const audioBlob = await fetchAudioBlob();
        if (!audioBlob) {
          console.error('Failed to fetch audio data.');
          return;
        }

        const audioContext = audioContextRef.current;
        if (!audioContext || audioContext.state === 'closed') {
          console.error('AudioContext is not available or closed.');
          return;
        }

        const audioData = await audioBlob.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(audioData);
        audioBufferRef.current = audioBuffer;

        setTotalDuration(metadataDuration || audioBuffer.duration);
      } catch (error) {
        console.error('Error fetching or processing audio:', error);
      }
    };

    fetchAudio();
  }, [fetchAudioBlob]);



  const handleStop = () => {
    console.log('Stopping audio playback and resetting state.');
  
    // Stop and clean up the current audio source
    if (audioSourceRef.current) {
      try {
        audioSourceRef.current.stop();
        audioSourceRef.current.disconnect();
      } catch (error) {
        console.error('Error stopping or disconnecting source:', error);
      }
      audioSourceRef.current = null;
    }
  
    // Cancel progress tracking
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
  
    // Reset playback states
    setIsPlaying(false);
    setCurrentTime(0);
    startOffsetRef.current = 0;
  
    // Suspend the audio context to stop any lingering audio output
    if (audioContextRef.current) {
      if (audioContextRef.current.state === 'running') {
        audioContextRef.current.suspend().catch((error) => {
          console.error('Error suspending audio context:', error);
        });
      }
    }
  
    // Avoid reinitializing the AudioMotionAnalyzer unless destroyed
    if (audioMotionAnalyzerRef.current) {
      console.log('AudioMotionAnalyzer instance is already active.');
    } else if (containerRef.current && audioContextRef.current) {
      // Reinitialize the AudioMotionAnalyzer only if needed
      console.log('Reinitializing AudioMotionAnalyzer...');
      audioMotionAnalyzerRef.current = new AudioMotionAnalyzer(containerRef.current, {
        height: 200,
        width: 600,
        gradient: 'rainbow',
      });
    }
  
    console.log('Playback stopped. File and duration states maintained.');
  };
  

  return (
    
    <div >
      <div className="audio-play-bar">
        <div className="audio-controls" >
        <button onClick={handlePlayPause}>{isPlaying ? <FaPause size={18} /> : <FaPlay size={18} />}</button>
        <button onClick={() => handleSkip(-10)}><FaBackward size={18} /></button>
        <button onClick={() => handleSkip(10)}><FaForward size={18} /></button>
        <button onClick={handleSpeedToggle}>{`${playbackRate}x`}</button>
        <button onClick={handleStop}> <FaStop size={18} /> </button>
       </div>
      </div>
      <div
        ref={containerRef}
        id="audio-motion-container"
        style={{ height: '200px', width: '100%' }}
       >
      </div>

      <div style={{ marginTop: '20px', width: '100%' }}>
        <input
          type="range"
          min="0"
          max={totalDuration}
          step="0.1"
          value={currentTime}
          onChange={handleProgressChange}
          onMouseUp={handleSeekEnd}
          onTouchEnd={handleSeekEnd}
          style={{ width: '100%' }}
        />
        <div style={{ textAlign: 'center', fontSize: '14px', color: '#555', marginTop: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>Elapsed:</span>{' '}
        {new Date(currentTime * 1000).toISOString().substr(11, 8)}{' '}
        <span style={{ fontWeight: 'bold', margin: '0 5px' }}>/</span>{' '}
        <span style={{ fontWeight: 'bold' }}>Total:</span>{' '}
        {new Date(totalDuration * 1000).toISOString().substr(11, 8)}
        </div>

      </div>
    </div>

  );
};

export default CustomAudioMotionAnalyzer;
