import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth } from '../firebaseConfig'; // Ensure you're importing the correct Firebase config
import NavBar from './NavBar';
import './Base.css';
import { useNavigate } from 'react-router-dom';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [questionContainerColor, setQuestionContainerColor] = useState<string>('#ffffff');
  const [optionBackgroundColor, setOptionBackgroundColor] = useState<string>('#282c34');
  const [userId, setUserId] = useState<string | null>(null); // Track user ID


    // Disable scrolling when the component is mounted
    useEffect(() => {
      // Disable scrolling on mount
      document.body.style.overflow = 'hidden';
  
      // Cleanup function to restore scrolling when the component unmounts
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, []);
  
  // Fetch color settings from Firestore when the component loads
  useEffect(() => {
    const fetchUserSettings = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
      setUserId(currentUser.uid);

      const db = getFirestore();
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const savedSettings = userData?.settings; 

        if (savedSettings) {
          if (savedSettings.questionContainerColor) {
            setQuestionContainerColor(savedSettings.questionContainerColor);
          }
          if (savedSettings.optionBackgroundColor) {
            setOptionBackgroundColor(savedSettings.optionBackgroundColor);
          }
        }
      }
    };

    fetchUserSettings();
  }, []);

  // Update Firestore with new color settings
  const saveColorSettings = async (containerColor: string, optionColor: string) => {
    if (!userId) return;

    const db = getFirestore();
    const userDocRef = doc(db, 'users', userId);

    await updateDoc(userDocRef, {
      settings: {
        questionContainerColor: containerColor,
        optionBackgroundColor: optionColor,
      },
    });
  };

  // Handle color changes for question container and option background
  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'container' | 'option') => {
    const selectedColor = e.target.value;
    if (type === 'container') {
      setQuestionContainerColor(selectedColor);
      saveColorSettings(selectedColor, optionBackgroundColor); // Save to Firestore
    } else if (type === 'option') {
      setOptionBackgroundColor(selectedColor);
      saveColorSettings(questionContainerColor, selectedColor); // Save to Firestore
    }
  };

  // Save default colors to Firestore when reset
  const setDefaultColor = () => {
    const defaultContainerColor = '#2b2f33';
    setQuestionContainerColor(defaultContainerColor);
    saveColorSettings(defaultContainerColor, optionBackgroundColor); // Save to Firestore
  };

  const setDefaultOptionColor = () => {
    const defaultOptionColor = '#282c34';
    setOptionBackgroundColor(defaultOptionColor);
    saveColorSettings(questionContainerColor, defaultOptionColor); // Save to Firestore
  };

  const resetAllColors = () => {
    const defaultContainerColor = '#2b2f33';
    const defaultOptionColor = '#282c34';
    setQuestionContainerColor(defaultContainerColor);
    setOptionBackgroundColor(defaultOptionColor);
    saveColorSettings(defaultContainerColor, defaultOptionColor); // Save to Firestore
  };

  return (
    <div className="container">
      <NavBar userImage={userImage} onProfileLoad={(loadedFirstName, loadedLastName, loadedImageUrl, loadedBio) => setUserImage(loadedImageUrl)} />
      <div className="main-content">
        <div className="content">
          <div className="content-inner">
            <h1 className="billing-title">Settings ⚙️</h1>

            <div className="color-settings">
             <div className="color-row-container">
              <div className="color-row">
                <h2>Select Question Panel Color:</h2>
                <input 
                  type="color" 
                  value={questionContainerColor} 
                  onChange={(e) => handleColorChange(e, 'container')} 
                  className="color-input"
                />
              </div>
              </div>   
              <button className="default-color-button" onClick={setDefaultColor}>
                Use Default Panel Color
              </button>

              <div className="color-row-container">
              <div className="color-row">
                <h2>Select Question Option Color:</h2>
                <input 
                  type="color" 
                  value={optionBackgroundColor} 
                  onChange={(e) => handleColorChange(e, 'option')} 
                  className="color-input"
                />
               </div>
              </div>
              <button className="default-color-button" onClick={setDefaultOptionColor}>
                Use Default Option Color
              </button>

            </div>
            <button className="default-color-button" onClick={resetAllColors} style={{ marginLeft: '10px', marginTop: '10px'}}>
              Reset All Colors
            </button>
          </div>

        </div>
      </div>
    </div>

    
  );
};

export default Settings;
