import React, { useEffect, useState } from 'react';

interface CustomConfirmModalProps {
  message: string;
  onPublic: () => void;
  onPrivate: () => void;
  onCancel: () => void;
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const CustomConfirmModal: React.FC<CustomConfirmModalProps> = ({
  message,
  onPublic,
  onPrivate,
  onCancel,
  tags,
  setTags,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleAddTag = () => {
    if (inputValue.trim()) {
      if (!tags.includes(inputValue.trim())) {
        setTags((prevTags) => [...prevTags, inputValue.trim()]);
        setInputValue('');
        setError('');
      } else {
        setError('Tag already exists.');
      }
    } else {
      setError('Tag cannot be empty.');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  useEffect(() => {
    if (tags.length === 0) {
      setError('Please provide at least one tag for the document.');
    } else {
      setError('');
    }
  }, [tags]);

  return (
    <div className="modal-overlay">
      <div className="modal-content qwindow-modal">
        <label className="qwindow-label" style={{ marginBottom: '10px' }}>
          Add Tags to the File
        </label>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter a tag and press Add Tag"
            style={{ flex: 1, marginRight: '10px', padding: '5px' }}
          />
          <button onClick={handleAddTag} style={{ padding: '5px 5px' }}>
            Add Tag
          </button>
        </div>
        {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}

        {tags.length > 0 && ( // Show the tags list only when there are tags
          <div style={{ color: 'white', textAlign: 'left', marginBottom: '10px' }}>
            <ul style={{ paddingLeft: '20px', margin: 0, listStyleType: 'disc' }}>
              {tags.map((tag, index) => (
                <li
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <span>{tag}</span>
                  <button
                    onClick={() => handleRemoveTag(tag)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      color: 'red',
                    }}
                    aria-label="Remove tag"
                  >
                    ❌
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <label className="qwindow-label" style={{ marginBottom: '20px' }}>
          {message}
        </label>
        <button onClick={onPrivate} style={{ marginRight: '20px' }}>
          Keep the File Private
        </button>
        <button onClick={onPublic}>Share with Public</button>

        <div style={{ marginTop: '20px' }}>
          <button className="cancel-button"  onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default CustomConfirmModal;
