import React, { useState, useEffect } from 'react';
import { auth, googleProvider } from '../firebaseConfig';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  signInAnonymously, 
  sendEmailVerification, 
  onAuthStateChanged,
} from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { getFirestore, doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import Logo from '../assets/logo.png';
import GoogleLogo from '../assets/google-logo.svg';
import AnonymousLogo from '../assets/anonymous-logo.svg';
import apple_logo from '../assets/apple_logo.png';
import google_play from "../assets/google_play.png";
import defaultUserImage from '../assets/default-user.png';  
import './Base.css';

const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [showLogo, setShowLogo] = useState(false);
  const [showAppName, setShowAppName] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if a user is already logged in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in, redirect to /home
        navigate('/home');
      }
    });

    // Show the logo first, then the app name
    const logoTimer = setTimeout(() => setShowLogo(true), 500); // Show logo after 0.5 seconds
    const nameTimer = setTimeout(() => setShowAppName(true), 2000); // Show app name after 2 seconds

    return () => {
      unsubscribe();
      clearTimeout(logoTimer);
      clearTimeout(nameTimer);
    };
  }, [navigate]);

  
  const handleEmailAuth = async () => {
    try {
      if (isLogin) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        if (!user.emailVerified) {
          setEmailNotVerified(true);
          alert('Please verify your email before logging in.');
          return;
        }

        navigate('/home'); // User is verified, navigate to home
      } else {
        if (password !== confirmPassword) {
          alert('Passwords do not match');
          return;
        }

        // Create a new user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Send email verification
        await sendEmailVerification(user);

        // Save user profile to Firestore
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', user.uid);
        await setDoc(userDocRef, {
          firstName: name,
          email: user.email,
          bio: '',
          imageUrl: defaultUserImage,
          subscriptionStatus: 'Starter',
        });

        alert('Account created successfully. Please check your email for verification.');
        navigate('/auth'); // Navigate to login page after signup
      }
    } catch (error: any) {
      console.error('Authentication error:', error);
      //alert(`Authentication failed: ${error.message}`);
      alert(`Please verify your email and password and try again.`);      
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        alert('Verification email resent. Please check your inbox.');
      } else {
        alert('Email is already verified or user is not logged in.');
      }
    } catch (error: any) {
      console.error('Error resending verification email:', error);
      //alert(`Failed to resend verification email: ${error.message}`);
      alert(`Failed to resend verification email.`);
    }
  };

  const handleGoogleAuth = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
  
      // Extract the user's information
      const displayName = user.displayName;
      const email = user.email;
      const photoURL = user.photoURL;
  
      //console.log('User Name:', displayName);
      //console.log('User Email:', email);
      //console.log('User Profile Picture:', photoURL);
      //console.log('User uid:', user.uid);
  
      // Initialize Firestore
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', user.uid);
  
      // Retrieve the current document for the user
      const userDoc = await getDoc(userDocRef);
  
      // Prepare update data
      const updateData: Record<string, any> = { email }; // Always update email
  
      // Conditionally update name and imageUrl only if they are not already set
      if (!userDoc.exists() || !userDoc.data()?.name) {
        updateData.name = displayName;
      }
  
      if (!userDoc.exists() || !userDoc.data()?.imageUrl) {
        updateData.imageUrl = photoURL;
      }
  
      // Save or update user info in Firestore
      await setDoc(userDocRef, updateData, { merge: true });
  
      // Navigate to home
      navigate('/home');
    } catch (error: any) {
      console.error('Google authentication error:', error);
      alert(`Google authentication failed.`);
    }
  };
  
  const handleAnonymousAuth = async () => {
    try {
      // Sign in anonymously
      const userCredential = await signInAnonymously(auth);
      const currentUser = userCredential.user;
      const userUID = currentUser.uid;
      const firestore = getFirestore();
      
      // Get user's IP address
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ipAddress = ipData.ip;
  
      // Reference to the "anonymousUsers" document in the "admin" collection
      const docRef = doc(firestore, 'admin', 'anonymousUsers');
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
  
        // Increment the "counts" field
        const newCount = (data.counts || 0) + 1;
  
        // Check if the IP address already exists
        let ipAddressData = data.ipAddress || {};
        if (ipAddressData[ipAddress]) {
          ipAddressData[ipAddress].count += 1;
        } else {
          ipAddressData[ipAddress] = { count: 1 };
        }
  
        // Update Firestore with the new counts and ipAddress data
        await updateDoc(docRef, {
          counts: newCount,
          ipAddress: ipAddressData
        });
      } else {
        // If the document doesn't exist, create it
        const initialData = {
          counts: 1,
          ipAddress: {
            [ipAddress]: { count: 1 }
          }
        };
        await setDoc(docRef, initialData);
      }
  
      // Add the "anonymousUser" field to the user's document in the "users" collection
      const userDocRef = doc(firestore, 'users', userUID);
      await setDoc(userDocRef, {
        anonymousUser: true
      }, { merge: true });
  
      navigate('/login-select');
    } catch (error: any) {
      console.error('Anonymous authentication error:', error);
      //alert(`Anonymous authentication failed: ${error.message}`);      
      alert(`Anonymous authentication failed.`);
    }
  };
  

  const buttonStyle = {
    width: '250px',
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    marginBottom: '10px',
    marginLeft: '0px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  };

  const buttonHoverStyle = {
    backgroundColor: '#45a049',
  };

  const googleButtonStyle = {
    ...buttonStyle,
    width: '200px',
    backgroundColor: '#9E9E9E',
    marginLeft: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const anonymousButtonStyle = {
    ...buttonStyle,
    width: '200px',
    backgroundColor: '#db4437',
    marginLeft: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const logoStyle: React.CSSProperties = {
    width: '60px',
    marginBottom: '10px',
    opacity: showLogo ? 1 : 0,
    transition: 'opacity 1s ease',
    visibility: showLogo ? 'visible' : 'hidden',
  };

  const appNameStyle: React.CSSProperties = {
    fontSize: '48px',
    marginBottom: '-30px',
    marginTop: '-10px',
    opacity: showAppName ? 1 : 0,
    transition: 'opacity 1s ease',
    visibility: showAppName ? 'visible' : 'hidden',
    animation: showAppName ? 'moveUp 1s forwards' : 'none',
  };

  const inputStyle: React.CSSProperties = {
    width: '180%',
    padding: '10px',
    marginBottom: '10px',
    marginLeft: '-55px',
    borderRadius: '10px', // Rounded corners for input boxes
    border: '1px solid #ccc', // Optional: Add a border
  };

  const dividerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF', // White color for the text and line
    margin: '20px 0',
    width: '10%',
  };

  const lineStyle: React.CSSProperties = {
    flex: 1,
    height: '1px',
    backgroundColor: '#FFFFFF', // White color for the line
  };

  const orTextStyle: React.CSSProperties = {
    margin: '0 10px',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={{ 
      maxWidth: '100%', 
      margin: '0 auto', 
      padding: '20px', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh', 
      textAlign: 'center',
      backgroundColor: '#000000', // Change background color to black
      color: '#FFFFFF', // Change text color to white
    }}>
     <div style={{ marginBottom: '10px', position: 'relative', top: '-30px' }}>
       {/* Logo with fade-in effect */}
       <img
          src={Logo}
          alt="CompreQnA Logo"
          style={logoStyle}
        />
        {/* App name with delayed fade-in effect */}
        <h1 style={appNameStyle}>
          CompreQnA
        </h1>
      </div>

      {showAppName && (
        <>
          {!isLogin && (
            <div>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={inputStyle}
              />
            </div>
          )}
          <div>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyle}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyle}
            />
          </div>
          {!isLogin && (
            <div>
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={inputStyle}
              />
            </div>
          )}
          <button
            onClick={handleEmailAuth}
            style={buttonStyle}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor)}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#4CAF50')}
          >
            {isLogin ? 'Login' : 'Sign Up'}
          </button>

          {/* If the user's email is not verified, show this button */}
          {emailNotVerified && (
            <button
              onClick={resendVerificationEmail}
              style={{ ...buttonStyle, backgroundColor: '#FFA500' }}
            >
              Resend Verification Email
            </button>
          )}

          <button
            onClick={() => setIsLogin(!isLogin)}
            style={{ ...buttonStyle, backgroundColor: '#008CBA' }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#007bb5')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#008CBA')}
          >
            {isLogin ? 'Need to create an account?' : 'Already have an account?'}
          </button>

          {/* Divider with "OR" */}
          <div style={dividerStyle}>
            <div style={lineStyle}></div>
            <div style={orTextStyle}>OR</div>
            <div style={lineStyle}></div>
          </div>

          <button
            onClick={handleGoogleAuth}
            style={googleButtonStyle}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#828282')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#9E9E9E')}
          >
            <img src={GoogleLogo} alt="Google logo" style={{ width: '40px', marginRight: '10px' }} />
            Sign in with Google
          </button>

          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h2 style={{ marginBottom: '-50px', fontSize: '14px', color: '#FFFFFF' }}>Coming Soon!!!</h2> {/* Updated text color to white */}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
            <img
              src={apple_logo}
              alt="Apple App Store logo"
              style={{ width: '150px', height: 'auto', marginLeft: "-150px" }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-45px' }}>
            <img
              src={google_play}
              alt="Google Play Store logo" 
              style={{ width: '150px', height: 'auto', marginRight: "-150px" }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Link to="/support" style={{ marginRight: '30px', color: '#FFFFFF', textDecoration: 'none',  marginTop: '20px' }}>Support</Link> {/* Updated link color */}
            <Link to="/privacy-policy" style={{ marginRight: '30px', color: '#FFFFFF', textDecoration: 'none',  marginTop: '20px' }}>Privacy Policy</Link> {/* Updated link color */}
            <Link to="/terms" style={{ color: '#FFFFFF', textDecoration: 'none',  marginTop: '20px' }}>Terms</Link> {/* Updated link color */}
          </div>
        </>
      )}
    </div>
  );
};

export default Auth;
