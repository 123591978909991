// caddDesignerFunctions.tsx

type FunctionParameters = {
  type: "object";
  properties: Record<string, { type: string; description: string; enum?: string[] }>;
  required: string[];
};

interface FunctionDefinition {
  name: string;
  description: string;
  parameters: FunctionParameters;
}

type AssistantTool =
| { type: 'code_interpreter'; languages?: string[] }  // Making 'languages' optional
| { type: 'file_search' }
| { type: 'function'; function: FunctionDefinition };

export const caddDesignerFunctions: AssistantTool[] = [
  {
    type: "function" as const,
    function: {
      name: "caddDesigner-getCurrentTemperature",
      description: "Get the current temperature for a specific location",
      parameters: {
        type: "object" as const,
        properties: {
          location: {
            type: "string",
            description: "The city and state, e.g., San Francisco, CA",
          },
          unit: {
            type: "string",
            enum: ["Celsius", "Fahrenheit"],
            description: "The temperature unit to use. Infer this from the user's location.",
          },
        },
        required: ["location", "unit"],
      },
    },
  },
  {
    type: "function" as const,
    function: {
      name: "caddDesigner-getRainProbability",
      description: "Get the probability of rain for a specific location",
      parameters: {
        type: "object" as const,
        properties: {
          location: {
            type: "string",
            description: "The city and state, e.g., San Francisco, CA",
          },
        },
        required: ["location"],
      },
    },
  },
];
