import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { listAll, getDownloadURL, ref, deleteObject, uploadBytes,updateMetadata,getMetadata } from 'firebase/storage';
import { signOut, onAuthStateChanged} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, addDoc, query, where,getDocs,deleteDoc,updateDoc} from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import PublicProfile from './PublicProfile';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import { FirebaseError } from 'firebase/app';
import OpenAI from "openai";
import Logo from '../assets/logo.png'; // Assuming your logo is here
import { FaPaperPlane, FaShareSquare,FaEdit,FaTrashAlt,FaCommentDots,FaSearch, FaCompass, FaUpload, FaFolderOpen,FaDownload} from 'react-icons/fa';
import { PDFDocument, rgb} from 'pdf-lib';
import * as fontkit from 'fontkit';
import PdfViewerModal from './PdfViewerModal'; 
import CustomConfirmModal from './CustomConfirmModal';
import { formatDistanceToNow } from 'date-fns';
import audioFile from "../assets/userInterfaceIcons/audioFile.png";
import pdfFile from "../assets/userInterfaceIcons/pdfFile.png";
import qnaFile from "../assets/userInterfaceIcons/qnaFile.png";
import youTubeFile from "../assets/userInterfaceIcons/youTubeFile.png";
import defaultUser from "../assets/default-user.png";

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })
  interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; // Optional property for different message types
  }
  
interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    virtualId: string;
    instructions?: string;
    model:string,
    tool:string;
    vectorStore:string; 
    threadID: string; 
  }

  interface FileData {
    name: string;
    userId: string;
    url: string;
    youtubeUrl: string;
    thumbnailUrl:string;
    refPath: string;
    createdAt: Date; 
    viewCount: number;
    documentType: string; 
    shareWithPublic: string; 
    userImageUrl: string; 
    qnaUrl:string;
    summaryUrl:string;   
    transcriptUrl:string;
    fileTags:string;
    size:number;
    fileNumber:string;
    duration:number;
  }
  
  type ToolbarItem = {
    label: string;
    key: string;
    icon?: React.ReactNode; // `icon` is optional
    title?: string; // `title` is optional
  };
  
  type Fontkit = any; 

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [files, setFiles] = useState<FileData[]>([]);
  const [visibleFiles, setVisibleFiles] = useState<FileData[]>([]);
  const [page, setPage] = useState(1);
  const filesPerPage = 20; // Number of files to load per page
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const [fileName, setFileName] = useState<string>('');  
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [currentFileRefPath, setCurrentFileRefPath] = useState('');
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const [isQnaModalOpen, setIsQnaModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);  
  const [numQuestions, setNumQuestions] = useState(10);
  const [questionFormat, setQuestionFormat] = useState('Multiple choice');
  const [summaryFormat, setSummaryFormat] = useState('Long and Detailed'); 
  const [difficultyLevel, setDifficultyLevel] = useState('Medium'); // New state for difficulty level
  const [animationOption, setAnimationOption] = useState('Yes'); // New state for animation
  const [progressText, setProgressText] = useState<string>(''); 
  const [showProgress, setShowProgress] = useState<boolean>(false); 
  const [qnaStatus, setQnaStatus] = useState<Record<string, boolean>>({});
  const [summaryStatus, setSummaryStatus] = useState<Record<string, boolean>>({});
  const fetchAttemptedRef = useRef<Record<string, boolean>>({});
  const fetchAttemptedRef1 = useRef<Record<string, boolean>>({});
  const firestoreFetchAttempted = useRef(false); // Ref to track Firestore fetch attempts
  const filesFetchAttempted = useRef(false);
  const [qnaFileUrl, setQnaFileUrl] = useState<string | null>(null);
  const [summaryFileUrl, setSummaryFileUrl] = useState<string | null>(null);  
  const [isAnimationRunning, setIsAnimationRunning] = useState(false); // New state to control animation visibility
  const [shareModalOpen, setShareModalOpen] = useState(false); // State for Share Modal
  const [shareEmail, setShareEmail] = useState(''); // Email input state
  const [shareMessage, setShareMessage] = useState(''); // Message input state
  const [shareFileUrl, setShareFileUrl] = useState<string | null>(null); // The file URL to be shared
  const [shareFileName, setShareFileName] = useState<string | null>(null); 
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [showAnimationOptions] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown menu
  const [userUID, setUserUID] = useState<string>('');
  const user = auth.currentUser;
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState<Assistant | null>(null);
  const [assistantFiles, setAssistantFiles] = useState<{ id: string; name: string }[]>([]);
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [selectedChatFile, setSelectedChatFile] = useState<string | null>(null);
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [showShareConfirmModal, setShowShareConfirmModal] = useState<boolean>(false);  
  const [shareWithPublic, setShareWithPublic] = useState<boolean>(true);
  const toolBarRef = useRef<HTMLDivElement | null>(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [currentFile, setCurrentFile] = useState<FileData | null>(null);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [filteredFiles, setFilteredFiles] = useState(files); 
  const processedFilesRef = useRef(new Set<string>());
  const [showSidebar, setShowSidebar] = useState(false);  
  const [showPublic, setShowPublic] = useState(true);
  const [showYourSpace, setShowYourSpace] = useState(false);  
  const [showAll, setShowAll] = useState(true);  
  const [showPDF, setShowPDF] = useState(false);  
  const [showAudio, setShowAudio] = useState(false); 
  const [showVideo, setShowVideo] = useState(false);   
  const [showQna, setShowQna] = useState(false);  
  const [showProfile, setShowProfile] = useState(false);
  const [profileUID, setProfileUID] = useState<string | null>(null);
  const [transcriptUrl, setTranscriptUrl] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<FileData | null>(null);
  //const [showAssistants, setShowAssistants] = useState(false);   
  const [tags, setTags] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [originalFiles, setOriginalFiles] = useState<FileData[]>([]);
  const [subjectLabels, setSubjectLabels] = useState<{ [key: string]: string }>({
    subject1: "Math",
    subject2: "Science",
    subject3: "History",
    subject4: "English",
    subject5: "Art",
    subject6: "Music",
    subject7: "Sport",
    subject8: "Coding",
  });
  const [plan, setPlan] = useState<string | null>(null); 
  const db = getFirestore();

  const fetchUserPlan = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscriptionStatus = userData?.subscriptionStatus;

        // Determine user plan based on Firestore data
        let planType = 'Starter';
        if (subscriptionStatus === 'Premium') planType = 'Premium';
        else if (subscriptionStatus === 'Chat') planType = 'Chat';
        else if (subscriptionStatus === 'ProChat') planType = 'ProChat';

        setPlan(planType); // Set the user plan
      } else {
        console.warn("User document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching user plan:", error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        fetchUserPlan(user.uid); // Fetch user plan when authenticated
      } else {
        console.warn("No user authenticated, data fetching skipped.");
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [fetchUserPlan]);




  const fetchAllUserFiles = useCallback(async () => {
    if (filesFetchAttempted.current) return;

    try {
        const folderRefs = [
            ref(storage, 'uploads/'),
            ref(storage, 'videos/'),
            ref(storage, 'recordings/'),
           // ref(storage, 'library/'),
        ];

        const profileImagesRef = ref(storage, 'profileImages/');

        // Fetch all profile images to map user_id to userImageUrl
        const fetchProfileImages = async () => {
            const profileImagesSnapshot = await listAll(profileImagesRef);
            const userImageMap: Record<string, string> = {};

            for (const item of profileImagesSnapshot.items) {
                const user_id = item.name.split('.')[0]; // Assuming the user_id is the file name without extension
                const imageUrl = await getDownloadURL(item);
                userImageMap[user_id] = imageUrl;
            }

            return userImageMap;
        };

        const userImageMap = await fetchProfileImages();

        const userFilesPromises = folderRefs.map(async (folderRef) => {
            const res = await listAll(folderRef);

            return res.items.map(async (item) => {
                const url = await getDownloadURL(item);
                let createdAt = new Date();
                let documentType = '';
                let shareWithPublic = 'No';
                let youtubeUrl = ''; // Default value
                let thumbnailUrl = ''; // Default value
                let qnaUrl = ''; 
                let summaryUrl = ''; 
                let transcriptUrl = '';                  
                let userImageUrl = '';
                let fileTags = '';                
                let viewCount = 0; // Default value
                let name = '';
                let user_id = '';
                let size = 0;
                let fileNumber='';
                let duration = 0;

                try {
                    const metadata = await getMetadata(item);
                    createdAt = metadata.timeCreated ? new Date(metadata.timeCreated) : createdAt;
                    documentType = metadata.customMetadata?.DocumentType || '';
                    shareWithPublic = metadata.customMetadata?.ShareWithPublic || 'No';
                    viewCount = metadata.customMetadata?.viewCount
                        ? parseInt(metadata.customMetadata.viewCount, 10)
                        : 0;
                    thumbnailUrl = metadata.customMetadata?.ThumbnailUrl || '';
                    qnaUrl = metadata.customMetadata?.QnaUrl || '';  
                    summaryUrl = metadata.customMetadata?.SummaryUrl || '';   
                    transcriptUrl = metadata.customMetadata?.TranscriptUrl || '';    
                    fileNumber= metadata.customMetadata?.NewFileNumber || '';  
                    fileTags = metadata.customMetadata?.Tags || '';                                                                              
                    user_id = metadata.customMetadata?.UserID || '';
                    duration = Number(metadata.customMetadata?.Duration) || 0;
                    userImageUrl = userImageMap[user_id] || ''; // Get user image URL from the map

                    if (documentType === 'YouTube Video') {
                        name = metadata.customMetadata?.YoutubeTitle || '';
                        const response = await fetch(url);
                        youtubeUrl = await response.text();
                        const videoId = youtubeUrl.split('v=')[1]?.split('&')[0] || '';
                        thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
                    } else {
                        name = item.name.replace(`${user_id}_`, '');
                    }
                } catch (metadataError) {
                    console.warn("Error fetching metadata:", metadataError);
                }

                return {
                    name,
                    userId:user_id,
                    url,
                    refPath: item.fullPath,
                    youtubeUrl,
                    thumbnailUrl,
                    createdAt,
                    viewCount,
                    documentType,
                    shareWithPublic,
                    userImageUrl,
                    qnaUrl,
                    summaryUrl,
                    transcriptUrl, 
                    fileTags,  
                    size,    
                    fileNumber,   
                    duration,          
                };
            });
        });

        const allFiles = await Promise.all(userFilesPromises);
        const filesWithUrls = await Promise.all(allFiles.flat());

        // Fetch public files
        const publicFilesPromises = folderRefs.map(async (folderRef) => {
            const res = await listAll(folderRef);

            return res.items.map(async (item) => {
                const url = await getDownloadURL(item);
                let createdAt = new Date();
                let documentType = '';
                let shareWithPublic = 'No';
                let youtubeUrl = ''; // Default value
                let thumbnailUrl = ''; // Default value
                let qnaUrl = ''; 
                let summaryUrl = '';    
                let transcriptUrl = '';                    
                let userImageUrl = '';
                let viewCount = 0; // Default value
                let name = '';
                let user_id = '';
                let fileTags = '';  
                let size = 0;
                let fileNumber = '';
                let duration = 0;
                try {
                    const metadata = await getMetadata(item);
                    createdAt = metadata.timeCreated ? new Date(metadata.timeCreated) : createdAt;
                    documentType = metadata.customMetadata?.DocumentType || '';
                    shareWithPublic = metadata.customMetadata?.ShareWithPublic || 'No';
                    user_id = metadata.customMetadata?.UserID || '';
                    qnaUrl = metadata.customMetadata?.QnaUrl || '';  
                    summaryUrl = metadata.customMetadata?.SummaryUrl || '';  
                    transcriptUrl = metadata.customMetadata?.TranscriptUrl || '';  
                    fileNumber= metadata.customMetadata?.NewFileNumber || '';  
                    fileTags = metadata.customMetadata?.Tags || '';  
                    duration = Number(metadata.customMetadata?.Duration) || 0;                   
                    userImageUrl = userImageMap[user_id] || ''; // Get user image URL from the map

                    if (shareWithPublic === 'Yes') {
                        name = item.name.replace(`${user_id}_`, '');
                    }
                } catch (metadataError) {
                    console.warn("Error fetching metadata:", metadataError);
                }

                return {
                    name,
                    userId: user_id,
                    url,
                    refPath: item.fullPath,
                    youtubeUrl,
                    thumbnailUrl,
                    createdAt,
                    viewCount,
                    documentType,
                    shareWithPublic,
                    userImageUrl,
                    qnaUrl,
                    summaryUrl,
                    transcriptUrl, 
                    fileTags,    
                    size, 
                    fileNumber,    
                    duration,          
                };
            });
        });

        const publicFiles = await Promise.all(publicFilesPromises);
        const allPublicFiles = await Promise.all(publicFiles.flat());
        const filteredPublicFiles = allPublicFiles.filter(file => file.shareWithPublic === 'Yes');

        // Combine user files and public files, ensuring no duplicates
        const combinedFiles = [
            ...filesWithUrls,
            ...filteredPublicFiles.filter(publicFile => !filesWithUrls.some(file => file.url === publicFile.url)),
        ];

        setFiles(combinedFiles); // No type errors now

        filesFetchAttempted.current = true;
    } catch (error) {
        console.error("Error fetching files:", error);
    }
}, []);

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchAllUserFiles(); 
        firestoreFetchAttempted.current = false; 
        filesFetchAttempted.current = false; 
      } else {
        console.warn('No user is authenticated.');
        setFiles([]); 
      }
    });
  
    return () => unsubscribe();
  }, [fetchAllUserFiles]);


  useEffect(() => {
    const filtered = files.filter((file) => {
      // Only include files shared with the public when 'Public' is active
      if (showPublic && file.shareWithPublic !== 'Yes') {
        return false;
      }
  
      if (user) {
        if (showYourSpace && !file.url.includes(user.uid)) {
          return false; 
        }
      } else {
        console.warn('No user is authenticated.');
      }
  
      // Apply additional filters based on the selected type
      if (showAll) return true;
  
      const matchPDF = showPDF && file.documentType === 'Note';
      const matchAudio = showAudio && file.documentType === 'Recording';
      const matchVideo = showVideo && file.documentType === 'YouTube Video';
      const matchQna = showQna && file.documentType === 'Question';
  
      return matchPDF || matchAudio || matchVideo || matchQna;
    });
  
    // Sort by createdAt descending
    filtered.sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime());
    setFilteredFiles(filtered);
  }, [showAll, showPDF, showAudio, showVideo, showQna, showPublic, showYourSpace, user, files]);
  
  // Handler for toggling the menu
  const toggleMenu = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.querySelector('.menu-content');
      const isClickInside = dropdownElement?.contains(event.target as Node);
      
      // If the click is outside the dropdown, close it
      if (!isClickInside) {
        setOpenMenuIndex(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const checkQnaFileExists = useCallback(async (fileUrl: string, qnaUrl: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser || fetchAttemptedRef.current[fileUrl]) {
      return;
    }
  
    fetchAttemptedRef.current[fileUrl] = true;
  
    if (!qnaUrl) {
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
      return;
    }
  
    try {
      // Create a StorageReference using the qnaUrl
      const qnaFileRef = ref(storage, qnaUrl);
  
      // Attempt to fetch the download URL
      await getDownloadURL(qnaFileRef);
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: true }));
    } catch (error) {
      setQnaStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
    }
  }, []);

  const checkSummaryFileExists = useCallback(async (fileUrl: string, summaryUrl: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser || fetchAttemptedRef1.current[fileUrl]) {
      return;
    }

    fetchAttemptedRef1.current[fileUrl] = true;
  
    if (!summaryUrl) {
      setSummaryStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
      return;
    }
    try {
      // Create a StorageReference using the summaryUrl
      const summaryFileRef = ref(storage, summaryUrl);
      // Attempt to fetch the download URL
      await getDownloadURL(summaryFileRef);
      setSummaryStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: true }));
    } catch (error) {
      setSummaryStatus((prevStatus) => ({ ...prevStatus, [fileUrl]: false }));
    }
  }, []);
  

  useEffect(() => {
    files.forEach((file) => {
      checkQnaFileExists(file.url, file.qnaUrl);
      checkSummaryFileExists(file.url, file.summaryUrl);
    });
  }, [files, checkQnaFileExists, checkSummaryFileExists]);


  useEffect(() => {
    files.forEach((file) => {
      if (!processedFilesRef.current.has(file.url)) {
        processedFilesRef.current.add(file.url); // Mark as processed
  
        if (file.thumbnailUrl) {
          // Use the existing thumbnail URL if available
          setThumbnails((prev) => ({ ...prev, [file.url]: file.thumbnailUrl }));
        } 
      }
    });
  }, [files]);
  

  const saveSelectedFileToDatabase = async (uid: string, fileUrl: string) => {
    if (!uid) return;

    const firestore = getFirestore();
    try {
      const userDocRef = doc(firestore, 'users', uid);
      await setDoc(userDocRef, { selectedFileUrl: fileUrl }, { merge: true });
    } catch (error) {
      if (error instanceof FirebaseError && error.code !== 'permission-denied') {
        console.error("Error saving selected file to Firestore:", error);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  // Handler for closing the Share Modal
  const handleCancelShare = () => {
    setShareModalOpen(false);
  };

  const handleShareFile = (fileUrl: string, fileName: string) => {
    setShareFileUrl(fileUrl); // Set the file URL to be shared
    setShareFileName(fileName); // Set the file name to be shared
    setShareModalOpen(true); // Open the modal
  };


  // Function to get the user by email from Firestore
  const getUserByEmail = async (email: string) => {
    const firestore = getFirestore();
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('email', '==', email));    
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return { uid: userDoc.id, ...userDoc.data() }; // Return UID and user data

    }
    return null; // No user found with the provided email
  };


  const handleSendShare = async () => {
    const firestore = getFirestore();
  
    try {
      if (!currentFileRefPath) {
        alert('No file selected for sharing.');
        return;
      }
  
      // Get the file's metadata
      const fileRef = ref(storage, currentFileRefPath);
      const metadata = await getMetadata(fileRef);
  
      if (!metadata.customMetadata) {
        alert('File metadata not found.');
        return;
      }
  
      const { DocumentType: documentType, NewFileNumber: newFileNumber } = metadata.customMetadata;
  
      if (!documentType) {
        alert('DocumentType is missing in the file metadata.');
        return;
      }
  
      // Fetch the receiver's UID based on the email provided in the modal
      const receiverUser = await getUserByEmail(shareEmail);
  
      if (!receiverUser) {
        alert('User with this email does not exist.');
        return;
      }
  
      const receiverUID = receiverUser.uid; // Get the receiver's UID
  
      // Add a notification to the receiver's Firestore collection
      await addDoc(collection(firestore, 'notifications'), {
        receiverId: receiverUID,
        senderId: auth.currentUser?.uid,
        fileUrl: shareFileUrl,
        fileName: shareFileName, // Send the file name with the notification
        fileType: documentType, // Set fileType based on DocumentType
        fileNumber:newFileNumber ||'',
        message: `${auth.currentUser?.email} is sharing the file "${shareFileName}" with you.`,
        status: 'pending', // Initially, the status is pending
        createdAt: new Date(),
        isRead: false, // Ensure that new notifications are marked as unread
      });
  
      alert(`Sharing file "${shareFileName}" message sent to ${shareEmail}`);
      setShareModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sharing file:', error);
      alert('Failed to share the file. Please try again.');
    }
  };
  

  const handleSelectFile = async (url: string) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      // Reset savedLibraryUrl in Firestore to empty string
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await setDoc(userDocRef, { savedLibraryUrl: '' }, { merge: true });

      // Save the selected file URL to the database
      saveSelectedFileToDatabase(currentUser.uid, url);
    }
  };

  const handleDeleteFile = async (fileRefPath: string) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this file?");
        if (confirmDelete) {
            const fileRef = ref(storage, fileRefPath);

            try {
                // Fetch the custom metadata of the file
                const metadata = await getMetadata(fileRef);
                const customMetadata = metadata.customMetadata || {};

                // Check for associated files and remove them
                const {ThumbnailUrl, QnaUrl, SummaryUrl, TranscriptUrl, NewFileNumber } = customMetadata;

                if (ThumbnailUrl) {
                  const thumbnailFileRef = ref(storage, ThumbnailUrl);
                  try {
                      await deleteObject(thumbnailFileRef);
                      console.log('Associated thumbnail file deleted successfully.');
                  } catch (error) {
                      console.error('Error deleting thumbnail file:', error);
                  }
                }

                if (QnaUrl) {
                    const qnaFileRef = ref(storage, QnaUrl);
                    try {
                        await deleteObject(qnaFileRef);
                        console.log('Associated Q&A file deleted successfully.');
                    } catch (error) {
                        console.error('Error deleting Q&A file:', error);
                    }
                }

                if (SummaryUrl) {
                    const summaryFileRef = ref(storage, SummaryUrl);
                    try {
                        await deleteObject(summaryFileRef);
                        console.log('Associated Summary file deleted successfully.');
                    } catch (error) {
                        console.error('Error deleting Summary file:', error);
                    }
                }

                if (TranscriptUrl) {
                    const transcriptFileRef = ref(storage, TranscriptUrl);
                    try {
                        await deleteObject(transcriptFileRef);
                        console.log('Associated Transcript file deleted successfully.');
                    } catch (error) {
                        console.error('Error deleting Transcript file:', error);
                    }
                }

                if (NewFileNumber) {
                    try {
                      const firestore = getFirestore();
                        const firestoreDocRef = doc(firestore, 'files', NewFileNumber);
                        await deleteDoc(firestoreDocRef);
                        console.log('Firestore document deleted successfully.');
                    } catch (error) {
                        console.error('Error deleting Firestore document:', error);
                    }
                }

                // Delete the main file
                await deleteObject(fileRef);
                console.log('Main file deleted successfully.');
            } catch (error) {
                if (error instanceof FirebaseError && error.code === 'storage/object-not-found') {
                    console.log('Main file not found in storage, removing from file list.');
                } else {
                    console.error('Error deleting file:', error);
                    alert('Failed to delete the file. Please try again.');
                    return;
                }
            }

            // Update the state to remove the file from the list and close the PDF viewer if open
            setFiles(files.filter(file => file.refPath !== fileRefPath));
            setPdfUrl(null);

            alert('File and associated resources deleted successfully.');
        }
    };


    const handleFileShareWithPublic = async (fileRefPath: string) => {
      try {
        setTags([]);
        const fileRef = ref(storage, fileRefPath); 
        const metadata = await getMetadata(fileRef);
        const fileTags = metadata.customMetadata?.Tags || ''; 
        setTags(fileTags.split(',').map(tag => tag.trim())); 
        setShareFileUrl(fileRefPath); 
        setShowShareConfirmModal(true); 
      } catch (error) {
        console.error("Error fetching file metadata for tags:", error);
      }
    };

    const confirmFileShareWithPublic = async () => {
      if (shareFileUrl) { 
        setShareWithPublic(true);
        await handleShareChange(shareFileUrl); 
      } else {
        console.error('No file selected for change.'); 
      }
      setShowShareConfirmModal(false); // Close the modal
    };

    const cancelFileShareWithPublic = async () => {
      if (shareFileUrl) { 
        setShareWithPublic(false);
        await handleShareChange(shareFileUrl); 
      } else {
        console.error('No file selected for change.'); 
      }
      setShowShareConfirmModal(false); // Close the modal
    };


    const cancelShareWithPublicWindow = async () => {
      setShowShareConfirmModal(false); 
    };

    const handleShareChange = async (fileRefPath: string) => {
      const fileRef = ref(storage, fileRefPath); // Reference to the file
      console.log('Share with Public:', shareWithPublic);
    
      try {
        // Fetch the current metadata of the file
        const currentMetadata = await getMetadata(fileRef);
    
        // Extract NewFileNumber from custom metadata
        const fileNumber = currentMetadata.customMetadata?.NewFileNumber;
    
        if (!fileNumber) {
          console.error('FileNumber is not available in metadata.');
          alert('FileNumber not found. Cannot update Firestore.');
          return;
        }
    
        // Convert tags array into a comma-separated string
        const tagsString = tags.join(',');
    
        // Prepare the new metadata object by merging with the current metadata
        const updatedMetadata = {
          customMetadata: {
            ...currentMetadata.customMetadata, // Preserve existing custom metadata fields
            ShareWithPublic: shareWithPublic ? 'Yes' : 'No', // Update ShareWithPublic field
            Tags: tagsString, // Add/Update the Tags field
          },
        };
    
        // Update the file's metadata in Firebase Storage
        await updateMetadata(fileRef, updatedMetadata);
    
        // Update Firestore with fileNumber as document ID
        const firestore = getFirestore();
        const fileDocRef = doc(firestore, 'files', fileNumber);
    
        await updateDoc(fileDocRef, {
          shareWithPublic: shareWithPublic ? 'Yes' : 'No', // Update Firestore shareWithPublic field
          fileTags: tagsString, // Update Firestore fileTags field
        });
    
        // Reset state variables after successful update
        setShareWithPublic(false);
        setShareFileUrl('');
    
        // Success message
        alert('File sharing, tags, and Firestore record updated successfully.');
      } catch (error) {
        console.error('Error updating metadata or Firestore:', error);
        alert('Failed to update the file and Firestore. Please try again.');
      }
    };

    const handleChatChange = async (fileRefPath: string) => {
      const currentUser = auth.currentUser;
    
      if (!currentUser) {
        alert("You need to be logged in to proceed.");
        return;
      }
    
      setSelectedChatFile(fileRefPath);
    
      // Clear assistant files before opening the modal
      setAssistantFiles([]);
    
      // Open chat modal for assistant selection
      openChatModal();
    };
    
    
    // Move useEffect outside the function
    useEffect(() => {
      if (isChatModalOpen && auth.currentUser) {
        const fetchAssistants = async () => {
          const currentUser = auth.currentUser;
    
          // Check if the currentUser exists and has a UID
          if (!currentUser || !currentUser.uid) {
            console.error('No authenticated user or UID is missing.');
            return;
          }
    
          try {
            // Get Firestore reference
            const firestore = getFirestore();
            
            // Get a reference to the user document in Firestore
            const userDocRef = doc(firestore, 'users', currentUser.uid);
            
            // Fetch the user document
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
              const userData = userDoc.data();
              
              // Process and map the fetched assistants to match the expected structure
              if (userData.assistants) {
                const fetchedAssistants = userData.assistants.map((assistant: any) => ({
                  name: assistant.name,
                  id: assistant.id,
                  chat: assistant.lastChatMessages || [], // Fetch the last set of chat messages
                  virtualId: assistant.name.charAt(0).toUpperCase(),
                  instructions: assistant.instructions,
                  tool: assistant.tool,
                  model: assistant.model,
                  vectorStore: assistant.vectorStoreId,
                  threadID: assistant.threadID,
                }));
    
                // Set assistants to state
                setAssistants(fetchedAssistants);
                console.log('Fetched assistants:', fetchedAssistants);
              } else {
                console.warn('No assistants found for the user.');
              }
            } else {
              console.warn('User document does not exist.');
            }
          } catch (error) {
            console.error('Error fetching assistants:', error);
          }
        };
    
        fetchAssistants();
      }
    }, [isChatModalOpen]);
    
    const fetchAssistantFiles = async (assistant: Assistant) => {
      try {
        // Fetch the list of files from the assistant's vector store
        const filesList = await openai.beta.vectorStores.files.list(assistant.vectorStore);
        console.log('vector store files',filesList)
        // Fetch file names based on file IDs by fetching metadata for each file
        const fileNames = await Promise.all(
          filesList.data.map(async (file) => {
            try {
              // Fetch the metadata of each file using the file ID
              const fileMetadata = await openai.files.retrieve(file.id);
              return { id: file.id, name: fileMetadata.filename || 'Unknown File' };
            } catch (error) {
              console.error(`Error fetching metadata for file ID: ${file.id}`, error);
              return { id: file.id, name: 'Unknown File' }; // Default to 'Unknown File' in case of an error
            }
          })
        );
    
        // Set the file names to state, mapping the file IDs to their respective names
        setAssistantFiles(fileNames);
      } catch (error) {
        console.error("Error fetching assistant's files:", error);
      }
    };
  
  const handleAssistantSelection = (assistant: Assistant) => {
    setSelectedAssistant(assistant);
    fetchAssistantFiles(assistant);
  };


  const handleAddFile = async () => {
    // Ensure that `selectedAssistant` and `selectedChatFile` are available from state
    if (!selectedAssistant || !selectedChatFile) {
      alert('No file or assistant selected');
      return;
    }
  
    try {
      // Get the file reference from Firebase Storage
      const fileRef = ref(storage, selectedChatFile);
  
      // Get the download URL for the file
      const fileUrl = await getDownloadURL(fileRef);

      // Fetch the file as a Blob
      const fileResponse = await fetch(fileUrl);
      const fileBlob = await fileResponse.blob();
  
      // Get the file name (e.g., Note23.pdf) from the selectedChatFile URL
      const fileName = decodeURIComponent(selectedChatFile)
        .replace('uploads/', '')
        .split('_')
        .slice(1)
        .join('_') || 'file.pdf';
  
      // Convert the Blob into a File object
      const file = new File([fileBlob], fileName, { type: fileBlob.type });

      // Upload the file to OpenAI's API to get the file_id
      const fileUploadResponse = await openai.files.create({
        file: file,
        purpose: "assistants",
      });
  
      const fileID = fileUploadResponse.id;

      // Now, add the uploaded file to the selected assistant's vector store
      await openai.beta.vectorStores.files.create(selectedAssistant.vectorStore, {
        file_id: fileID,
      });

      await openai.beta.assistants.update(selectedAssistant.id, {
        tool_resources: { file_search: { vector_store_ids: [selectedAssistant.vectorStore] } },
      });

      // Refresh the assistant's file list after adding
      await fetchAssistantFiles(selectedAssistant);
  
      alert(`File "${fileName}" added successfully to assistant "${selectedAssistant.name}"`);
  
    } catch (error) {
      console.error('Error adding file to assistant:', error);
      alert('Failed to add file to assistant.');
    }
  };
  

  const handleRemoveFile = async (file_id: string) => {
    if (!selectedAssistant) return;
  
    try {
      // Display a warning message before deleting the file
      const confirmDelete = window.confirm("Are you sure you want to delete this file? This action cannot be undone.");
  
      // If the user cancels the action, return early
      if (!confirmDelete) return;
  
      // If file is not found, return
      if (!file_id) return;
  
      // Remove the file from the assistant's vector store using the file's id
      await openai.beta.vectorStores.files.del(selectedAssistant.vectorStore, file_id);
  
      // Remove the file from OpenAI's storage using the file's id
      await openai.files.del(file_id);
  
      // Add a short delay to allow for the file to be fully removed
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
  
      // Refresh the assistant's file list after deletion
      await fetchAssistantFiles(selectedAssistant);
  
      alert('File removed successfully.');
    } catch (error) {
      console.error('Error removing file from assistant:', error);
      alert('Failed to remove file from assistant.');
    }
  };
  
  const openChatModal = () => setIsChatModalOpen(true);
  const closeChatModal = () => setIsChatModalOpen(false);


  const handleRenameFile = async (newName: string) => {
    if (currentFileRefPath && newName) {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
  
      const userUID = currentUser.uid;
      const fileRef = ref(storage, currentFileRefPath);
  
      try {
        // Get metadata for the main file
        const metadata = await getMetadata(fileRef);
        const { customMetadata } = metadata;
  
        if (!customMetadata) {
          alert("Metadata not found for the file.");
          return;
        }
  
        const documentType = customMetadata.DocumentType;
        const newFileNumber = customMetadata.NewFileNumber;
        const tags = customMetadata.Tags;
          
        // Check if renaming is applicable
        if (documentType === "YouTube Video") {
          alert("Renaming is not applicable to YouTube Video files.");
          return;
        }
  
        // Define file paths for associated files based on the DocumentType
        const thumbnailRefPath = `thumbnails/${userUID}_${newName}.png`;
        const qnaRefPath = `Q&A/${userUID}_${newName}_qna.json`;
        const summaryRefPath =
          documentType === "Note"
            ? `summaries/${userUID}_${newName}_summary.pdf`
            : `summaries/${userUID}_${newName}_transcript_summary.pdf`;
        const transcriptRefPath =
          documentType === "Recording"
            ? `recordingTranscript/${userUID}_${newName}_transcript.pdf`
            : null;
  
        // Function to rename associated files and retain metadata
        const renameFile = async (oldPath: string, newPath: string) => {
          const oldRef = ref(storage, oldPath);
          const newRef = ref(storage, newPath);
  
          try {
            const fileUrl = await getDownloadURL(oldRef);
            const response = await fetch(fileUrl);
            const blob = await response.blob();
  
            // Upload the file with the new name
            await uploadBytes(newRef, blob);
  
            // Preserve existing metadata
            const oldMetadata = await getMetadata(oldRef);
            await updateMetadata(newRef, { customMetadata: oldMetadata.customMetadata });
  
            // Delete the original file
            await deleteObject(oldRef);
          } catch (error) {
            if (error instanceof Error && error.message.includes("storage/object-not-found")) {
              console.log(`File not found: ${oldPath}, skipping rename.`);
            } else {
              console.error(`Error renaming file: ${oldPath}`, error);
              throw error;
            }
          }
        };
  
        // Rename each associated file if applicable
        if (customMetadata.ThumbnailUrl) {
          await renameFile(customMetadata.ThumbnailUrl, thumbnailRefPath);
          customMetadata.ThumbnailUrl = await getDownloadURL(ref(storage, thumbnailRefPath));
        }
        if (customMetadata.QnaUrl) {
          await renameFile(customMetadata.QnaUrl, qnaRefPath);
          customMetadata.QnaUrl = await getDownloadURL(ref(storage, qnaRefPath));
        }
        if (customMetadata.SummaryUrl) {
          await renameFile(customMetadata.SummaryUrl, summaryRefPath);
          customMetadata.SummaryUrl = await getDownloadURL(ref(storage, summaryRefPath));
        }
        if (customMetadata.TranscriptUrl && transcriptRefPath) {
          await renameFile(customMetadata.TranscriptUrl, transcriptRefPath);
          customMetadata.TranscriptUrl = await getDownloadURL(ref(storage, transcriptRefPath));
        }
  
        // Rename the main file
        const newFileRefPath = `uploads/${userUID}_${newName}`;
        const newFileRef = ref(storage, newFileRefPath);
  
        const fileUrl = await getDownloadURL(fileRef);
        const response = await fetch(fileUrl);
        const blob = await response.blob();
  
        await uploadBytes(newFileRef, blob);
        await updateMetadata(newFileRef, { customMetadata });
  
        // Delete the original file
        await deleteObject(fileRef);
  
        // Update Firestore if NewFileNumber is available
        if (newFileNumber) {
          const firestore = getFirestore();
          const fileDocRef = doc(firestore, "files", newFileNumber);
  
          const updateFields = {
            name: newName,
            refPath: newFileRefPath,
            thumbnailUrl: customMetadata.ThumbnailUrl || null,
            url: await getDownloadURL(newFileRef),
            fileTags: tags,
            qnaUrl: customMetadata.QnaUrl || null,
            summaryUrl: customMetadata.SummaryUrl || null,
            transcriptUrl: customMetadata.TranscriptUrl || null,
          };
  
          await updateDoc(fileDocRef, updateFields);
        }
  
        // Update state and notify the user
        const updatedFiles = await Promise.all(
          files.map(async (file) =>
            file.refPath === currentFileRefPath
              ? { ...file, name: newName, refPath: newFileRefPath, url: await getDownloadURL(newFileRef) }
              : file
          )
        );
  
        setFiles(updatedFiles);
  
        alert("File renamed successfully.");
      } catch (error) {
        console.error("Error renaming file:", error);
        alert("Failed to rename the file. Please try again.");
      } finally {
        setIsRenameModalOpen(false);
      }
    }
  };
  
  const handleSummaryButtonClick = (fileUrl: string) => {
    if(!showPublic){
      if(currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording'){
        setSummaryFileUrl(transcriptUrl);
      }else{
        setSummaryFileUrl(fileUrl);
      }    
      setProgressText('');
      setShowProgress(false);
      setIsSummaryModalOpen(true);
    } else{
      alert("Switch to My Space to generate summaries for your files!");
      return;
    }
  };

  const handleQnaButtonClick = (fileUrl: string) => {
    if(!showPublic){
      if(currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording'){
        setQnaFileUrl(transcriptUrl);
      }else{
        setQnaFileUrl(fileUrl);
      }
      setProgressText('');
      setShowProgress(false);
      setIsQnaModalOpen(true);  
    } else{
      alert("Switch to My Space to generate questions for your files");
      return;
    }
  };

  const handleQnaModalClose = () => {
    setIsQnaModalOpen(false);
  };
  const handleSummaryModalClose = () => {
    setIsSummaryModalOpen(false);
  };

  const updateProgress = (message: string) => {
    setProgressText(prev => `${message}\n${prev}`);
  };

  const handleQuestionGeneration = async () => {
    if(currentFile?.shareWithPublic === "Yes"){
      setShareWithPublic(true);
    } else{
      setShareWithPublic(false);
    }
    await handleQnaOkClick() ;
  };

  const handleSummaryGeneration = async () => {
    if(currentFile?.shareWithPublic === "Yes"){
      setShareWithPublic(true);
    } else{
      setShareWithPublic(false);
    }
    await handleSummaryOkClick() ;
  };

  const handleQnaOkClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        alert("You need to be logged in to generate Q&A.");
        return;
    }

    setShowProgress(true);
    updateProgress("Starting Q&A generation...");

    if (animationOption === 'Yes') {
        setIsQnaModalOpen(false); // Close the Q&A modal
        setIsAnimationRunning(true); // Start the animation

        // Start with the initial scale-up and rotation
        const logo = document.querySelector('.animation-logo') as HTMLElement | null;
        if (logo) {
            logo.style.animation = 'rotateAndScaleUpLogo 5s ease-in-out forwards';

            // After the scale-up is complete, start continuous rotation without stopping
            setTimeout(() => {
                logo.style.animation = 'continuousRotate 2s linear infinite';
            }, 5000); // Match this timeout with the duration of the rotateAndScaleUpLogo animation
        }
    }

    try {
        const fileUrl = qnaFileUrl;
        console.log('qna File Url',qnaFileUrl)
        if (!fileUrl) {
            alert("No file selected. Please select a file to generate Q&A.");
            return;
        }

        updateProgress(`Selected file URL: ${fileUrl}`);

        const fileResponse = await fetch(fileUrl);
        const fileBlob = await fileResponse.blob();

        let fileName = '';

        if (currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording') {
            // Extract the filename by splitting on '/' and taking the last part, then remove query parameters
            const fileUrlParts = decodeURIComponent(fileUrl).split('/');
            const fullFileName = fileUrlParts[fileUrlParts.length - 1]; // Get the last segment of the path
            const cleanFileName = fullFileName.split('?')[0]; // Remove query parameters if any
        
            // Remove the UID prefix (assumes UID and file name are separated by an underscore '_')
            const fileNameParts = cleanFileName.split('_');
            fileName = fileNameParts.length > 1 ? fileNameParts.slice(1).join('_') : cleanFileName;
        } else {
            fileName = files.find(file => file.url === fileUrl)?.name || "file.pdf";
        }
        
       // console.log('fileName',fileName)

        updateProgress("Fetched file blob.");
        updateProgress(`Determined file name: ${fileName}`);

        const file = new File([fileBlob], fileName, { type: fileBlob.type });
        updateProgress("Converted Blob to File.");

        const assistantResponse = await openai.beta.assistants.create({
            name: "Q&A Assistant",
            instructions: "Generate Q&A based on the uploaded file.",
            model: "gpt-4o-mini",
            tools: [{ type: "file_search" }],
            metadata: { user: currentUser.uid }
        });

        const assistantID = assistantResponse.id;
        updateProgress("Created AI Assistant.");

        const threadResponse = await openai.beta.threads.create();
        const threadID = threadResponse.id;
        updateProgress("Created thread.");

        const fileUploadResponse = await openai.files.create({
            file: file,
            purpose: "assistants",
        });
        console.log('fileUploadRespons',fileUploadResponse)

        const fileID = fileUploadResponse.id;
        updateProgress("Uploaded file.");

        const vectorStoreResponse = await openai.beta.vectorStores.create({
            name: "Q&A Vector Store",
        });

        const vectorStoreID = vectorStoreResponse.id;
        updateProgress("Created Vector Store.");

        const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
            file_id: fileID,
        });
        console.log('myvectorestorefile',myVectorStoreFile);
        updateProgress("File added to vector store.");

        await openai.beta.assistants.update(assistantID, {
            tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
        });
        updateProgress("Updated assistant with vector store ID.");

        let prompt = '';
        let adjustedDifficultyLevel = difficultyLevel;
        if (difficultyLevel === 'Easy') {
          adjustedDifficultyLevel = 'Medium';
        } else if (difficultyLevel === 'Medium') {
          adjustedDifficultyLevel = 'Hard';
        } else if (difficultyLevel === 'Hard') {
          adjustedDifficultyLevel = 'very very hard and impossible to answer';
        }
        
        // Construct the prompt based on the question format and the adjusted difficulty level
        if (questionFormat === 'Direct question') {
          prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
          Show the questions and answers in JSON format in an array.`;
        }
        
        if (questionFormat === 'Multiple choice') {
          prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
          Provide the question with multiple choices for user to select, choice A, choice B, choice C, and choice D. There is only 
          one correct answer. Provide a letter among A, B, C, and D. Show the multiple choice questions and answers in JSON format in an array.
          For the JSON format, use "question" as the key for the question, "options" as the key for choices of A, B, C, and D, and "answer" as the key for the correct answer.`;
        }
        updateProgress("Prompt for Q&A generation sent.");
        //console.log('prompt', prompt);

        const messageResponse = await openai.beta.threads.messages.create(threadID, {
            content: prompt,
            role: 'user',
        });
        updateProgress("Added a message to the thread.");
        console.log(messageResponse);

        let runAttempts = 0;
        let resultData: string | null = null;
        let isComplete = false;
        const maxAttempts = 10;

        while (runAttempts < maxAttempts && !isComplete) {
            runAttempts += 1;

            updateProgress(`Attempt ${runAttempts} to generate Q&A`);

            const runResponse = await openai.beta.threads.runs.create(threadID, {
                assistant_id: assistantID,
            });

            updateProgress(`Created run...`);

            let runStatus: OpenAI.Beta.Threads.Runs.Run | null = null;
            while (!isComplete) {
                runStatus = await openai.beta.threads.runs.retrieve(threadID, runResponse.id);
                updateProgress(`Run status: ${runStatus?.status}`);

                if (runStatus && runStatus.status === "completed") {
                    const messages = await openai.beta.threads.messages.list(threadID);
                    if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
                        resultData = messages.data[0].content[0].text.value;

                        const jsonMatch = resultData.match(/```json([\s\S]*?)```/);

                        if (jsonMatch && jsonMatch[1]) {
                          try {
                            const jsonData = JSON.parse(jsonMatch[1].trim());
                        
                            const cleanedResultData = JSON.stringify(jsonData, null, 2);
                        
                            const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);
                            const qnaBlob = new Blob([cleanedResultData], { type: "application/json" });
                        
                            // Set custom metadata for the Q&A file
                            const customMetadata = {
                                customMetadata: {
                                    ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                                    UserID: userUID,
                                    DocumentType: 'Question',
                                },
                            };
                        
                            await uploadBytes(qnaFileRef, qnaBlob, customMetadata);
                        
                            // Get the URL of the uploaded Q&A file
                            const qnaUrl = await getDownloadURL(qnaFileRef);
                        
                            // Update the custom metadata of the current file with the QnaUrl
                            if (currentFile) {
                                const fileRef = ref(storage, currentFile.refPath);
                                const currentMetadata = await getMetadata(fileRef);
                        
                                const updatedMetadata = {
                                    customMetadata: {
                                        ...currentMetadata.customMetadata, // Preserve existing custom metadata fields
                                        QnaUrl: qnaUrl, // Add the QnaUrl field
                                    },
                                };
                        
                                await updateMetadata(fileRef, updatedMetadata);

                                setCurrentFile({
                                  ...currentFile, 
                                  qnaUrl: qnaUrl, 
                                });


                                if (currentFile.fileNumber) {
                                  const firestore = getFirestore();
                                  const fileDocRef = doc(firestore, "files", currentFile.fileNumber.toString());
                              
                                  await setDoc(
                                      fileDocRef,
                                      { qnaUrl: qnaUrl }, // Add the QnA URL to the document
                                      { merge: true } // Merge with existing fields instead of overwriting
                                  );
                              
                                  console.log(`Added QnA URL to Firestore for document number: ${currentFile.fileNumber}`);
                                }


                                console.log("Updated file metadata with QnaUrl:", qnaUrl);
                            }
                        

                            updateProgress("Saved Q&A to Cloud.");
                            alert("Q&A generated and saved successfully!");
                            setShareWithPublic(false);
                        
                            if (currentFile?.url) {
                              setQnaStatus((prevStatus) => ({
                                ...prevStatus,
                                [currentFile.url]: true, // Ensure `currentFile.url` is used only if defined
                              }));
                            } else {
                              console.error("Error: currentFile.url is undefined");
                            }
                            
                            isComplete = true;
                            break;
                        } catch (error) {
                            console.error("Failed to parse JSON data or update metadata:", error);
                        }
                        
                        }
                    }
                    if (!isComplete) {
                        updateProgress("Run completed but no valid JSON data found.");
                        break;
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            }
            if (isComplete) break;
        }

        if (!isComplete) {
            updateProgress("No valid JSON data found in the resultData.");
            alert("Failed to generate Q&A data. Try again!");
            await openai.files.del(fileID);
            updateProgress("Deleted file.");

            await openai.beta.threads.del(threadID);
            updateProgress("Deleted thread.");

            await openai.beta.vectorStores.del(vectorStoreID);
            updateProgress("Deleted vector store.");

            await openai.beta.assistants.del(assistantID);
            updateProgress("Deleted assistant.");
        } else {
            await openai.files.del(fileID);
            updateProgress("Deleted file.");

            await openai.beta.threads.del(threadID);
            updateProgress("Deleted thread.");

            await openai.beta.vectorStores.del(vectorStoreID);
            updateProgress("Deleted vector store.");

            await openai.beta.assistants.del(assistantID);
            updateProgress("Deleted assistant.");
        }

    } catch (error) {
        console.error("Error during Q&A generation:", error);
        alert("Failed to generate Q&A. Please try again.");
    } finally {
        if (animationOption === 'Yes') {
            handlePopUpMessage(); // Start the post-animation after completion
        } else {
            setIsQnaModalOpen(false);
            setIsAnimationRunning(false); // Stop the animation
            navigate('/home'); // Navigate back to Notes component
        }
    }
};

const handleSummaryOkClick = async () => {
  //console.log('start generating a summary');
  const currentUser = auth.currentUser;
  if (!currentUser) {
      alert("You need to be logged in to generate Summary.");
      return;
  }

  setShowProgress(true);
  updateProgress("Starting summary generation...");

  if (animationOption === 'Yes') {
      setIsSummaryModalOpen(false); // Close the Summary modal
      setIsAnimationRunning(true); // Start the animation

      const logo = document.querySelector('.animation-logo') as HTMLElement | null;
      if (logo) {
          logo.style.animation = 'rotateAndScaleUpLogo 5s ease-in-out forwards';

          setTimeout(() => {
              logo.style.animation = 'continuousRotate 2s linear infinite';
          }, 5000);
      }
  }

  try {
      const fileUrl = summaryFileUrl;
      if (!fileUrl) {
          alert("No file selected. Please select a file to generate Summary.");
          return;
      }

      updateProgress(`Selected file URL: ${fileUrl}`);

      const fileResponse = await fetch(fileUrl);

      const fileBlob = await fileResponse.blob();

      let fileName = '';

      if (currentFile?.documentType === 'YouTube Video' || currentFile?.documentType === 'Recording') {
          // Extract the filename by splitting on '/' and taking the last part, then remove query parameters
          const fileUrlParts = decodeURIComponent(fileUrl).split('/');
          const fullFileName = fileUrlParts[fileUrlParts.length - 1]; // Get the last segment of the path
          const cleanFileName = fullFileName.split('?')[0]; // Remove query parameters if any
      
          // Remove the UID prefix (assumes UID and file name are separated by an underscore '_')
          const fileNameParts = cleanFileName.split('_');
          fileName = fileNameParts.length > 1 ? fileNameParts.slice(1).join('_') : cleanFileName;
      } else {
          fileName = files.find(file => file.url === fileUrl)?.name || "file.pdf";
      }

      updateProgress("Fetched file blob.");
      updateProgress(`Determined file name: ${fileName}`);

      const file = new File([fileBlob], fileName, { type: fileBlob.type });
      updateProgress("Converted Blob to File.");

      const assistantResponse = await openai.beta.assistants.create({
          name: "Summary Assistant",
          instructions: "You are an assistant to provide a summary for a document.",
          model: "gpt-4o-mini",
          tools: [{ type: "file_search" }],
          metadata: { user: currentUser.uid }
      });

      const assistantID = assistantResponse.id;
      //console.log('Assistant ID', assistantID);

      updateProgress("Created AI Assistant.");

      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;
      //console.log('thread ID', threadID);

      updateProgress("Created thread.");

      const fileUploadResponse = await openai.files.create({
          file: file,
          purpose: "assistants",
      });
      //console.log('fileUploadResponse',fileUploadResponse)

      const fileID = fileUploadResponse.id;
      updateProgress("Uploaded file.");

      const vectorStoreResponse = await openai.beta.vectorStores.create({
          name: "Summary Vector Store",
      });

      const vectorStoreID = vectorStoreResponse.id;
      updateProgress("Created Vector Store.");
      //console.log('vectorstore ID', vectorStoreID);
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });
      //console.log('myvector store file',myVectorStoreFile)
      updateProgress("File successfully added to vector store.");

      await openai.beta.assistants.update(assistantID, {
          tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });
      updateProgress("Updated assistant with vector store ID.");

      let prompt = '';

      if (summaryFormat === 'Long and Detailed') {
        prompt = `Can you provide a long and detailed summary for the document provided?`
      }

      if (summaryFormat === 'Short and Brief') {
        prompt = `Can you provide a short and brief summary for the document provided?`
      }

      updateProgress("Prompt for summary generation sent.");

      const messageResponse = await openai.beta.threads.messages.create(threadID, {
          content: prompt,
          role: 'user',
      });

      updateProgress("Added a message to the thread.");
      //console.log(messageResponse )
      let runAttempts = 0;
      let resultData: string | null = null;
      let isComplete = false;
      const maxAttempts = 10;

      while (runAttempts < maxAttempts && !isComplete) {
        runAttempts += 1;
        updateProgress(`Attempt ${runAttempts} to generate summary`);
        //console.log(`Attempt ${runAttempts} to generate summary`);
    
        const runResponse = await openai.beta.threads.runs.create(threadID, {
            assistant_id: assistantID,
        });
    
        updateProgress(`Created run...`);
        //console.log(`Created run...`);
    
        let runStatus: OpenAI.Beta.Threads.Runs.Run | null = null;
        while (!isComplete) {
            runStatus = await openai.beta.threads.runs.retrieve(threadID, runResponse.id);
            updateProgress(`Run status: ${runStatus?.status}`);
            //console.log(`Run status: ${runStatus?.status}`);
    
            if (runStatus && runStatus.status === "completed") {
                const messages = await openai.beta.threads.messages.list(threadID);
                //console.log('openai messages', messages);
    
                if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
                    resultData = messages.data[0].content[0].text.value;
                    //console.log('resultData', resultData);
    
                        const baseFileName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    
                        // Save the reformatted text to Firebase Storage as a PDF
                        const filename = `summaries/${currentUser.uid}_${baseFileName}_summary.pdf`;
                        const formattedData = parseMarkdownForPDF(resultData);

                        textToPDF(formattedData, filename);
    
                        updateProgress("Saved summary to Cloud.");
                        alert("Summary generated and saved successfully!");
                        if (currentFile?.url) {
                          setSummaryStatus((prevStatus) => ({
                            ...prevStatus,
                            [currentFile.url]: true, 
                          }));
                        } else {
                          console.error("Error: currentFile.url is undefined");
                        }
                        
                        isComplete = true;
                        break; // Break from the inner loop if successful

                }
    
                if (!isComplete) {
                    updateProgress("Run completed but no valid data found.");
                    break;
                }
            } else {
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        }
        if (isComplete) break;
    }

      if (!isComplete) {
          updateProgress("No valid JSON data found in the resultData.");
          alert("Failed to generate Summary data. Try again!");
          await openai.files.del(fileID);
          updateProgress("Deleted file.");
          await openai.beta.threads.del(threadID);
          await openai.beta.vectorStores.del(vectorStoreID);
          await openai.beta.assistants.del(assistantID);
      } else {
          await openai.files.del(fileID);
          updateProgress("Deleted file.");
          await openai.beta.threads.del(threadID);
          await openai.beta.vectorStores.del(vectorStoreID);
          await openai.beta.assistants.del(assistantID);
      }

  } catch (error) {
      console.error("Error during Summary generation:", error);
      alert("Failed to generate Summary. Please try again.");
  } finally {
      if (animationOption === 'Yes') {
          handlePopUpMessageSummary();
      } else {
          setIsSummaryModalOpen(false);
          setIsAnimationRunning(false);
      }
  }
};



const parseMarkdownForPDF = (text: string): Array<{ type: string; content: string; bold?: boolean }> => {
  const elements: Array<{ type: string; content: string; bold?: boolean }> = [];
  const lines = text.split(/\n/);

  lines.forEach((line) => {
    if (/^#{1,6}\s/.test(line)) {
      // Headings
      const level = (line.match(/^#+/) || [''])[0].length;
      elements.push({
        type: `heading-${level}`,
        content: line.replace(/^#+\s*/, '').trim(),
        bold: true,
      });
    } else if (/^(\*|-)\s+.*$/.test(line)) {
      // Unordered lists
      const content = line.replace(/^(\*|-)\s*/, '').trim();
      const parts = content.split(/(\*\*.*?\*\*[,:]?)/); // Handle bold with colon or comma
      parts.forEach((part) => {
        const isBold = /^\*\*(.*?)\*\*[,:]?$/.test(part);
        elements.push({
          type: 'list-item',
          content: part.replace(/^\*\*|\*\*[,:]?$/g, '').trim(),
          bold: isBold,
        });
      });
    } else if (/^\d+\.\s+.*$/.test(line)) {
      // Ordered lists
      const content = line.replace(/^\d+\.\s*/, '').trim();
      const parts = content.split(/(\*\*.*?\*\*[,:]?)/);
      parts.forEach((part) => {
        const isBold = /^\*\*(.*?)\*\*[,:]?$/.test(part);
        elements.push({
          type: 'ordered-list-item',
          content: part.replace(/^\*\*|\*\*[,:]?$/g, '').trim(),
          bold: isBold,
        });
      });
    } else if (/(\*\*.*?\*\*[,:]?)/.test(line)) {
      // Inline bold in paragraphs
      const parts = line.split(/(\*\*.*?\*\*[,:]?)/);
      parts.forEach((part) => {
        const isBold = /^\*\*(.*?)\*\*[,:]?$/.test(part);
        elements.push({
          type: 'text',
          content: part.replace(/^\*\*|\*\*[,:]?$/g, '').trim(),
          bold: isBold,
        });
      });
    } else {
      // Regular paragraph
      elements.push({ type: 'paragraph', content: line.trim() });
    }
  });

  return elements;
};


const textToPDF = async (elements: { type: string; content: string; bold?: boolean }[], filename: string) => {
  const pdfDoc = await PDFDocument.create();

  // Register fontkit
  pdfDoc.registerFontkit(fontkit as any);

  // Load fonts
  const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
  const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
  const customFont = await pdfDoc.embedFont(fontBytes);

  const boldFontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Bold.ttf';
  const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
  const customBoldFont = await pdfDoc.embedFont(boldFontBytes);

  const pageWidth = 8.5 * 72; // Letter size width
  const pageHeight = 11 * 72; // Letter size height
  const margin = 50;
  const lineHeight = 14;
  const bottomMargin = 60; // Extra space at the bottom of the page
  let y = pageHeight - margin;

  let page = pdfDoc.addPage([pageWidth, pageHeight]);

  // Spacing configuration
  const spacing = {
    heading: 10,
    paragraph: 6,
    listItem: 4,
  };

  const drawText = (text: string, font: any, fontSize: number, x: number = margin) => {
    const words = text.split(/(\s+)/); // Match spaces for proper word wrapping
    let line = '';

    for (const word of words || []) {
      const testLine = line + word;
      const lineWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (x + lineWidth > pageWidth - margin) {
        page.drawText(line.trim(), { x, y, size: fontSize, font });
        line = word;
        y -= lineHeight;

        // Check for bottom margin
        if (y < bottomMargin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = pageHeight - margin;
        }
      } else {
        line = testLine;
      }
    }

    if (line) {
      page.drawText(line.trim(), { x, y, size: fontSize, font });
      y -= lineHeight;
    }
  };

  elements.forEach((element, index) => {
    let font = customFont;
    let fontSize = 12;
    let x = margin;
    let additionalSpacing = 0;

    switch (element.type) {
      case 'heading-1':
        font = customBoldFont;
        fontSize = 20;
        additionalSpacing = spacing.heading;
        break;
      case 'heading-2':
        font = customBoldFont;
        fontSize = 18;
        additionalSpacing = spacing.heading;
        break;
      case 'heading-3':
        font = customBoldFont;
        fontSize = 16;
        additionalSpacing = spacing.heading;
        break;
      case 'heading-4':
        font = customBoldFont;
        fontSize = 14;
        additionalSpacing = spacing.heading;
        break;
      case 'list-item':
        x = margin + 20;
        font = element.bold ? customBoldFont : customFont;
        additionalSpacing = spacing.listItem;
        break;
      case 'ordered-list-item':
        x = margin + 20;
        font = element.bold ? customBoldFont : customFont;
        additionalSpacing = spacing.listItem;
        break;
      case 'text':
        font = element.bold ? customBoldFont : customFont;
        additionalSpacing = spacing.paragraph;
        break;
      default:
        font = customFont;
        fontSize = 12;
        additionalSpacing = spacing.paragraph;
        break;
    }

    // Add space before the current element
    if (index > 0) y -= additionalSpacing;

    // Draw the text
    drawText(element.content, font, fontSize, x);

    // Add space after the current element
    y -= additionalSpacing;
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });


  const storageRef = ref(storage, `${filename}`);

  try {
    const userFilesRef = ref(storage, `summaries/`);
    const fileList = await listAll(userFilesRef);
    const currentUser = auth.currentUser;

    if (currentUser) {
      setUserUID(currentUser.uid);
      //console.log('isAnaymous',currentUser.isAnonymous)

      if (currentUser.isAnonymous) {
        const existingFiles = fileList.items.filter(item => item.name.startsWith(currentUser.uid));
        if (existingFiles.length >= 2) {
            alert('Anonymous users can only upload up to two summary files.');
            return;
        }
    
        const customMetadata = {
            customMetadata: {
                ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                UserID: currentUser.uid,
                DocumentType: 'Summary',
            },
        };
    
        await uploadBytes(storageRef, blob, customMetadata);
    
        // Get the URL of the uploaded summary file
        const summaryUrl = await getDownloadURL(storageRef);
    
        // Update currentFile's custom metadata with the SummaryUrl
        if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);
    
            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    SummaryUrl: summaryUrl, // Add the SummaryUrl
                },
            };
    
            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              summaryUrl: summaryUrl, 
            });

            if (currentFile.fileNumber) {
              const firestore = getFirestore();
              const fileDocRef = doc(firestore, "files", currentFile.fileNumber.toString());
          
              await setDoc(
                  fileDocRef,
                  { summaryUrl: summaryUrl }, 
                  { merge: true } 
              );
          
              console.log(`Added summary URL to Firestore for document number: ${currentFile.fileNumber}`);
            }

            //console.log("Updated currentFile metadata with SummaryUrl:", summaryUrl);
        }
    
        console.log('Summary created successfully!');
        setShareWithPublic(false);
    
    } else {
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
    
        const isStarterUser = userData?.subscriptionStatus !== true;
        //console.log('isStarterUser',isStarterUser)

        if (isStarterUser) {
            const existingFiles = fileList.items.filter(item => item.name.startsWith(currentUser.uid));
            if (existingFiles.length >= 2) {
                alert('Starter users can only upload up to two files.');
                return;
            }
        }
    
        const customMetadata = {
            customMetadata: {
                ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
                UserID: currentUser.uid,
                DocumentType: 'Summary',
            },
        };
    
        await uploadBytes(storageRef, blob, customMetadata);
    
        // Get the URL of the uploaded summary file
        const summaryUrl = await getDownloadURL(storageRef);
    
        // Update currentFile's custom metadata with the SummaryUrl
        if (currentFile) {
            const fileRef = ref(storage, currentFile.refPath);
            const currentMetadata = await getMetadata(fileRef);
    
            const updatedMetadata = {
                customMetadata: {
                    ...currentMetadata.customMetadata, // Preserve existing metadata
                    SummaryUrl: summaryUrl, // Add the SummaryUrl
                },
            };
    
            await updateMetadata(fileRef, updatedMetadata);

            setCurrentFile({
              ...currentFile, 
              summaryUrl: summaryUrl, 
            });

            if (currentFile.fileNumber) {
              const firestore = getFirestore();
              const fileDocRef = doc(firestore, "files", currentFile.fileNumber.toString());
          
              await setDoc(
                  fileDocRef,
                  { summaryUrl: summaryUrl }, 
                  { merge: true } 
              );
          
              console.log(`Added summary URL to Firestore for document number: ${currentFile.fileNumber}`);
            }

           // console.log("Updated currentFile metadata with SummaryUrl:", summaryUrl);
        }
    
        console.log('Summary created successfully!');
        setShareWithPublic(false);
    }
    
    }

  } catch (error) {
    console.error('Upload error:', error);
  }
};



const handlePopUpMessage = () => {
    // After the user clicks OK, start scaling down the logo while continuing to rotate
    const logo = document.querySelector('.animation-logo') as HTMLElement | null;
    if (logo) {
        // Switch to the scale down animation with rotation
        logo.style.animation = 'rotateAndScaleDownLogo 3s ease-in-out forwards';
    }

    // Fade out the overlay after the logo scales down
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.classList.add('fade-out');
        }
    }, 3000); // Adjust the timing to match the logo scale-down duration

    // Remove the overlay from the DOM after the fade-out is complete
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.style.display = 'none';
        }
        setIsAnimationRunning(false);
        navigate('/home'); // Navigate back to Notes component
    }, 4000); // Ensure this matches the total duration of scale-down + fade-out
};

const handlePopUpMessageSummary = () => {
  // After the user clicks OK, start scaling down the logo while continuing to rotate
  const logo = document.querySelector('.animation-logo') as HTMLElement | null;
  if (logo) {
      // Switch to the scale down animation with rotation
      logo.style.animation = 'rotateAndScaleDownLogo 3s ease-in-out forwards';
  }

  // Fade out the overlay after the logo scales down
  setTimeout(() => {
      const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
      if (overlay) {
          overlay.classList.add('fade-out');
      }
  }, 3000); // Adjust the timing to match the logo scale-down duration

  // Remove the overlay from the DOM after the fade-out is complete
  setTimeout(() => {
      const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
      if (overlay) {
          overlay.style.display = 'none';
      }
      setIsAnimationRunning(false);
  }, 4000); // Ensure this matches the total duration of scale-down + fade-out
};


useEffect(() => {
  if (selectedFile) {
    setPdfUrl(selectedFile.url);
    setCurrentFile(selectedFile);
    setIsPdfModalOpen(true);
  }
}, [selectedFile]);


const handleViewFile = async (url: string) => {

  console.log('codes get here')
  const fileToView = filteredFiles.find((file) => file.url === url);
  
  if (!fileToView) {
    console.warn("File not found in filtered files.");
    return;
  }

  setSelectedFile(fileToView); // Triggers useEffect to update pdfUrl, currentFile, and open the modal

  try {
    const fileRef = ref(storage, fileToView.refPath);

    // Fetch current metadata to get the existing viewCount and qnaUrl
    const metadata = await getMetadata(fileRef);
    let viewCount = parseInt(metadata.customMetadata?.viewCount || "0", 10);
    const qnaUrl = metadata.customMetadata?.QnaUrl || '';
    const summaryUrl = metadata.customMetadata?.SummaryUrl || '';
    const transcriptUrl = metadata.customMetadata?.TranscriptUrl || '';

    // Increment view count
    viewCount += 1;

    // Update the metadata with the incremented view count
    await updateMetadata(fileRef, {
      customMetadata: {
        ...metadata.customMetadata,
        viewCount: viewCount.toString(),
      },
    });

    // Update the `files` state with the new view count and qnaUrl
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.url === url ? { ...file, viewCount, qnaUrl,summaryUrl,transcriptUrl } : file
      )
    );

    // Update `currentFile` with the updated qnaUrl
    setSelectedFile((prevFile) =>
      prevFile ? { ...prevFile, qnaUrl,summaryUrl,transcriptUrl } : null
    );

    //console.log(`View count and QnaUrl updated for file: ${url}`);
  } catch (error) {
    console.error("Error updating view count or QnaUrl:", error);
    alert("Failed to update view count or QnaUrl. Please try again.");
  }
};


 // Function to handle left scroll
 const handleScrollLeft = () => {
  if (toolBarRef.current) {
    toolBarRef.current.scrollBy({ left: -100, behavior: 'smooth' });
  }
};

// Function to handle right scroll
const handleScrollRight = () => {
  if (toolBarRef.current) {
    toolBarRef.current.scrollBy({ left: 100, behavior: 'smooth' });
  }
};

// Update scroll button visibility based on scroll position
const updateScrollButtonVisibility = useCallback(() => {
  if (toolBarRef.current) {
    const { scrollLeft, scrollWidth, clientWidth } = toolBarRef.current;

    // Hide left button if scrolled to the left end, otherwise show it
    setShowLeftScroll(scrollLeft > 0);

    // Hide right button if scrolled to the right end, otherwise show it
    setShowRightScroll(scrollLeft + clientWidth < scrollWidth);
  }
}, []);

useEffect(() => {
  const toolbar = toolBarRef.current;
  if (toolbar) {
    // Set initial button visibility
    updateScrollButtonVisibility();

    // Add scroll event listener to update visibility as the user scrolls
    toolbar.addEventListener('scroll', updateScrollButtonVisibility);

    // Cleanup on component unmount
    return () => {
      toolbar.removeEventListener('scroll', updateScrollButtonVisibility);
    };
  }
}, [updateScrollButtonVisibility]);


  // Handle search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterFiles(query); // Apply filtering based on both search query and document type
  };

const getNewFileNumber = async (): Promise<number> => {
  try {
    const firestore = getFirestore();
    const countersDocRef = doc(firestore, 'admin', 'counters');
    const countersDoc = await getDoc(countersDocRef);

    // Get the current lastFileNumber or default to 0 if not present
    const lastFileNumber = countersDoc.exists() ? countersDoc.data()?.lastFileNumber || 0 : 0;

    // Increment the file number
    const newFileNumber = lastFileNumber + 1;

    // Update the lastFileNumber in Firestore
    await updateDoc(countersDocRef, { lastFileNumber: newFileNumber });

    return newFileNumber;
  } catch (error) {
    console.error('Error fetching or updating lastFileNumber:', error);
    throw new Error('Unable to fetch or update the last file number.');
  }
};

const handleCollectFile = async (
  fileUrl: string,
  fileName: string,
  documentType: string
) => {
  const currentUser = auth.currentUser;
  if (!currentUser) return;
  const firestore = getFirestore();
  const currentUserUID = currentUser.uid;

  // Fetch the user's profile from Firestore to check the subscription status
  const userDocRef = doc(firestore, 'users', currentUserUID);
  const userDoc = await getDoc(userDocRef);
  const userData = userDoc.data();
  const isSubscriber = userData?.subscriptionStatus === true; // Check subscription status

  try {

    // Get metadata for the main file
    const collectedFileRef = ref(storage, fileUrl);
    const metadata = await getMetadata(collectedFileRef);

    if (!metadata.customMetadata) {
      alert('Metadata not found for the shared file.');
      return;
    }

    const customMetadata = metadata.customMetadata;
    const { size } = metadata; // Include file size
    const {
      QnaUrl = null,
      ThumbnailUrl = null,
      SummaryUrl = null,
      TranscriptUrl = null,
      Tags = null,
      YoutubeTitle = null,
      Duration = null,
      ShareWithPublic = 'No',
    } = customMetadata;

    // Define folder paths based on fileType
    const folderPath =
    documentType === 'Note'
        ? 'uploads'
        : documentType === 'Recording'
        ? 'recordings'
        : documentType === 'YouTube Video'
        ? 'videos'
        : null;

    if (!folderPath) {
      alert('Invalid file type.');
      return;
    }

    // Generate a unique file name if a duplicate exists
    const listRef = ref(storage, `${folderPath}/`);
    const res = await listAll(listRef);
    const existingFiles = res.items
      .filter((item) => item.name.startsWith(`${currentUserUID}_`))
      .map((item) => item.name.replace(`${currentUserUID}_`, ''));

    if (!isSubscriber && existingFiles.length >= 2) {
        alert('Non-subscribers can only collect up to two files in this folder. Consider subscribing to collect unlimited files.');
        return;
    }

    let uniqueFileName = fileName;
    let counter = 1;
    while (existingFiles.includes(uniqueFileName)) {
      const fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
      const fileExt = fileName.substring(fileName.lastIndexOf('.'));
      uniqueFileName = `${fileNameWithoutExt}(${counter})${fileExt}`;
      counter++;
    }

    //console.log('uniqueFileName',uniqueFileName)
    const newFileRefPath = `${folderPath}/${currentUserUID}_${uniqueFileName}`;
    const newFileRef = ref(storage, newFileRefPath);

    // Copy the main file to the new location
    const fileDownloadUrl = await getDownloadURL(collectedFileRef);
    const response = await fetch(fileDownloadUrl);
    const sharedFileBlob = await response.blob();
    await uploadBytes(newFileRef, sharedFileBlob);

    const newFileNumber = await getNewFileNumber();

    // Copy associated files and update their URLs in the new metadata
    const renameAssociatedFile = async (url: string | null, folder: string, suffix: string) => {
      if (url) {
        const oldRef = ref(storage, url);
        const newRefPath = `${folder}/${currentUserUID}_${uniqueFileName}_${suffix}`;
        const newRef = ref(storage, newRefPath);

        const downloadUrl = await getDownloadURL(oldRef);
        const res = await fetch(downloadUrl);
        const blob = await res.blob();
        await uploadBytes(newRef, blob);

        // Preserve metadata
        const oldMetadata = await getMetadata(oldRef);
        await updateMetadata(newRef, { customMetadata: oldMetadata.customMetadata });

        return await getDownloadURL(newRef);
      }
      return null;
    };

    const newThumbnailUrl = await renameAssociatedFile(ThumbnailUrl, 'thumbnails', 'thumbnail.png');
    const newQnaUrl = await renameAssociatedFile(QnaUrl, 'Q&A', 'qna.json');
    const newSummaryUrl =
       documentType === 'Note'
        ? await renameAssociatedFile(SummaryUrl, 'summaries', 'summary.pdf')
        : await renameAssociatedFile(SummaryUrl, 'summaries', 'transcript_summary.pdf');
    const newTranscriptUrl =
       documentType === 'Recording'
        ? await renameAssociatedFile(TranscriptUrl, 'recordingTranscript', 'transcript.pdf')
        : await renameAssociatedFile(TranscriptUrl, 'videosTranscript', 'transcript.pdf');

    const newCustomMetadata = {
      viewCount: '0',
      UserID: currentUserUID,
      NewFileNumber: String(newFileNumber),
      DocumentType: documentType,
      ShareWithPublic: 'No',
      Tags:Tags || '',
      ThumbnailUrl: newThumbnailUrl || '', // Replace null with an empty string
      QnaUrl: newQnaUrl || '', // Replace null with an empty string
      SummaryUrl: newSummaryUrl || '', // Replace null with an empty string
      TranscriptUrl: newTranscriptUrl || '', // Replace null with an empty string
      YoutubeTitle: YoutubeTitle || '',
    };
    
    await updateMetadata(newFileRef, { customMetadata: newCustomMetadata });
    const newUrl = await getDownloadURL(newFileRef);    

    // Update Firestore database
    await setDoc(doc(firestore, 'files', String(newFileNumber)), {
      createAt: new Date(),
      documentType: documentType,
      name: uniqueFileName,
      qnaUrl: newQnaUrl,
      refPath: newFileRefPath,
      shareWithPublic: ShareWithPublic,
      size,
      summaryUrl: newSummaryUrl,
      thumbnailUrl: newThumbnailUrl,
      transcriptUrl: newTranscriptUrl,
      url: newUrl,
      tags: Tags,
      youtubeTitle: YoutubeTitle,
      userId: currentUserUID,
      viewCount: 0,
      youtubeUrl: documentType === 'YouTube Video' ? fileUrl : null,
    });

    const newFile = {
      name:uniqueFileName,
      userId: currentUserUID,
      url:newUrl,
      refPath: newFileRefPath,
      youtubeUrl: documentType === 'YouTube Video' ? fileUrl : '',
      thumbnailUrl:  newThumbnailUrl || '',
      createdAt: new Date(),
      viewCount: 0,
      documentType: documentType,
      shareWithPublic: 'No',
      userImageUrl:'',
      qnaUrl: newQnaUrl ||'',
      summaryUrl:  newSummaryUrl || '',
      transcriptUrl: newTranscriptUrl || '',
      fileTags: Tags ||'',    
      size, 
      fileNumber: String(newFileNumber) || '',    
      duration: Number(Duration) || 0,     
    };
    
    setFiles((prevFiles) => [...prevFiles, newFile]);

    //Refresh all the files
    await fetchAllUserFiles(); 

    alert(`File "${uniqueFileName}" collected and added to your ${documentType === 'Note' ? 'PDF files' : documentType === 'Recording' ? 'recordings' : 'videos'}.`);
  } catch (error) {
    console.error('Error collecting file:', error);
    alert('Failed to collect the file. Please try again.');
  }
};

const handleDownloadFile = async (fileUrl: string) => {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);

    // Extract the filename from the URL and clean it up
    let fileName = fileUrl.split('/').pop() || 'downloaded-file';
    
    // Decode the filename from URL encoding (e.g., %20 becomes a space)
    fileName = decodeURIComponent(fileName);
    
    // Remove the UID prefix if it exists (everything before the first underscore)
    const underscoreIndex = fileName.indexOf('_');
    if (underscoreIndex !== -1) {
      fileName = fileName.substring(underscoreIndex + 1); // Keep everything after the first underscore
    }

    // Remove any query parameters (everything after the file extension)
    const extensionIndex = fileName.indexOf('.');
    if (extensionIndex !== -1) {
      // This regex will match the extension and everything after it (query params)
      const queryStart = fileName.indexOf('?');
      if (queryStart !== -1) {
        fileName = fileName.substring(0, queryStart); // Remove everything after the first `?`
      }
    }

    downloadLink.download = fileName;

    // Append the link to the body, trigger a click event, and then remove the link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Clean up the object URL
    URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};


const filterFiles = (query: string) => {
  const currentUserUID = auth.currentUser?.uid || '';

  const filtered = files
    .filter((file) => {
      const matchesName = file.name.toLowerCase().includes(query);
      const matchesTags = file.fileTags?.toLowerCase().includes(query) || false;
      const matchesQuery = matchesName || matchesTags;

      if (showPublic) {
        return matchesQuery && file.shareWithPublic === 'Yes';
      }

      if (showYourSpace) {
        return matchesQuery && file.url.includes(currentUserUID);
      }

      return matchesQuery;
    })
    .sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime()); 

  setFilteredFiles(filtered);
};


  const handlePublicProfileClick = (userId:string) => {
      setProfileUID(userId);
      setShowProfile(true);
  };

  const handleSubjectClick = async (subjectKey: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert('You need to be logged in to manage subjects.');
      return;
    }
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUser.uid);
  
    const newLabel = window.prompt(`Enter a name for ${subjectKey}:`, subjectLabels[subjectKey] || subjectKey);
    if (!newLabel || newLabel.trim() === '') return;
  
    try {
      await setDoc(
        userDocRef,
        { subjectLabels: { [subjectKey]: newLabel } },
        { merge: true }
      );
  
      setSubjectLabels((prev) => ({
        ...prev,
        [subjectKey]: newLabel,
      }));
  
      alert(`${subjectKey} renamed to "${newLabel}".`);
    } catch (error) {
      console.error('Error updating subject label:', error);
      alert('Failed to update subject label. Please try again.');
    }
  };
  

  const handleSubjectSearchClick = async (subjectKey: string) => {
    const currentUserUID = auth.currentUser?.uid || ''; // Get the current user's UID
    const subjectLabel = subjectLabels[subjectKey]; // Get the label for the subject key
    if (!subjectLabel) return;
  
    const filtered = files
      .filter((file) => {
        const matchesName = file.name.toLowerCase().includes(subjectLabel.toLowerCase()); // Match subject label with file name
        const matchesTags = file.fileTags?.toLowerCase().includes(subjectLabel.toLowerCase()) || false; // Match subject label with fileTags
        const matchesQuery = matchesName || matchesTags; // Match either name or tags
  
        if (showPublic) {
          // Only include files shared with the public
          return matchesQuery && file.shareWithPublic === 'Yes';
        }
  
        if (showYourSpace) {
          // Only include files associated with the current user's UID
          return matchesQuery && file.url.includes(currentUserUID);
        }
  
        // Default behavior: show all files that match the query
        return matchesQuery;
      })
      .sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime()); // Sort by createdAt descending
  
    setFilteredFiles(filtered);
  };
  

  const fetchSubjectLabels = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    try {
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', currentUser.uid);
  
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        if (data.subjectLabels) {
          const updatedLabels = {
            ...subjectLabels, // Default values
            ...data.subjectLabels, // Firestore data
          };
          setSubjectLabels(updatedLabels);
  
          // Optionally save the merged labels back to Firestore
          await setDoc(userDocRef, { subjectLabels: updatedLabels }, { merge: true });
        }
      } else {
        // Save default labels to Firestore if the document doesn't exist
        await setDoc(userDocRef, { subjectLabels }, { merge: true });
      }
    } catch (error) {
      console.error('Error fetching subject labels:', error);
    }
  };
  

  // Call `fetchSubjectLabels` on component mount
  useEffect(() => {
    fetchSubjectLabels();
  }, []);


  useEffect(() => {
    const currentUserUID = auth.currentUser?.uid || ''; // Get the current user's UID
  
    if (files.length > 0) {
      let updatedFiles: FileData[] = [];
  
      if (showPublic) {
        updatedFiles = files.filter(
          (file) => file.shareWithPublic === 'Yes' // Only include public files
        );
      } else if (showYourSpace) {
        updatedFiles = files.filter((file) =>
          file.url.includes(currentUserUID) // Only include user-specific files
        );
      } else {
        updatedFiles = [...files]; // Include all files if no specific flag is set
      }
  
      // Sort the filtered files by createdAt descending
      updatedFiles.sort(
        (a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime()
      );
  
      setOriginalFiles(updatedFiles);
    }
  }, [files, showPublic, showYourSpace, auth]);
  
  
  useEffect(() => {
    const initialFiles = filteredFiles.slice(0, filesPerPage);
    setVisibleFiles(initialFiles);
  }, [filteredFiles]);
  
  const loadMoreFiles = () => {
    const nextPage = page + 1;
    const newFiles = filteredFiles.slice(0, nextPage * filesPerPage);
    setVisibleFiles(newFiles);
    setPage(nextPage);
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreFiles();
      }
    });
  
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
  
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [visibleFiles, page]);


  const handleHomeClick = async () => {
    navigate('/home');
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage}
        onProfileLoad={handleProfileLoad} 
      />
      {/* Search Bar */}
      <div className="search-bar-container">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
          placeholder="Search files..."
        />
        <FaSearch className="search-icon" />
      </div>

      <div className="main-content">
       {showSidebar && (
        <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
       )}
        <div className="content-inner">

          {/* Toolbar container */}
          <div className="toolbar-container">
            {/* Left Scroll Button, conditionally rendered */}
            {showLeftScroll && (
              <button onClick={handleScrollLeft} className="scroll-button left-scroll">
                {'<'}
              </button>
             )}

            <div ref={toolBarRef} className="toolbar-buttons" style={{ marginLeft: showSidebar ? '-6px' : '30px', }} >
              {[
                { label: "Sidebar", key: "Sidebar", icon: <FaCompass />, title: "Hide Sidebar" },
                { label: "Uploads", key: "Uploads", icon: <FaUpload />, title: "Upload" },
                { label: "Public", key: "Public" },
                { label: "My Space", key: "My Space" },
                { label: "All", key: "All" },
                { label: "PDF", key: "PDF" },
                { label: "Audio", key: "Audio" },
                { label: "Video", key: "Video" },
                { label: "Assistants", key: "Assistants" },
                ...Object.entries(subjectLabels).map(([key, label]) => ({ label, key })),
              ].map(({ label, key, icon }, index) => (
                <button
                  key={index}
                  className={`toolbar-button 
                      ${showSidebar && key === "Sidebar" ? "selected" : ""}                       
                      ${showPublic && key === "Public" ? "selected" : ""} 
                      ${showYourSpace && key === "My Space" ? "selected" : ""} 
                      ${showAll && key === "All" ? "selected" : ""} 
                      ${showPDF && key === "PDF" ? "selected" : ""} 
                      ${showAudio && key === "Audio" ? "selected" : ""} 
                      ${showVideo && key === "Video" ? "selected" : ""} 
                      ${showQna && key === "Qna" ? "selected" : ""} 
                      ${selectedSubject === key ? "selected" : ""}`}
                  onClick={() => {
                    if (key === "Sidebar") {
                      setShowSidebar((prev) => !prev);
                    }
                    if (key === "Uploads") {
                      navigate("/uploadInfo");
                    }
                    if (key === "Public") {
                      setShowPublic(true);
                      setShowYourSpace(false);
                    }
                    if (key === "My Space") {
                      setShowYourSpace(true);
                      setShowPublic(false);
                    }
                    if (key === "Assistants") {
                      if (plan === 'Chat' || plan === 'ProChat') {
                        navigate("/chat");
                      } else {
                        alert("Upgrade to Chat Plan to access AI Assistants.");
                      }
                    }
                                      
                    if (key.startsWith('subject')) {
                      if (selectedSubject === key) {
                        // If already selected, deselect it and revert the search
                        setSelectedSubject(null);
                        setFilteredFiles(originalFiles); // Assuming `originalFiles` is the prior unfiltered list
                      } else {
                        // Otherwise, select the subject and perform the search
                        setSelectedSubject(key);
                        handleSubjectSearchClick(key);
                      }
                    }
                    if (key === "All") {
                      setShowAll(true);
                      setShowPDF(false);
                      setShowAudio(false);
                      setShowVideo(false);
                      setShowQna(false);
                    } else if (["PDF", "Audio", "Video", "Qna"].includes(key)) {
                      setShowAll(false);
                      if (key === "PDF") setShowPDF(!showPDF);
                      if (key === "Audio") setShowAudio(!showAudio);
                      if (key === "Video") setShowVideo(!showVideo);
                      if (key === "Qna") setShowQna(!showQna);
                    }
                  }}
                  onDoubleClick={(e) => {
                    if (key.startsWith('subject')) {
                      handleSubjectClick(key); // Allow double-clicks only for subject buttons
                    }
                  }}
                >
                  {/* Render icon if available, otherwise show label */}
                  {icon || label}
                </button>
              ))}
            </div>


            {/* Right Scroll Button, conditionally rendered */}
            {showRightScroll && (
              <button onClick={handleScrollRight} className="scroll-button right-scroll">
                {'>'}
              </button>
            )}
          </div>


        <div className="file-list-container"  style={{ marginLeft: showSidebar ? '4px' : '40px', }} >
        {visibleFiles.length === 0 ? (
            // Show this message when files is empty
            showPublic ? (
              <p className="no-files-message" style={{ color: 'white' }}>Loading files ... </p>
            ) : (
              <p className="no-files-message" style={{ color: 'white' }}>No file was found. Please upload your file.</p>
            )

          ) : (
            // Show the file list when files is not empty
            <ul className="file-list">
              {visibleFiles
              .map((file, index) => {
                const isLongWithoutSpaces = file.name.length > 20 && !file.name.includes(' ');
                const splitFileName = (name: string): string => {
                 const midpoint = Math.floor(name.length / 2); // Find the midpoint of the name
                  return `${name.slice(0, midpoint)}\u200B${name.slice(midpoint)}`; // Insert a zero-width space to create a word break
                };

                return (
                  <li key={index} className="file-list-item">

                <span
                  className="file-thumbnail"
                  onClick={() => {
                    handleViewFile(file.url);
                    handleSelectFile(file.url);
                    setFileName(file.name);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {file.thumbnailUrl ? (
                    <img src={thumbnails[file.url]} alt={`Thumbnail for ${file.name}`} />
                  ) : file.documentType === 'Note' ? (
                    <img src={pdfFile} alt={`PDF File Placeholder for ${file.name}`} />
                  ) : file.documentType === 'Recording' ? (
                    <img src={audioFile} alt={`Audio File Placeholder for ${file.name}`} />
                  ) : file.documentType === 'YouTube Video' ? (
                    <img src={youTubeFile} alt={`YouTube File Placeholder for ${file.name}`} />
                  ) : file.documentType === 'Question' ? (
                    <img src={qnaFile} alt={`Q&A File Placeholder for ${file.name}`} />
                  ) : (
                    <p>Loading...</p>
                  )}
                </span>

                  <div className="file-actions">

                  {showPublic && (
                    <img
                        src={file.userImageUrl && file.userImageUrl !== '' ? file.userImageUrl : defaultUser}
                        alt="User Thumbnail"
                        className="file-user-image"
                        onClick={() => handlePublicProfileClick(file.userId)} // Pass the userId to the function
                      />
                    )}

                    <span className="file-name">
                      {isLongWithoutSpaces ? splitFileName(file.name) : file.name}
                    </span>

                        {/* Menu Button with Dropdown */}
                        <div className="menu-container" ref={menuRef}>
                        <button title="Tools" className="menu-button" onClick={() => toggleMenu(index)}>
                          <span className="button-dot"></span>
                          <span className="button-dot"></span>
                          <span className="button-dot"></span>
                        </button>
                          {openMenuIndex === index && (
                            <div className="menu-content">

                            {!user?.isAnonymous && showPublic &&(   
                              <button className="menu-option" onClick={() => handleCollectFile(file.url, file.name, file.documentType)}>
                                <FaFolderOpen size={14} style={{ marginRight: '8px' }} />
                                Collect
                              </button>
                                )}

                            {!user?.isAnonymous &&(   
                                <button className="menu-option" onClick={() => handleDownloadFile(file.url)}>
                                  <FaDownload size={14} style={{ marginRight: '8px' }} />
                                  Download</button>     
                              )}   
                
                              {showYourSpace && (
                                <div>
                                  {/* Send Button */}
                                  <button
                                    title="Send"
                                    className="menu-option"
                                    onClick={() => {
                                      setCurrentFileRefPath(file.refPath);
                                      handleShareFile(file.url, file.name);
                                      }}
                                  >
                                    <FaPaperPlane size={14} style={{ marginRight: '8px' }} />
                                    Send to People
                                  </button>

                                  {/* Chat button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      handleChatChange(file.refPath);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaCommentDots size={14} style={{ marginRight: '8px' }} />
                                    Send to AI Assistant
                                  </button>

                                  {/* Rename button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      setCurrentFileRefPath(file.refPath);
                                      setNewFileName(file.name);
                                      setIsRenameModalOpen(true);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaEdit size={14} style={{ marginRight: '8px' }} />
                                    Rename
                                  </button>


                                  {/* Share button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      handleFileShareWithPublic(file.refPath);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaShareSquare size={14} style={{ marginRight: '8px' }} />
                                    Update Tags & Share
                                  </button>

                                  {/* Delete button with icon */}
                                  <button
                                    className="menu-option"
                                    onClick={() => {
                                      handleDeleteFile(file.refPath);
                                      setOpenMenuIndex(null);
                                    }}
                                  >
                                    <FaTrashAlt size={14} style={{ marginRight: '8px' }} />
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                    </div>

                    {file.fileTags && (
                      <div  style={{ color: '#aaa', fontSize: '11px', marginTop: '5px' }}>
                        {(() => {
                          const tags = file.fileTags.split(',').map(tag => tag.trim());
                          const maxTagsToShow = 3;
                          const displayedTags = tags.slice(0, maxTagsToShow);
                          const remainingTags = tags.length - maxTagsToShow;

                          return (
                            <span>
                              {displayedTags.join(', ')}
                              {remainingTags > 0 && `, and more`}
                            </span>
                          );
                        })()}
                      </div>
                    )}

                    <div className="views-date">
                      <p>
                        {showPublic && `${file.viewCount} views - `}
                        {file.createdAt
                          ? formatDistanceToNow(new Date(file.createdAt), { addSuffix: true })
                          : "Unknown"}
                      </p>
                    </div>

                  </li>
                );
              })}

              {/* Lazy loading trigger */}
              <div ref={loaderRef} style={{ height: '50px', textAlign: 'center' }}>
                {visibleFiles.length < filteredFiles.length && <p>Loading more files...</p>}
              </div>
            </ul>
          )}
          </div>

            <PdfViewerModal
            isOpen={isPdfModalOpen}
            pdfUrl={pdfUrl}
            fileName={fileName}
            qnaStatus={qnaStatus}
            summaryStatus={summaryStatus} 
            setSummaryStatus={setSummaryStatus}                        
            onClose={() => setIsPdfModalOpen(false)} 
            onGenerateQuestions={() => handleQnaButtonClick(pdfUrl!)} 
            onGenerateSummaries={() => handleSummaryButtonClick(pdfUrl!)} 
            currentFile={currentFile}  
            setCurrentFile={setCurrentFile}               
            transcriptUrl={transcriptUrl} 
            setTranscriptUrl={setTranscriptUrl}   
            showPublic={showPublic}         
          />

        </div>
      </div>

      {shareModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content notes-share-modal">
            <h3 className="modal-title">Send Document</h3>
            <div className="modal-row notes-share-row">
              <label className="notes-share-label">To:</label>
              <input
                type="email"
                value={shareEmail}
                onChange={(e) => setShareEmail(e.target.value)}
                placeholder="Enter recipient's email"
                className="notes-share-input"
              />
            </div>
            <div className="modal-row notes-share-row">
              <label className="notes-share-label">Message:</label>
              <textarea
                value={shareMessage}
                onChange={(e) => setShareMessage(e.target.value)}
                placeholder="Enter your message"
                className="notes-share-textarea"
              />
            </div>
            <div className="modal-actions notes-share-actions">
              <button className="cancel-button" onClick={handleCancelShare}>
                Cancel
              </button>
              <button className="notes-share-send-button" onClick={handleSendShare}>
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      {isRenameModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="modal-title">Rename File</h3>
            <div className="input-row" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label style={{ marginLeft: '30px', marginRight: '10px', width: '80px', color: 'white' }}>File Name:</label>
              <input 
                type="text" 
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)} 
                placeholder="Enter new file name" 
                style={{ width: '66%', padding: '5px' }}  // Adjusted to 2/3 width
              />
            </div>
            <div className="modal-actions">
              <button className="cancel-button" onClick={() => setIsRenameModalOpen(false)}>
                Cancel
              </button>
              <button className="save-button" onClick={() => handleRenameFile(newFileName)}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isQnaModalOpen && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
                  <h3 className="modal-title">Q&A Generation</h3>
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Number of Questions:
                          <input
                              type="number"
                              value={numQuestions}
                              onChange={(e) => setNumQuestions(parseInt(e.target.value, 10))}
                              min="1"
                              max="100"
                              className="qwindow-input"
                          />
                      </label>
                  </div>
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Question Format:
                          <select
                              value={questionFormat}
                              onChange={(e) => setQuestionFormat(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Multiple choice">Multiple choice</option>
                              <option value="Direct question">Direct question</option>
                          </select>
                      </label>
                  </div>

                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Difficulty Level:
                          <select
                              value={difficultyLevel}
                              onChange={(e) => setDifficultyLevel(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Easy">Easy</option>
                              <option value="Medium">Medium</option>
                              <option value="Hard">Hard</option>
                          </select>
                      </label>
                  </div>

                  {showAnimationOptions && (
                    <div className="modal-row qwindow-row">
                    <label className="qwindow-label">
                        Animation:
                        <select
                        value={animationOption}
                        onChange={(e) => setAnimationOption(e.target.value)}
                        className="qwindow-select"
                        >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                    </label>
                    </div>
                 )}

                  {showProgress && (
                      <div className="modal-row qwindow-row">
                          <textarea
                              className="qwindow-textarea"
                              value={progressText}
                              readOnly
                          />
                      </div>
                  )}

                  <div className="modal-actions qwindow-actions">
                      <button className="cancel-button qwindow-button" onClick={handleQnaModalClose}>
                          Cancel
                      </button>
                      <button className="ok-button qwindow-button" onClick={handleQuestionGeneration}>
                          OK
                      </button>
                  </div>


              </div>
          </div>
      )}

      {isSummaryModalOpen && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
                  <h3 className="modal-title">Summary Generation</h3>
                  
                  
                  <div className="modal-row qwindow-row">
                      <label className="qwindow-label">
                          Summary Format:
                          <select
                              value={summaryFormat}
                              onChange={(e) => setSummaryFormat(e.target.value)}
                              className="qwindow-select"
                          >
                              <option value="Long and Detailed">Long and Detailed</option>
                              <option value="Short and Brief">Short and Brief</option>
                          </select>
                      </label>
                  </div>

                  {showAnimationOptions && (
                    <div className="modal-row qwindow-row">
                    <label className="qwindow-label">
                        Animation:
                        <select
                        value={animationOption}
                        onChange={(e) => setAnimationOption(e.target.value)}
                        className="qwindow-select"
                        >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                    </label>
                    </div>
                 )}

                  {showProgress && (
                      <div className="modal-row qwindow-row">
                          <textarea
                              className="qwindow-textarea"
                              value={progressText}
                              readOnly
                          />
                      </div>
                  )}

                  <div className="modal-actions qwindow-actions">
                      <button className="cancel-button qwindow-button" onClick={handleSummaryModalClose}>
                          Cancel
                      </button>
                      <button className="ok-button qwindow-button" onClick={handleSummaryGeneration}>
                          OK
                      </button>
                  </div>


              </div>
          </div>
      )}

      {isChatModalOpen && selectedChatFile && (
        <div className="modal-overlay">
          <div className="modal-content qwindow-modal">
            <h3 className="modal-title">Add File to Chat Assistant</h3>

            <label className="qwindow-label" style={{ display: 'flex', alignItems: 'center' }}>
              <strong style={{ marginLeft: '5px', marginBottom: '10px'}}>
                {decodeURIComponent(selectedChatFile).replace('uploads/', '').split('_').slice(1).join('_')}
              </strong>
            </label>

          {/* Dropdown to select an assistant */}
          <label className="qwindow-label">Select an assistant to add the file</label>
          <select
            onChange={(e) => {
              const selected = assistants.find((asst) => asst.id === e.target.value);

              if (selected) {
                handleAssistantSelection(selected); // Proceed with the selected assistant
              } else {
                setAssistantFiles([]); // Clear assistant files if no assistant is selected
              }
            }}
            style={{ padding: '8px', borderRadius: '5px', width: '100%', marginBottom: '10px' }} // Styling the dropdown
          >
            <option value="">Select an assistant</option>
            {assistants.length > 0 ? (
              assistants.map((assistant) => (
                <option key={assistant.id} value={assistant.id}>
                  {assistant.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No assistant is available
              </option>
            )}
          </select>

            {/* Show assistant files if available */}
            {assistantFiles.length > 0 && (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {assistantFiles.map((file, index) => (
                  <li key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    backgroundColor: '#2b2f33',
                    marginBottom: '8px',
                    color: 'white'
                  }}>
                    <span>{file.name}</span>
                    <button
                      title="Remove"
                      onClick={() => handleRemoveFile(file.id)}
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'red',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <FaTrashAlt size={16} />
                    </button>
                  </li>
                ))}
              </ul>
            )}

            {/* Action buttons */}
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={handleAddFile}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                  marginRight: '10px'
                }}
              >
                Add File
              </button>
              <button
                onClick={closeChatModal}
                style={{
                  padding: '10px 20px',
                  borderRadius: '5px',
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

     {showShareConfirmModal && (
                <CustomConfirmModal 
                  message="Do you want to share the file with the public?"
                  onPublic={confirmFileShareWithPublic}
                  onPrivate={cancelFileShareWithPublic}
                  onCancel={cancelShareWithPublicWindow}
                  tags={tags}
                  setTags={setTags}                  
                />
      )}


      {showProfile && profileUID && (
          <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
              <PublicProfile userUID={profileUID} onClose={() => setShowProfile(false)} />
              </div>
          </div>
      )}

      {/* Animation Overlay */}
      {isAnimationRunning && (
        <div className="animation-overlay">
          <img src={Logo} alt="Loading" className="animation-logo" />
        </div>
      )}
  
    </div>
  );
};

export default Home;
