import React, { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storage, auth } from '../firebaseConfig';
import { getDownloadURL, listAll, ref, deleteObject, uploadBytes } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import OpenAI from 'openai';
import Logo from '../assets/logo.png'; 
import './Base.css';


const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const LoginSelect: React.FC = () => {
  const [showParagraph, setShowParagraph] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isQnaModalOpen, setIsQnaModalOpen] = useState(false);
  const [numQuestions, setNumQuestions] = useState(10);
  const [questionFormat, setQuestionFormat] = useState('Multiple choice');
  const [difficultyLevel, setDifficultyLevel] = useState('Medium');
  const [animationOption, setAnimationOption] = useState('Yes');
  const [isAnimationRunning, setIsAnimationRunning] = useState(false); 
  const [showAnimationOptions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = 'black';

    const paragraphTimer = setTimeout(() => {
      setShowParagraph(true);
    }, 500);

    const optionsTimer = setTimeout(() => {
      setShowOptions(true);
    }, 1500);

    return () => {
      document.body.style.backgroundColor = '';
      clearTimeout(paragraphTimer);
      clearTimeout(optionsTimer);
    };
  }, []);

  const uploadFile = async (file: File) => {
    console.log('file name', file.name);

    const currentUser = auth.currentUser;
    if (currentUser) {
      const userUID = currentUser.uid;
      try {
        const listRef = ref(storage, 'uploads/');
        const res = await listAll(listRef);
        const userFiles = res.items.filter(item => item.name.includes(userUID));

        for (const file of userFiles) {
          await deleteObject(ref(storage, file.fullPath));
        }

        const listRef1 = ref(storage, 'Q&A/');
        const res1 = await listAll(listRef1);
        const userFiles1 = res1.items.filter(item => item.name.includes(userUID));

        for (const file of userFiles1) {
          await deleteObject(ref(storage, file.fullPath));
        }

        const fileName = `${userUID}_${file.name}`;
        const storageRef = ref(storage, `uploads/${fileName}`);
        await uploadBytes(storageRef, file);
        setSelectedFile(file);

        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', userUID);
        await setDoc(userDocRef, { hasFetched: false }, { merge: true });
        alert('File uploaded successfully!');
      } catch (error) {
        console.error('Upload error:', error);
      }
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(file);
      setIsQnaModalOpen(true);
    }
  };

  const handleQnaOkClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert("You need to be logged in to generate Q&A.");
      return;
    }

    if (animationOption === 'Yes') {
        setIsQnaModalOpen(false); // Close the Q&A modal
        setIsAnimationRunning(true); // Start the animation

        // Start with the initial scale-up and rotation
        const logo = document.querySelector('.animation-logo') as HTMLElement | null;
        if (logo) {
            logo.style.animation = 'rotateAndScaleUpLogo 5s ease-in-out forwards';

            // After the scale-up is complete, start continuous rotation without stopping
            setTimeout(() => {
                logo.style.animation = 'continuousRotate 2s linear infinite';
            }, 5000); // Match this timeout with the duration of the rotateAndScaleUpLogo animation
        }
    }

    try {
      const uniqueFileName = `${currentUser.uid}_${selectedFile!.name}`;
      console.log('uniqueFileName', uniqueFileName);

      const storageRef = ref(storage, `uploads/${uniqueFileName}`);
      await uploadBytes(storageRef, selectedFile!);

      const fileURL = await getDownloadURL(storageRef);
      console.log('File URL:', fileURL);

      const fileBlob = await selectedFile!.arrayBuffer();
      const file = new File([fileBlob], selectedFile!.name, { type: selectedFile!.type });

      const assistantResponse = await openai.beta.assistants.create({
        name: "Q&A Assistant",
        instructions: "Generate Q&A based on the uploaded file.",
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { user: currentUser.uid },
      });
      const assistantID = assistantResponse.id;

      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;

      const fileUploadResponse = await openai.files.create({
        file: file,
        purpose: "assistants",
      });
      const fileID = fileUploadResponse.id;

      console.log('fileID', fileID);

      const vectorStoreResponse = await openai.beta.vectorStores.create({
        name: "Q&A Vector Store",
      });
      const vectorStoreID = vectorStoreResponse.id;

      console.log('vectorstoreID', vectorStoreResponse);

      await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });

      await openai.beta.assistants.update(assistantID, {
        tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });

      let prompt = '';
      let adjustedDifficultyLevel = difficultyLevel;
      if (difficultyLevel === 'Easy') {
        adjustedDifficultyLevel = 'Medium';
      } else if (difficultyLevel === 'Medium') {
        adjustedDifficultyLevel = 'Hard';
      } else if (difficultyLevel === 'Hard') {
        adjustedDifficultyLevel = 'very very hard and impossible to answer';
      }

        // Construct the prompt based on the question format and the adjusted difficulty level
        if (questionFormat === 'Direct question') {
            prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
            Show the questions and answers in JSON format in an array.`;
          }
          
          if (questionFormat === 'Multiple choice') {
            prompt = `Can you create ${numQuestions} ${adjustedDifficultyLevel} questions based on the content of the file and provide corresponding answers. 
            Provide the question with multiple choices for user to select, choice A, choice B, choice C, and choice D. There is only 
            one correct answer. Provide a letter among A, B, C, and D. Show the multiple choice questions and answers in JSON format in an array.
            For the JSON format, use "question" as the key for the question, "options" as the key for choices of A, B, C, and D, and "answer" as the key for the correct answer.`;
          }

      const messageResponse = await openai.beta.threads.messages.create(threadID, {
        content: prompt,
        role: 'user',
      });

      console.log('messageResponse', messageResponse);

      let runAttempts = 0;
      let resultData: string | null = null;
      let isComplete = false;
      const maxAttempts = 10;

      while (runAttempts < maxAttempts && !isComplete) {
        runAttempts += 1;

        const runResponse = await openai.beta.threads.runs.create(threadID, {
          assistant_id: assistantID,
        });

        let runStatus: OpenAI.Beta.Threads.Runs.Run | null = null;
        while (!isComplete) {
          runStatus = await openai.beta.threads.runs.retrieve(threadID, runResponse.id);

          if (runStatus && runStatus.status === "completed") {
            const messages = await openai.beta.threads.messages.list(threadID);
            if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
              resultData = messages.data[0].content[0].text.value;
              const jsonMatch = resultData.match(/```json([\s\S]*?)```/);

              if (jsonMatch && jsonMatch[1]) {
                try {
                  const jsonData = JSON.parse(jsonMatch[1].trim());

                  const cleanedResultData = JSON.stringify(jsonData, null, 2);

                  const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${selectedFile!.name}_qna.json`);
                  const qnaBlob = new Blob([cleanedResultData], { type: "application/json" });

                  await uploadBytes(qnaFileRef, qnaBlob);

                  const firestore = getFirestore();
                  const userDocRef = doc(firestore, 'users', currentUser.uid);
                  await setDoc(userDocRef, { hasFetched: true, selectedFileUrl: fileURL }, { merge: true });

                  alert("Q&A generated and saved successfully!");
                  isComplete = true;
                  break;
                } catch (error) {
                  console.error("Failed to parse JSON data:", error);
                }
              }
            }
            if (!isComplete) {
              break;
            }
          } else {
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
        if (isComplete) break;
      }

      if (!isComplete) {
        alert("Failed to generate Q&A data. Try again!");
        await openai.files.del(fileID);
        await openai.beta.threads.del(threadID);
        await openai.beta.vectorStores.del(vectorStoreID);
        await openai.beta.assistants.del(assistantID);
        navigate('/login-select');
      } else {
        await openai.files.del(fileID);
        await openai.beta.threads.del(threadID);
        await openai.beta.vectorStores.del(vectorStoreID);
        await openai.beta.assistants.del(assistantID);
      }
    } catch (error) {
      console.error("Error during Q&A generation:", error);
      alert("Failed to generate Q&A. Please try again.");
    } finally {
        if (animationOption === 'Yes') {
            handlePopUpMessage(); // Start the post-animation after completion
        } else {
            setIsQnaModalOpen(false);
            setIsAnimationRunning(false); // Stop the animation
            navigate('/home'); // Navigate back to Notes component
        }
    }
  };

  const handlePopUpMessage = () => {
    // After the user clicks OK, start scaling down the logo while continuing to rotate
    const logo = document.querySelector('.animation-logo') as HTMLElement | null;
    if (logo) {
        // Switch to the scale down animation with rotation
        logo.style.animation = 'rotateAndScaleDownLogo 3s ease-in-out forwards';
    }

    // Fade out the overlay after the logo scales down
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.classList.add('fade-out');
        }
    }, 3000); // Adjust the timing to match the logo scale-down duration

    // Remove the overlay from the DOM after the fade-out is complete
    setTimeout(() => {
        const overlay = document.querySelector('.animation-overlay') as HTMLElement | null;
        if (overlay) {
            overlay.style.display = 'none';
        }
        setIsAnimationRunning(false);
        navigate('/home'); // Navigate back to Notes component
    }, 4000); // Ensure this matches the total duration of scale-down + fade-out
};

  const dividerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '10px',  
    width: '100%',
    justifyContent: 'center',
  };

  const lineStyle: React.CSSProperties = {
    flex: 1,
    height: '1px',
    backgroundColor: '#FFFFFF',
  };

  const orTextStyle: React.CSSProperties = {
    margin: '0 10px',
    whiteSpace: 'nowrap',
  };

  const handleQnaModalClose = () => {
    setIsQnaModalOpen(false);
  };

  return (
    <div style={styles.container}>
      <style>{`
        @keyframes smoothMoveUp {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>

      <div
        style={{
          ...styles.paragraphContainer,
          animation: showParagraph ? 'smoothMoveUp 1s ease-in-out forwards' : '',
        }}
      >
        <p style={styles.paragraph}>
          Welcome to <span style={styles.brand}>CompreQnA</span>, your go-to platform for enhancing comprehension through interactive questions and answers.
        </p>
      </div>

      <div
        style={{
          ...styles.optionsContainer,
          animation: showOptions ? 'smoothMoveUp 1s ease-in-out forwards' : '',
        }}
      >
        <h2 style={styles.subHeading}>Select an option to continue:</h2>

        <div style={styles.buttonContainer}>
        <button
            className="button-glow-effect"
            style={styles.button}
            onClick={() => document.getElementById('fileInput')?.click()}
            >
            Upload Your Own PDF and Generate Questions
        </button>
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            accept="application/pdf"
            onChange={handleFileChange}
          />

          <div style={dividerStyle}>
            <div style={lineStyle}></div>
            <div style={orTextStyle}>OR</div>
            <div style={lineStyle}></div>
          </div>

          <button
            className="button-glow-effect"
            style={styles.button}
            onClick={() => navigate('/us-history')}
            >
            Try Out the App with US History Questions
          </button>

          <button
            style={styles.skipButton}
            onClick={() => navigate('/home')}
          >
            SKIP
          </button>

          {isQnaModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content qwindow-modal">
                <h3 className="modal-title">Q&A Generation</h3>

                <div className="modal-row qwindow-row">
                  <label className="qwindow-label">
                    Number of Questions:
                    <input
                      type="number"
                      value={numQuestions}
                      onChange={(e) => setNumQuestions(parseInt(e.target.value, 10))}
                      min="1"
                      max="100"
                      className="qwindow-input"
                    />
                  </label>
                </div>

                <div className="modal-row qwindow-row">
                  <label className="qwindow-label">
                    Question Format:
                    <select
                      value={questionFormat}
                      onChange={(e) => setQuestionFormat(e.target.value)}
                      className="qwindow-select"
                    >
                      <option value="Multiple choice">Multiple choice</option>
                      <option value="Direct question">Direct question</option>
                    </select>
                  </label>
                </div>

                <div className="modal-row qwindow-row">
                  <label className="qwindow-label">
                    Difficulty Level:
                    <select
                      value={difficultyLevel}
                      onChange={(e) => setDifficultyLevel(e.target.value)}
                      className="qwindow-select"
                    >
                      <option value="Easy">Easy</option>
                      <option value="Medium">Medium</option>
                      <option value="Hard">Hard</option>
                    </select>
                  </label>
                </div>

                {/* Conditionally render the animation section */}
                {showAnimationOptions && (
                    <div className="modal-row qwindow-row">
                    <label className="qwindow-label">
                        Animation:
                        <select
                        value={animationOption}
                        onChange={(e) => setAnimationOption(e.target.value)}
                        className="qwindow-select"
                        >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        </select>
                    </label>
                    </div>
                )}

                <div className="modal-actions qwindow-actions">
                  <button className="cancel-button qwindow-button" onClick={handleQnaModalClose}>
                    Cancel
                  </button>
                  <button className="ok-button qwindow-button" onClick={handleQnaOkClick}>
                    OK
                  </button>
                </div>

              </div>
            </div>
          )}

        </div>
      </div>
        {/* Animation Overlay */}
        {isAnimationRunning && (
        <div className="animation-overlay">
        <img src={Logo} alt="Loading" className="animation-logo" />
        </div>
        )}

    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    height: '100vh',
    backgroundColor: '#0d1117',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/stardust.png)',
    color: '#c9d1d9',
    fontFamily: 'Arial, sans-serif',
    backgroundSize: 'cover',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  paragraphContainer: {
    opacity: 0,
  },
  paragraph: {
    fontSize: '20px',
    marginBottom: '40px',
    color: '#c9d1d9',
  },
  optionsContainer: {
    opacity: 0,
  },
  subHeading: {
    fontSize: '22px',
    marginBottom: '20px',
    color: '#58a6ff',
    textShadow: '0 0 5px #58a6ff',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '20px',
  },
  button: {
    padding: '15px 30px',
    fontSize: '16px',
    color: '#c9d1d9',
    backgroundColor: '#161b22',
    border: '2px solid #58a6ff',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  brand: {
    color: '#58a6ff',
    textShadow: '0 0 10px #58a6ff',
  },
skipButton: {
  padding: '10px 20px',
  fontSize: '14px',
  margin: '0 auto', // Centers the button horizontally
  color: '#ffffff',
  backgroundColor: 'transparent',
  borderRadius: '5px',
  cursor: 'pointer',
  textShadow: '0 0 5px #58a6ff',
  border: 'none',
  transition: 'box-shadow 0.5s ease, transform 0.2s ease',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  display: 'block', // Ensures block-level behavior
  width: 'fit-content', // Ensures button width is based on content
},

};

export default LoginSelect;
