import React, { useState, useEffect } from 'react';
import { getFirestore, doc,getDoc } from 'firebase/firestore';
import { getStorage, ref, listAll } from 'firebase/storage'; // Ensure getDownloadURL is imported here
import './Base.css';

interface ProfileProps {
  userUID: string;
  onClose: () => void;
}

const PublicProfile: React.FC<ProfileProps> = ({ userUID, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [bio, setBio] = useState(''); // Initialize bio state
  const [pdfFilesCount, setPdfFilesCount] = useState(0); 
  const [audioFilesCount, setAudioFilesCount] = useState(0); 
  const [videoFilesCount, setVideoFilesCount] = useState(0); 

  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userUID);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFirstName(userData?.firstName || '');
        setLastName(userData?.lastName || '');
        setBio(userData?.bio || 'No bio yet.');
        setUserImage(userData?.imageUrl || '/path-to-user-image.png');
      }
    };

    const fetchPdfFilesCount = async () => {
      const storage = getStorage();
      const listRef = ref(storage, 'uploads/');
      const res = await listAll(listRef);

      const userFiles = res.items.filter((item) => item.name.includes(userUID));
      setPdfFilesCount(userFiles.length);
    };

    const fetchAudioFilesCount = async () => {
      const storage = getStorage();
      const listRef = ref(storage, 'recordings/');
      const res = await listAll(listRef);

      const userFiles = res.items.filter((item) => item.name.includes(userUID));
      setAudioFilesCount(userFiles.length);
    };

    const fetchVideoFilesCount = async () => {
      const storage = getStorage();
      const listRef = ref(storage, 'videos/');
      const res = await listAll(listRef);

      const userFiles = res.items.filter((item) => item.name.includes(userUID));
      setVideoFilesCount(userFiles.length);
    };

    fetchUserProfile();
    fetchPdfFilesCount();
    fetchAudioFilesCount();
    fetchVideoFilesCount();
  }, [userUID]);


  return (
    <div className="profile-container">
      <div className="profile-header">
         <div className="profile-image-container"  >
          <img
            src={userImage}
            alt={`${firstName} ${lastName}`}
            className="profile-image"
          />
        </div>
        <div style={{ color: 'white' }}>
          <h1>{firstName} {lastName}</h1>
          <p >{bio}</p>
        </div>
      </div>
        {/* Stats */}
        <div className="profile-stats">
          <div>
            <strong>{pdfFilesCount}</strong> PDF Files;
          </div>

          <div>
            <strong>{audioFilesCount}</strong> Audio Files;
          </div>

          <div>
            <strong>{videoFilesCount}</strong> Video Files
          </div>

        </div>
      <button className="profile-close-button" onClick={onClose}>Close</button>
    </div>
  );
};

export default PublicProfile;