import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import NavBar from './NavBar';
import './Base.css';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'; // Firestore imports

const BillingProChat: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [product] = useState({
    name: 'ProChat Subscription',
    price: 100.00,
    description: 'Gain access to a professional chatbot tailored to handle specific tasks within specialized fields.',
    frequency: 'Monthly',
  });

  const [userEmail, setUserEmail] = useState<string | null>(null); 
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
  const [pollingTimeoutReached, setPollingTimeoutReached] = useState(false);
  const [pollingStarted, setPollingStarted] = useState(false); // Flag to start polling

  const handleViewHomePage = () => {
    navigate('/home');
  };

  const handleCheckout = () => {

    // Open a new tab for the Stripe payment link and include the Compreqna User Email as metadata
    window.open(`https://buy.stripe.com/eVa9Bs9EX7lbbQY8wz?compreqna_user_email=${userEmail}`, '_blank');
  
    // Start polling after the checkout button is clicked
    setPollingStarted(true);
  };
  

  useEffect(() => {
    const fetchUserEmail = () => {
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.email) {
        setUserEmail(currentUser.email); // Set the current user's email
      }
    };

    fetchUserEmail();
  }, []);

  // Polling for payment completion
  useEffect(() => {
  if (userEmail && pollingStarted && !pollingTimeoutReached) {   
      const POLLING_INTERVAL = 5000; // Poll every 5 seconds
      const POLLING_TIMEOUT = 600000; // Stop polling after 10 minutes (600,000 ms)

      const intervalId = setInterval(() => {
        fetch(`/api/payment-status/${userEmail}`)
          .then(response => response.json())
          .then(data => {
            if (data.customerId && data.status) {
              setCustomerId(data.customerId);
              setPaymentStatus(data.status);
              saveCustomerInfoToFirestore(data.customerId); // Save customerId and subscriptionStatus to Firestore
              clearInterval(intervalId); // Stop polling when payment is completed or failed
              navigate('/billing-account');
            }
          })
          .catch(error => console.error('Error fetching payment status:', error));
      }, POLLING_INTERVAL);

      // Set a timeout to stop polling after 2 minutes
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId); // Stop polling after the timeout
        setPollingTimeoutReached(true); // Set the timeout flag
      }, POLLING_TIMEOUT);

      // Cleanup interval and timeout on component unmount
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [userEmail, pollingStarted, pollingTimeoutReached,navigate]);


  // Function to save customerId and subscriptionStatus to Firestore
  const saveCustomerInfoToFirestore = async (customerId: string) => {
    const db = getFirestore();
    const currentUser = auth.currentUser;

    if (currentUser && currentUser.uid) {
      const userDocRef = doc(db, 'users', currentUser.uid); // Reference to the user document by UID
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
          try {
            // Update Firestore with customerId and set subscriptionStatus to true
            await updateDoc(userDocRef, { customerId, subscriptionStatus: 'ProChat' });
            console.log('Customer ID and subscription status saved to Firestore.');
          } catch (error) {
            console.error('Error saving customerId and subscription status to Firestore:', error);
          }
      } else {
        // If the user document doesn't exist, create it with customerId and subscriptionStatus
        try {
          await setDoc(userDocRef, { customerId, subscriptionStatus: 'ProChat' });
          console.log('Customer ID and subscription status saved to Firestore.');
        } catch (error) {
          console.error('Error creating user document in Firestore:', error);
        }
      }
    }
  };


  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={(fName, lName, imgUrl) => setUserImage(imgUrl)} 
      />
      <div className="main-content">
          <div className="content-inner">
            <h1 className="billing-title">Subscription</h1>

              <div className="product-details">
                <h2 className="product-name">{product.name}</h2>
                <p className="product-description">{product.description}</p>
                <p className="product-price">Contact Us for Monthly Price</p>
              </div>

              <p style={{ color: '#c090ff', marginRight: '10px' }}>
                <strong>Please use the same email you used to log in when submitting your payment at checkout.</strong> 
              </p>  

              <div className="checkout-section">
                <button className="checkout-button" onClick={handleCheckout}>
                  Checkout
                </button>
              </div>

              {/* Show the customer ID and payment status after payment completion */}
              {paymentStatus && (
                <div className="payment-status-section">
                  <p>Payment Status: {paymentStatus}</p>
                </div>
              )}

              {/* Show message if polling timeout is reached */}
              {pollingTimeoutReached && !customerId && (
                <div className="timeout-message">
                  <p>Payment was not completed within the time limit. Please try again.</p>
                </div>
              )}
            </div>
          </div>

          <p onClick={handleViewHomePage} className="home-page-link">
            Go to Home Page
          </p>
        </div>
  );
};

export default BillingProChat;
